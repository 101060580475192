import Vue from 'vue';
import Toast from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

const options = {
	position: 'bottom-right',
	timeout: 3000
};

Vue.use(Toast, options);
export default (context, inject) => {
	inject('defaultCatcher', error => {
		if (error.networkError) {
			Vue.$toast.warning(
				'De server is even onbereikbaar, probeer over enkele seconden nog eens'
			);
		} else {
			throw error;
		}
	});
	inject('defaultThen', ({ data, errors }) => {
		if (errors) {
			errors.forEach(e => {
				Vue.$toast.error(e.message);
			});
		}
		return { data, errors };
	});
};
