<template lang="pug">

div(v-if="links.length")
	div(v-if="display === 'cards'")
		v-container.pa-0.mb-12
			v-row
				v-col(v-for="(card, i) in links" cols="12" sm="6" md="3" :key="`cards-${i}`")
					v-hover(#default="{ hover }")
						v-card(:elevation="hover ? 3 : 1 " :to="card.to")
							v-img(:src="card.image" :aspect-ratio="16/9")
							v-card-text.py-2
								slot(v-bind="card")
									span.font-weight-bold {{card.title}}
</template>

<script>
import gql from 'graphql-tag';

export default {
	name: 'LinkListEmbed',
	props: {
		links: {
			type: Array,
			default() {
				return [];
			}
		},
		display: {
			type: String,
			default: 'list',
			validation(val) {
				return ['list', 'cards'].contains(val);
			}
		},
		showDivider: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			weblogs: [],
			total: null,
			fetchLimit: null,
			fetched: false
		};
	},
	async fetch() {},
	computed: {},
	methods: {},
	fragments: gql`
		fragment LinkListEmbedDataFragment on Embed {
			data {
				... on EmbedDataLinkList {
					links {
						title
						to
						image
					}
					display
				}
			}
		}
	`
};
</script>
