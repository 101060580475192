<template lang="pug">
div.accommodation-list-embed
	ws-content(:markdown="introduction" :context="{accommodations: accommodations}")
	.caption.grey--text.text-right *op basis van {{filtersWithPersons.persons}} personen voor {{filtersWithPersons.duration}} dagen
	div(style="position:absolute; width:1100px")
	accommodation-list(:filterstring="`${filterstringWithPersons}`" v-if="filterstringWithPersons" :limit="3" hide-disclaimer hide-alert)
	.text-center.mb-6
		v-btn( color="primary" outlined :href="`/accommodatie/resultaten#${filterstring}`")  {{cta}}
</template>

<script>
import gql from 'graphql-tag';
import AccommodationList from '@dengl/common/components/accommodations/list.vue';

import {
	encodeFilters,
	decodeFilters
} from '@dengl/common/utils/filterstrings';
import fields from './fields.js';

export default {
	name: 'AccommodationBlockEmbed',
	components: {
		AccommodationList
	},
	props: fields,
	data() {
		return {
			accommodations: []
		};
	},
	computed: {
		filters() {
			return decodeFilters(this.filterstring, this.$i18n.locale);
		},
		filtersWithPersons() {
			return { persons: 2, duration: 7, date: '2025-02-15', ...this.filters };
		},
		filterstringWithPersons() {
			return encodeFilters(this.filtersWithPersons, this.$i18n.locale);
		}
	},
	fragments: gql`
		fragment AccommodationBlockEmbedDataFragment on Embed {
			data {
				... on EmbedDataAccommodationBlock {
					filterstring
					introduction
					cta
				}
			}
		}
	`
};
</script>
<style lang="sass">
.accommodation-list-embed
	.v-card__actions
		position: relative !important
</style>
