import countries from '@/locale/countries';

export const slugs = {
	'nl-NL': {
		snowforecast: 'sneeuwverwachting',
		snowfall: 'sneeuwval',
		snowradar: 'sneeuwradar',
		sun: 'zon',
		clouds: 'wolken',
		temperature: 'temperatuur',
		temp850: '850hpa',
		wind: 'wind',
		jetstream: 'jetstream',
		advanced: 'gevorderden',
		apresski: 'apresski',
		authentic: 'sfeervol',
		beginners: 'beginners',
		crowd: 'rustig',
		kids: 'kindvriendelijk',
		location: 'locatie',
		offpiste: 'offpiste',
		pistes: 'pistes',
		price: 'prijs',
		restaurants: 'restaurants',
		route: 'bereikbaarheid',
		skihall_authentic: 'skihall_authentic',
		skihall_crowd: 'skihall_crowd',
		skihall_funpark: 'skihall_funpark',
		skihall_kids: 'skihall_kids',
		skihall_lifts: 'skihall_lifts',
		skihall_pistes: 'skihall_pistes',
		skihall_price: 'skihall_price',
		skihall_restaurants: 'skihall_restaurants',
		skihall_snow: 'skihall_snow',
		snowguarantee: 'sneeuwzeker',
		tours: 'tochten',
		summerski: 'zomerski',
		isEasy: 'gemakkelijk',
		isDifficult: 'moeilijk',
		hasConnectedSlopes: 'aaneengesloten',
		hasNoFlatAreas: 'nietvlak',
		hasDownHill: 'dalafdaling',
		hasSummerSkiing: 'zomerski',
		min_elevation: 'hoogte',
		max_distance_utrecht: 'utrecht',
		max_distance_highway: 'snelweg',
		show_map: 'kaart',
		skimaps: 'kaarten',
		accommodations: 'accommodaties',
		apartments: 'appartementen',
		chalets: 'chalets',
		hotels: 'hotels',
		pensions: 'pensions',
		destination: 'skigebieden',
		weather: 'weersneeuw',
		travelogues: 'reisverslagen',
		weather_snow: 'weer-en-sneeuw',
		gear: 'materiaal-en-techniek',
		travel: 'reizen',
		marketplace: 'marktplaats',
		photo_video: 'fotos-en-filmpjes',
		meet_greet: 'meet-en-greet',
		work: 'werken-in-de-sneeuw',
		stube: 'stube',
		weather_snowheight: 'weersneeuw/sneeuwhoogte',
		theme_childfriendly: 'kindvriendelijke-skigebieden',
		theme_beginners: 'skigebieden-voor-beginners',
		theme_advanced: 'skigebieden-voor-gevorderden',
		theme_offpiste: 'off-piste-skigebieden',
		theme_cheapareas: 'goedkope-skigebieden',
		theme_tours: 'skigebieden-voor-tochten',
		theme_snowboardfriendly: 'skigebieden-voor-snowboarders',
		theme_quietareas: 'rustige-skigebieden',
		theme_big: 'grote-skigebieden-oostenrijk',
		theme_small: 'kleine-skigebieden',
		theme_apresski: 'de-beste-apres-ski-bestemmingen',
		theme_authentic: 'authentieke-skidorpen',
		theme_pisteinresort: 'pistes-tot-in-het-dorp',
		theme_snowguaranteed: 'sneeuwzekere-skigebieden',
		theme_summerski: 'zomer-gletsjer-skigebieden',
		...Object.keys(countries.nl).reduce((carry, iso) => {
			carry[iso] = countries.nl[iso].slug;
			return carry;
		}, {})
	},
	'de-DE': {
		profile: 'profil',
		areas: 'skigebiete',
		areas_overview: 'skigebiete/ubersicht-lander-mit-wintersportgebieten',
		areas_theme: 'skigebiete/thema',
		areas_skihalls: 'skihallen',
		areas_dryslopes: 'borstelbanen',
		areas_revolvingslopes: 'rolbanen',
		areas_localdestinations: 'wintersport-in-de-buurt',
		areas_countries: 'skigebiete/ubersicht-lander-mit-wintersportgebieten',
		areas_compare: 'skigebiete/vergelijken',
		areas_skipasses: 'skigebiete/skipasprijzen',
		areas_top50: 'skigebiete/top50',
		areas_airports: 'vliegvelden',
		weblog_message: 'blog/bericht',
		snowforecast: 'schneevorhersage',
		snowfall: 'schneefall',
		snowradar: 'schneeradar',
		sun: 'sonnenschein',
		clouds: 'bewoelkung',
		temperature: 'temperaturen',
		temp850: 'temperatur-auf-den-bergen',
		wind: 'wind',
		jetstream: 'jetstream',
		resorts: 'skiorte',
		area_slug: 'skigebiete',
		resort_slug: 'skiorte',
		overview: 'ubersicht-lander-mit-wintersportgebieten',
		destination: 'skigebiet',
		favorites: 'favoriten',
		travelogues: 'reisverslagen',
		weather_snow: 'weer-en-sneeuw',
		gear: 'materiaal-en-techniek',
		travel: 'reizen',
		marketplace: 'marktplaats',
		photo_video: 'fotos-en-filmpjes',
		meet_greet: 'meet-en-greet',
		work: 'werken-in-de-sneeuw',
		stube: 'stube',
		accommodations: 'unterkuenfte',
		apartments: 'appartementen',
		chalets: 'chalets',
		hotels: 'hotels',
		pensions: 'pensions',
		max_distance_berlin: 'berlin',
		theme_childfriendly: 'kinderfreundliche-skigebiete',
		theme_beginners: 'skigebiete-fur-anfanger',
		theme_advanced: 'skigebiete-fur-fortgeschrittene',
		theme_offpiste: 'die-besten-skigebiete-abseits-der-piste',
		theme_cheapareas: 'preisgunstige-skigebiete',
		theme_tours: 'skigebiete-fur-rundtouren',
		theme_snowboardfriendly: 'skigebiete-ideal-fur-snowboarder',
		theme_quietareas: 'ruhige-skigebiete',
		theme_big: 'grosse-skigebiete-in-osterreich',
		theme_small: 'kleine-skigebiete',
		theme_apresski: 'der-beste-apres-ski',
		theme_authentic: 'authentische-skiorte',
		theme_pisteinresort: 'skiorte-mit-talabfahrten',
		theme_snowguaranteed: 'schneesichere-skigebiete',
		theme_summerski: 'gletscherskigebiete-sommer',
		...Object.keys(countries.de).reduce((carry, iso) => {
			carry[iso] = countries.de[iso].slug;
			return carry;
		}, {})
	}
};

slugs['nl-BE'] = { ...slugs['nl-NL'] };

const invert = data => {
	return Object.keys(data).reduce(function (obj, key) {
		obj[data[key]] = key;
		return obj;
	}, {});
};

export const deslugged = {
	...invert(slugs['nl-NL']),
	...invert(slugs['de-DE']),
	...invert(slugs['nl-BE'])
};

export default { slugs, deslugged };
