var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ws-dialog',{attrs:{"title":_vm.$t('login.modal:heading')},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-alert',{staticClass:"mb-3",attrs:{"value":_vm.showActivated,"type":"success","color":"success","border":"bottom"}},[_vm._v(_vm._s(_vm.$t('page.login:account.activated')))]),_c('v-alert',{staticClass:"mb-3",attrs:{"value":_vm.error.show,"type":"error","color":"error"}},[_vm._v(_vm._s(_vm.error.message))]),_c('v-card-text',{staticClass:"mt-3"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{attrs:{"rules":"required|email","name":_vm.$t('page.login:email.field.label'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('v-text-field',{staticClass:"mb-3",attrs:{"label":_vm.$t('page.login:email.field.label'),"required":"","outlined":"","hide-details":"auto","error-messages":touched ? errors : []},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('page.login:password.field.label'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('v-text-field',{staticClass:"mb-3",attrs:{"type":"password","label":_vm.$t('page.login:password.field.label'),"required":"","outlined":"","hide-details":"auto","error-messages":touched ? errors : []},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),(_vm.step === '2fa')?[_c('validation-provider',{staticClass:"mb-3",attrs:{"rules":"required|digits:6","name":"token","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('v-text-field',{attrs:{"outlined":"","label":"2FA Token","autofocus":"","error-messages":touched ? errors : [],"hide-details":"auto"},model:{value:(_vm.token),callback:function ($$v) {_vm.token=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"token"}})]}}],null,true)})]:_vm._e(),_c('v-btn',{attrs:{"type":"submit","block":"","color":"accent","depressed":"","disabled":invalid}},[_vm._v(_vm._s(_vm.$t('page.login:login.button')))])],2)]}}])})],1),_c('div',{staticClass:"text-center pb-4"},[_c('nuxt-link',{staticClass:"d-block",attrs:{"to":_vm.localePath({name:'forgot'})}},[_vm._v(_vm._s(_vm.$t('page.login:forgot.password.link')))]),_c('nuxt-link',{staticClass:"d-block",attrs:{"to":_vm.localePath({name:'register', query: {redirect: encodeURIComponent(_vm.$route.fullPath)}})}},[_vm._v(_vm._s(_vm.$t('page.login:register.link')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }