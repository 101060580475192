<script>
export default {
	functional: true,
	props: {
		vertical: { type: Boolean, default: false }
	},
	render(createElement, context) {
		let color = 'grey';

		if (
			context.data.staticClass &&
			context.data.staticClass.includes('--text')
		) {
			color = 'black';
		}

		const children = context.children.map(child => {
			const liChildren = [child.children];
			if (child.data && child.data.attrs && child.data.attrs.icon) {
				const iconType = Object.prototype.hasOwnProperty.call(
					child.data.attrs,
					'vuetify-icon'
				)
					? 'v-icon'
					: 'ws-icon';

				liChildren.unshift(
					createElement(
						iconType,
						{ class: 'mr-1', props: { small: true, color } },
						[child.data.attrs.icon]
					)
				);
			}

			return createElement(
				'li',
				{ class: context.props.vertical ? '' : 'd-inline-block mr-2' },
				liChildren
			);
		});

		return createElement(
			'ul',
			{
				class:
					context.data.staticClass &&
					context.data.staticClass.includes('--text')
						? ['list-unstyled', context.data.staticClass]
						: ['grey--text', 'list-unstyled', context.data.staticClass]
			},
			children
		);
	}
};
</script>
