<template lang="pug">
v-row.ma-0(dense)
	v-col(v-bind="colprops")
		v-text-field(readonly solo outlined flat dense hide-details :label="$t('global.snowalarm.form.departure')" @click="departureModal = true" :value="$dayjs(_date).format('DD MMM YYYY')")
			template(#append)
				ws-icon(@click="departureModal = true" small) calendar
		ws-dialog(v-model="departureModal" :title="$t('global.snowalarm.form.departure')")
			v-row(no-gutters)
				v-col.shrink(v-for="month in ['2024-12', '2025-01', '2025-02', '2025-03', '2025-04']" :key="month")
					v-date-picker.snow-alarm--datepicker.elevation-0(v-model="_date" :min="new Date().toISOString().substr(0, 10)" width="250" :picker-date="month" first-day-of-week="1" no-title  @input="departureModal = false" :locale="$i18n.locale")
	v-col(v-bind="colprops")
		v-select(:label="$t('components.accommodations.minimum-filter.duration-label')" outlined solo flat dense hide-details :items="nightOptions" v-model.number="_duration")
	v-col(v-bind="colprops")
		v-select(:label="$t('components.accommodations.minimum-filter.persons-label')" outlined solo flat dense hide-details :items="personsOptions" v-model.number="_persons" )
	v-col.shrink(v-bind="colprops" v-if="!disableAction")
		slot(name="action" v-bind:update="update")
			v-btn(color="accent" small depressed block @click="update") {{$t('components.accommodations.minimum-filter.search-button')}}

</template>

<script>
import CompactDatepicker from '@dengl/site/components/compact-datepicker.vue';
import { ref, computed, watch } from '@vue/composition-api';

export default {
	components: {
		CompactDatepicker
	},
	props: {
		horizontal: {
			type: Boolean,
			default: false
		},
		instant: {
			type: Boolean,
			default: false
		},
		disableAction: {
			type: Boolean,
			default: false
		},
		date: {
			type: String,
			default: '2025-02-15'
		},
		duration: {
			type: Number,
			default: 7
		},
		persons: {
			type: Number,
			default: 2
		}
	},
	setup(props, { emit }) {
		const _duration = ref(props.duration);
		const _persons = ref(props.persons);
		const _date = ref(props.date);
		const departureModal = ref(false);
		const isMounted = ref(false);
		const filters = computed(() => {
			return {
				duration: _duration.value,
				persons: _persons.value,
				date: _date.value
			};
		});

		const update = () => {
			localStorage.setItem('minimumFilters', JSON.stringify(filters.value));
			emit('update', filters.value);
		};

		if (props.instant) {
			watch(filters, update);
		}

		watch(
			() => [props.duration, props.date, props.persons],
			() => {
				_duration.value = props.duration;
				_persons.value = props.persons;
				_date.value = props.date;
			}
		);

		return { _duration, _persons, _date, update, departureModal, isMounted };
	},
	computed: {
		minDate() {
			const now = this.$dayjs();
			let seasonStart = this.$dayjs('2022-12-01');
			if (now.isAfter(seasonStart)) seasonStart = now;
			return seasonStart.format('YYYY-MM-DD');
		},
		colprops() {
			if (
				!this.horizontal ||
				(this.isMounted && this.$vuetify.breakpoint.xsOnly)
			) {
				return {
					cols: 12
				};
			}
			return {};
		},
		nightOptions() {
			return [...Array(14).keys()].map(index => {
				const nights = index + 1;
				return {
					text: this.$tc('accommodations-filters.nights', nights),
					value: nights
				};
			});
		},
		personsOptions() {
			return [...Array(50).keys()].map(index => {
				const persons = index + 1;
				return {
					text: this.$tc('accommodations-filters.persons', persons),
					value: persons
				};
			});
		}
	},
	mounted() {
		this.isMounted = true;
	}
};
</script>
