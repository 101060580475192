import WSIcon from '@dengl/common/components/ws-icon.vue';

const configuration = {
	default: { color: '#000000', icon: 'page' },
	sticky: { color: '#c0392b', icon: 'sticky' },
	destination: { color: '#000000', icon: 'chair-lift' },
	travelogues: { color: '#e67e22', icon: 'pencil' },
	weather_snow: { color: '#2980b9', icon: 'snowflake' },
	gear: { color: '#e74c3c', icon: 'tags' },
	travel: { color: '#34495e', icon: 'suitcase' },
	marketplace: { color: '#bdc3c7', icon: 'cart' },
	photo_video: { color: '#000000', icon: 'camera' },
	meet_greet: { color: '#217dbb', icon: 'comments' },
	work: { color: '#7f8c8d', icon: 'inbox' },
	stube: { color: '#f1c40f', icon: 'beer' },
	weblog: { color: '#1abc9c', icon: 'weblog' },
	news: { color: '#679BCA', icon: 'receipt', material: true }
};

export default {
	functional: true,

	render(createElement, { data, children }) {
		let icon = configuration.default;
		if (children[0].text) {
			const iconName = children[0].text;
			icon = configuration[iconName] || configuration.default;
		}

		if (icon) {
			data.props = { color: icon.color };
			if (icon.material) {
				return createElement('v-icon', data, [icon.icon]);
			}
			return createElement(WSIcon, data, [icon.icon]);
		} else {
			return createElement(WSIcon, data, children);
		}
	}
};
