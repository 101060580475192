import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import { VBtn, VChip, VCol, VContainer, VDataTable, VIcon, VImg, VRating, VRow, VSheet, VCard, VCardText, VDivider } from 'vuetify/lib'

import options from './options'

Vue.use(Vuetify, {
  components: { VBtn, VChip, VCol, VContainer, VDataTable, VIcon, VImg, VRating, VRow, VSheet, VCard, VCardText, VDivider }
})

export default (ctx) => {
  const vuetifyOptions = typeof options === 'function' ? options(ctx) : options

  const vuetify = new Vuetify(vuetifyOptions)

  ctx.app.vuetify = vuetify
  ctx.$vuetify = vuetify.framework
}
