<template lang="pug">
v-responsive(:class="componentClasses" :aspect-ratio="16/9")
	.static-map-holder(ref="holder")
		img.forecast-overlay(:src="overlay")
		img.forecast-overlay(v-if="showPressure" :src="pressureOverlay")
		div(:class="`country-overlay country-overlay-${$i18n.locale.substr(0,2).toLowerCase()}`")
		img.country-resizer(:src="`https://static.wintersport.nl/images/bg-map-${weatherregion}.jpg`" ref="country-resizer")
		.dot(v-if="dot" :style="dotStyle")
		slot
</template>

<script>
import { lngLatToPixels, forecastOverlayUrl } from './utils.js';

export default {
	props: {
		hour: {
			type: Number,
			default: 0
		},
		weatherregion: {
			type: String,
			default: 'alps'
		},
		mini: {
			type: Boolean,
			default: false
		},
		showPressure: {
			type: Boolean,
			default: false
		},
		dot: {
			type: Array,
			default: null
		},
		type: {
			type: String,
			default: 'snowforecast'
		},
		rundate: {
			type: String,
			default() {
				return this.$store.state.rundate;
			}
		},
		runhour: {
			type: String,
			default() {
				return '18';
			}
		}
	},

	computed: {
		overlay() {
			return forecastOverlayUrl(
				this.rundate,
				'18', // this.runhour,
				this.type,
				this.hour,
				this.weatherregion
			);
		},
		pressureOverlay() {
			return forecastOverlayUrl(
				this.rundate,
				'18', // this.runhour,
				'pressure',
				this.hour,
				this.weatherregion
			);
		},
		componentClasses() {
			const classes = [];
			classes.push('regionmap');
			classes.push(`regionmap-${this.weatherregion}`);
			if (this.mini) {
				classes.push('regionmap__mini');
			}

			return classes;
		},
		dotStyle() {
			if (!this.dot || !this.$refs['country-resizer']) {
				return null;
			}
			const { left, top } = lngLatToPixels(
				this.dot[0],
				this.dot[1],
				this.$refs['country-resizer'].clientHeight,
				this.$refs['country-resizer'].clientWidth ||
					this.$store.state.content_width.full,
				this.weatherregion
			);

			const style = {
				display: 'block',
				left: left + 'px',
				top: top + 'px'
			};
			return style;
		}
	}
};
</script>

<style lang="sass">
.regionmap
	$self: &
	.static-map-holder
		background-image: url('//static.wintersport.nl/images/bg-map-alps.jpg')
		background-size: cover
		display: block
		position: absolute

	.country-overlay,.country-overlay-de
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		background-size: cover

	.country-resizer
		position: relative
		visibility: hidden
		width: 100%

	.forecast-overlay
		position: absolute
		opacity: 0.8
		display: block
		width: 100%
		height: auto

	.dot
		position: absolute
		border: 2px solid white
		background: #2f619b
		width: 10px
		height: 10px
		margin-left: -5px
		margin-top: -5px
		border-radius: 50%
		display: none

	&-alps
		.country-overlay-nl
			background-image: url('//static.wintersport.nl/images/bg-map-overlay.png')
		.country-overlay-de
			background-image: url('//static.wintersport.nl/images/bg-map-overlay-de.png')

		.static-map-holder
			top: -10px
			bottom: 0px
			left: -10px
			right: -10px

	&-europe
		.country-overlay-nl
			background-image: url('//static.wintersport.nl/images/bg-europe-overlay.png')
		.country-overlay-de
			background-image: url('//static.wintersport.nl/images/bg-europe-overlay-de.png')

		.static-map-holder
			background-image: url('//static.wintersport.nl/images/bg-map-europe.jpg')
			top: -80px
			bottom: 0px
			left: 0px
			right: -10px

	&-sauerland
		.country-overlay
			background-image: url('//static.wintersport.nl/images/bg-sauerland-overlay.png')

		.static-map-holder
			background-image: url('//static.wintersport.nl/images/bg-map-sauerland.jpg')
			top: -30px
			bottom: 0px
			left: -10px
			right: -30px

	&__mini
		.country-overlay
			opacity: 0.3

		.static-map-holder
			top: -10px

		&#{ $self }-sauerland .country-overlay
			background-image: none

		&#{ $self }-alps .country-overlay
			background-image: url('//static.wintersport.nl/images/bg-map-overlay-mini.png')

		&#{ $self }-europe .country-overlay
			background-image: url('//static.wintersport.nl/images/bg-europe-overlay-mini.png')
</style>
