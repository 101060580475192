<template lang="pug">
snow-alarm(horizontal)
</template>

<script>
import gql from 'graphql-tag';

export default {
	name: 'EmbedSnowalarm',
	fragments: gql`
		fragment SnowalarmEmbedDataFragment on Embed {
			data {
				... on EmbedDataSnowalarm {
					name
				}
			}
		}
	`
};
</script>
