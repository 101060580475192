<template lang="pug">
dnt(thirdParty="instagram" thirdPartyName="instagram.com")
	v-container
		v-row(justify="center")
			v-col(cols="12" lg="8")
				vue-instagram-embed(:url="url" :max-width="700" :appID="'120994591283453'" :client-token="'c7e5348dda5b0e5748cacae968e09ffb'")
</template>

<script>
import gql from 'graphql-tag';
import dnt from '@dengl/common/components/dnt';
import fields from './fields.js';
import VueInstagramEmbed from './instaEmbed.js';

export default {
	name: 'InstagramEmbed',
	components: { VueInstagramEmbed, dnt },
	props: fields,
	fragments: gql`
		fragment InstagramEmbedDataFragment on Embed {
			data {
				... on EmbedDataInstagram {
					url
				}
			}
		}
	`
};
</script>
