export default {
	name: 'vue-instagram-embed',
	props: {
		url: {
			type: String
		},
		maxWidth: {
			type: Number,
			default: 320
		},
		className: {
			type: String
		},
		omitScript: {
			type: Boolean,
			default: true
		},
		appID: {
			type: String
		},
		clientToken: {
			type: String
		}
	},
	data() {
		return {
			html: ''
		};
	},
	mounted() {
		if (window.FB) {
			this.fetchEmbed();
		} else {
			this.injectScript();
			this.checkAPI().then(() => {
				this.fetchEmbed();
			});
		}
	},
	methods: {
		fetchEmbed() {
			const maxWidth = this.maxWidth >= 320 ? this.maxWidth : 320;
			const url = `https://graph.facebook.com/v10.0/oembed_video?url=https://www.facebook.com/facebook/videos/10153231379946729&maxwidth=${maxWidth}&omitscript=${this.omitScript}&access_token=${this.appID}|${this.clientToken}`;
			fetch(url)
				.then(res => {
					if (res.ok) {
						return res.json();
					}
				})
				.then(data => {
					this.html = data.html;
					this.$nextTick(() => {
						window.FB.XFBML.parse();
					});
				})
				.catch(e => {
					throw new Error(e);
				});
		},
		validateUrl() {
			const urlRe = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
			if (!urlRe.test(this.url)) {
				throw new Error(`${this.url} - Invalid url`);
			}
		},
		/**
		 * inject instagram embed script
		 */
		injectScript() {
			const protocolToUse = 'https:';
			const s = document.createElement('script');
			s.async = s.defer = true;
			s.src = `${protocolToUse}//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2`;
			s.id = 'vue-facebook-embed-script';
			const body = document.body;
			if (body) {
				body.appendChild(s);
			}
		},
		/**
		 * Check for window.instgrm
		 */
		checkAPI() {
			return new Promise(resolve => {
				(function checkAPI(self) {
					self.timer = window.setTimeout(() => {
						if (window.FB) {
							clearTimeout(self.timer);
							resolve();
						} else {
							checkAPI(self);
						}
					}, 20);
				})(this);
			});
		}
	},
	render() {
		return <div class={this.className} domPropsInnerHTML={this.html}></div>;
	}
};
