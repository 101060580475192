export default {
	functional: true,

	render(createElement, context) {
		if (Object.prototype.hasOwnProperty.call(context.props.to, '__typename')) {
			if (!context.props.to.slug) {
				return context.children;
			}

			switch (context.props.to.__typename) {
				case 'Profile':
					context.data.props = {
						to: context.parent.localePath({
							name: 'profile-slug',
							params: { slug: context.props.to.slug }
						})
					};
					break;
				case 'User':
					context.data.props = {
						to: context.parent.localePath({
							name: 'profile-slug',
							params: { slug: context.props.to.slug }
						})
					};
					break;
				case 'Country':
					context.data.props = {
						to: context.parent.localePath({
							name: 'areas-countries-country',
							params: { country: context.props.to.slug }
						})
					};

					break;
				case 'Area':
					context.data.props = {
						to: context.parent.localePath({
							name: 'areas-area',
							params: { area: context.props.to.slug }
						})
					};

					break;
				case 'Resort':
					context.data.props = {
						to: context.parent.localePath({
							name: 'resorts-resort',
							params: { resort: context.props.to.slug }
						})
					};

					break;
				case 'Cluster':
					context.data.props = {
						to: context.parent.localePath({
							name: 'clusters-cluster',
							params: { cluster: context.props.to.slug }
						})
					};

					break;
				case 'Organisation':
					context.data.props = {
						to: context.parent.localePath({
							name: 'localdestinations-organisation',
							params: { organisation: context.props.to.slug }
						})
					};
					break;
				case 'Skihall':
				case 'Dryslope':
				case 'Revolvingslope':
					context.data.props = {
						to: context.parent.localePath({
							name: `localdestinations-${context.props.to.__typename.toLowerCase()}s-slug`,
							params: { slug: context.props.to.slug }
						})
					};

					break;
				default:
					console.warn(
						`${context.props.to.__typename} is not a valid magic link type`
					);
					return context.children;
			}
		}

		return createElement('nuxt-link', context.data, context.children);
	}
};
