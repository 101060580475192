<template lang="pug">

v-app
	main-nav-de(v-if="$i18n.locale === 'de-DE'" :title="title" @shownav="setDrawer(true)")
	main-nav(v-else :title="title" @shownav="setDrawer(true)")
	v-navigation-drawer(@input="setDrawer($event)" :value="showMobilePageNav" app clipped temporary fullscreen)
		v-container.navigation
			v-btn.mt-6(v-if="adminPath" target="_blank" :href="adminPath" x-small fab top right absolute color="primary")
				v-icon edit
			portal-target(name="drawer-nav")
	nuxt
	foot

</template>

<script>
import MainNav from '@/components/main/top-navigation.vue';
import Foot from '@/components/foot.vue';
import { mapState, mapMutations } from 'vuex';

const MainNavDe = () =>
	import(
		/* webpackChunkName: "mainNavDE" */ '@/components/main/top-navigation.de.vue'
	);

export default {
	name: 'DefaultLayout',
	components: {
		MainNav,
		MainNavDe,
		Foot
	},
	computed: {
		showMobilePageNav() {
			return this.showDrawer && this.$vuetify.breakpoint.xsOnly;
		},
		...mapState({
			showDrawer: 'showNavigationDrawer',
			title: state => state.meta.title,
			adminPath: state => state.meta.adminPath
		})
	},
	methods: {
		...mapMutations({
			setDrawer: 'SET_SHOW_NAVIGATION_DRAWER'
		})
	}
};
</script>
