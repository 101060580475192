<script>
import { parseEmbed, fragments } from '@dengl/embeds/index.js';
import gql from 'graphql-tag';

export default {
	props: {
		value: {
			type: Object,
			default: () => {
				return { markdown: '', embeds: [] };
			}
		},
		html: {
			type: String,
			default: ''
		},
		markdown: {
			type: String,
			default: ''
		},
		embeds: {
			type: Array,
			default: () => []
		},
		level: {
			type: [Number, String],
			default: 2
		},
		context: {
			type: Object,
			default: () => {}
		},
		tag: {
			type: String,
			default: 'div'
		},
		parserType: {
			type: String,
			default: 'ALL'
		}
	},
	data() {
		return {
			content: {}
		};
	},
	computed: {
		renderedContent() {
			let result = '';
			result = this.content.markdown;

			if (this.context) {
				result = result.replace(/::([^:]*)::/g, (match, term) => {
					const tree = term.split('.');

					return tree.reduce((carry, item) => {
						return carry[item] || '';
					}, this.context);
				});
			} else {
				result = result.replace(/::([^:]*)::/g, (match, term) => {
					return this.$env.APP_ENV === 'production'
						? term
						: `<i class="error--text">${term}</i>`;
				});
			}

			if (!result.length) {
				result = '{{slot}}';
			}
			return result;
		}
	},
	watch: {
		markdown() {
			this.content.markdown = this.markdown;
		},
		html: 'contentUpdated',
		value() {
			this.contentUpdated();
			this.content = this.value;
			if (this.markdown.length) {
				this.content.markdown = this.markdown;
			}
			if (this.embeds.length) {
				this.content.embeds = this.embeds;
			}
		}
	},
	created() {
		this.content = this.value;
		if (this.markdown.length) {
			this.content.markdown = this.markdown;
		}
		if (this.embeds.length) {
			this.content.embeds = this.embeds;
		}
	},
	mounted() {
		this.$nextTick(this.addListeners);
	},
	beforeDestroy() {
		this.removeListeners();
	},
	methods: {
		navigate(event) {
			let target = event.target;
			let i = 0;

			// Go throught 5 parents max to find a tag
			while (
				i < 5 &&
				!(target instanceof HTMLAnchorElement) &&
				target.parentNode
			) {
				target = target.parentNode;
				i++;
			}
			// If target is still not a link, ignore
			if (!(target instanceof HTMLAnchorElement)) return;

			const href = target.getAttribute('href');

			// Get link target, if local link, navigate with router link
			if (
				href &&
				href[0] === '/' &&
				this.$router.match(href).matched.length &&
				!target.getAttribute('target')
			) {
				event.preventDefault();
				this.$router.push(href);
			}
			// If Google Analytics is activated & is external link
			else if (this.$ga) {
				// https://developers.google.com/analytics/devguides/collection/analyticsjs/events
				this.$ga('send', 'event', 'Outbound Link', 'click', target.href);
			}
		},
		contentUpdated() {
			this.removeListeners();
			this.$nextTick(() => {
				this.addListeners();
			});
		},
		addListeners() {
			this._links = this.$el.querySelectorAll('.md-generated a');
			for (let i = 0; i < this._links.length; i++) {
				this._links[i].addEventListener('click', this.navigate, false);
			}
		},
		removeListeners() {
			if (this._links) {
				for (let i = 0; i < this._links.length; i++) {
					this._links[i].removeEventListener('click', this.navigate, false);
				}
			}
			this._links = [];
		}
	},
	render(createElement) {
		if (this.renderedContent) {
			const plainparts = this.renderedContent.split(
				/(\{\{[slot|embed]:?[^}]*\}\})/gm
			);

			const plainElements = plainparts.map(content => {
				const match = /\{\{(slot|embed):?([^}]*)\}\}/gm.exec(content);
				if (match) {
					const slottype = match[1];
					const slotname = match[2] ? match[2] : 'default';

					return slottype === 'slot'
						? createElement(
								'div',
								{
									class: ['flow-left']
								},
								this.$slots[slotname]
						  )
						: parseEmbed(createElement, slotname, this.content.embeds || []);
				}
				return createElement(this.tag, {
					class: ['md-generated'],
					domProps: {
						innerHTML: content
					}
				});
			});

			if (this.$slots.default && plainElements.length === 1) {
				plainElements.push(this.$slots.default);
			}

			return createElement(this.tag, { class: ['ws-content'] }, plainElements);
		}
		return createElement(this.tag);
	},
	fragments: gql`
		fragment ContentDataFragment on Content {
			markdown
			embeds {
				type
				slug
				...EmbedDataFragment
			}
			media {
				photos {
					url
					alt
				}
			}
		}
		${fragments}
	`
};
</script>
