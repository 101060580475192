<template lang="pug">
client-only
	v-responsive(v-if="$vuetify.breakpoint.xsOnly" width="100%" style="z-index:10")
		v-autocomplete.nav-search(
				ref="search"
				return-object
				v-model="selection"
				item-text="name"
				item-value="id"
				hide-details
				flat
				solo
				no-filter
				:loading="isSearching"
				:items="results"
				:search-input.sync="search"
				@keyup.enter.native="onEnter()"
				@blur="onBlur"
				@focus="onFocus"
				:list-index.sync="selectedIndex"
				background-color="grey lighten-4"
				outlined
				)
			template(#label)
				label.primary--text Zoeken
			template(#append)
				v-btn.ma-0(@click="onEnter" color="accent" depressed icon small)
					v-icon search
			template(#item="data")
				v-list-item-avatar(v-if="data.item.countryCode" size="20" tile)
					img.flag.ma-0(:src="`https://static.wintersport.nl/images/flags/svg/${data.item.countryCode}.svg`" width="16")
				v-list-item-content {{data.item.name}}
				v-list-item-avatar(size="20")
					ws-icon(small) {{icons[data.item.__typename]}}

	v-responsive.mr-0.transition-swing(v-else :max-width="isFocused ? 300 : 150")
		v-autocomplete.nav-search(
				ref="search"
				return-object
				v-model="selection"
				item-text="name"
				item-value="id"
				hide-details
				flat
				dense
				rounded
				solo
				no-filter
				:loading="isSearching"
				:items="results"
				:search-input.sync="search"
				@keyup.enter.native="onEnter()"
				@blur="onBlur"
				@focus="onFocus"
				:list-index.sync="selectedIndex"
				background-color="grey lighten-4"
				outlined
				)
			template(#label)
				label.primary--text {{$t('component.top.navigation.search:search')}}
			template(#append)
				v-btn.ma-0.mr-n4(@click="onEnter" color="accent" depressed icon small)
					v-icon search
			template(#item="data")
				v-list-item-avatar(v-if="data.item.countryCode" size="20" tile)
					img.flag.ma-0(:src="`https://static.wintersport.nl/images/flags/svg/${data.item.countryCode}.svg`" width="16")
				v-list-item-content {{data.item.name}}
				v-list-item-avatar(size="20")
					ws-icon(small) {{icons[data.item.__typename]}}
</template>

<style lang="sass">
.nav-search.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset
	border-color: var(--v-border-base) !important
.nav-search.v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset
	border-color: var(--v-border-base) !important
	border-width: 1px
</style>

<script>
import gql from 'graphql-tag';

export default {
	name: 'TopNavigationSearch',
	data: () => ({
		isFocused: false,
		isSearching: false,
		search: '',
		selectedIndex: -1,
		results: [],
		selection: {},
		icons: {
			Area: 'ski',
			Resort: 'village',
			Cluster: 'ski',
			Organisation: 'organisation',
			Skihall: 'skihall',
			Revolvingslope: 'revolvingslope',
			Dryslope: 'dryslope',
			Carpetslope: 'carpetslope'
		}
	}),
	watch: {
		search(val) {
			if (!val || val.length < 3) return;
			this.searchDestinations(val);
		},
		selection(val) {
			if (this.$vuetify.breakpoint.xsOnly) {
				this.$emit('toggle');
			}
			this.selectSearch(val);
		}
	},
	methods: {
		onBlur() {
			this.resetSearch();
		},
		onEsc() {
			this.$refs.search.blur();
		},
		onFocus() {
			this.isFocused = true;
		},
		resetSearch() {
			this.$nextTick(() => {
				this.isFocused = false;
				this.results = [];
				this.search = undefined;
				this.isSearching = false;
				this.selection = null;
				this.selectedIndex = -1;
			});
		},
		onEnter() {
			const searchString = this.$lodash.cloneDeep(this.search);

			if (this.selectedIndex === -1 && searchString !== null) {
				this.$refs.search.blur();

				this.$router.push(
					this.localePath({
						name: 'search',
						query: { q: searchString }
					})
				);
			}
		},
		searchDestinations(val) {
			this.isSearching = true;

			const searchQuery = gql`
				query search($val: String!, $types: [String]!, $limit: Int!) {
					search(term: $val, types: $types, limit: $limit) {
						__typename
						... on Country {
							id
							name
							slug
							countryCode
						}
						... on Region {
							id
							name
							slug
							country {
								id
								countryCode
							}
						}
						... on Area {
							id
							name
							slug
							country {
								id
								countryCode
							}
						}
						... on Resort {
							id
							name
							slug
							country {
								id
								countryCode
							}
						}
						... on Cluster {
							id
							name
							slug
							areas {
								country {
									id
									countryCode
								}
							}
						}
						... on Organisation {
							id
							name
							slug
							address {
								countryCode: countrycode
							}
						}
						... on Skihall {
							id
							name
							slug
							address {
								countryCode: countrycode
							}
						}
						... on Revolvingslope {
							id
							name
							slug
							address {
								countryCode: countrycode
							}
						}
						... on Dryslope {
							id
							name
							slug
							address {
								countryCode: countrycode
							}
						}
					}
				}
			`;

			this.$graphClient
				.query({
					query: searchQuery,
					variables: {
						val,
						types: [
							'countries',
							'regions',
							'areas',
							'resorts',
							'clusters',
							'organisations',
							'skihalls',
							'revolvingslopes',
							'dryslopes'
						],
						limit: 8
					}
				})
				.then(({ data }) => {
					this.results = this.formattedSearchResult(data.search);
				})
				.catch(_err => {})
				.finally(() => (this.isSearching = false));
		},
		selectSearch(item) {
			if (!item) return false;

			const toConfig = {
				Country: {
					name: 'areas-countries-country',
					params: { country: item.slug }
				},
				Region: { name: 'areas-regions-region', params: { region: item.slug } },
				Area: { name: 'areas-area', params: { area: item.slug } },
				Resort: { name: 'resorts-resort', params: { resort: item.slug } },
				Cluster: {
					name: 'clusters-cluster',
					params: {
						cluster: item.slug
					}
				},
				Organisation: {
					name: 'localdestinations-organisation',
					params: {
						organisation: item.slug
					}
				},
				Skihall: {
					name: 'localdestinations-skihalls-slug',
					params: {
						slug: item.slug
					}
				},
				Revolvingslope: {
					name: 'localdestinations-revolvingslopes-slug',
					params: {
						slug: item.slug
					}
				},
				Dryslope: {
					name: 'localdestinations-dryslopes-slug',
					params: {
						slug: item.slug
					}
				},
				SiteSearch: {
					name: 'search',
					query: {
						q: this.search
					}
				}
			};
			const to = toConfig[item.__typename];

			if (to) {
				this.$router.push(this.localePath(to));
			}
		},
		formattedSearchResult(results) {
			results.push({
				id: '42',
				name: this.$t('search:go.to.search.page'),
				slug: 'SiteSearch',
				__typename: 'SiteSearch'
			});
			return results.map(r => {
				if (['Region', 'Area', 'Resort'].includes(r.__typename))
					return {
						...r,
						countryCode: r.country ? r.country.countryCode : null
					};
				if (['Cluster'].includes(r.__typename))
					return {
						...r,
						countryCode: r.areas.length ? r.areas[0].countryCode : null
					};
				if (
					['Organisation', 'Skihall', 'Revolvingslope', 'Dryslope'].includes(
						r.__typename
					)
				)
					return {
						...r,
						countryCode: r.address ? r.address.countryCode : null
					};
				return r;
			});
		}
	}
};
</script>
