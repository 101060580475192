<template lang="pug">

v-expansion-panels.md-generated(accordion multiple)
	v-expansion-panel(v-for="(item, index) in items" :key="index")
		v-expansion-panel-header.font-weight-bold {{item.question}}
		v-expansion-panel-content
			div(v-html="item.answer")

</template>

<script>
import gql from 'graphql-tag';

export default {
	name: 'FaqEmbed',
	props: {
		items: {
			type: Array,
			required: true
		}
	},
	data() {
		return {};
	},
	created() {
		const currentFaqItems = this.$store.state.jsonld.FAQPage
			? this.$store.state.jsonld.FAQPage.mainEntity
			: [];

		// voeg vragen toe die nog niet in store stonden
		this.$store.commit('SET_JSONLD', {
			type: 'FAQPage',
			data: {
				mainEntity: [
					...currentFaqItems,
					...this.items
						.filter(item => {
							return !currentFaqItems.find(i => i.name === item.question);
						})
						.map(item => {
							return {
								'@type': 'Question',
								name: item.question,
								acceptedAnswer: {
									'@type': 'Answer',
									text: item.answer
								}
							};
						})
				]
			}
		});
	},
	beforeDestroy() {
		const currentFaqItems = this.$store.state.jsonld.FAQPage
			? this.$store.state.jsonld.FAQPage.mainEntity
			: [];

		// verwijder vragen uit de store die in deze embed zitten
		const remainingFaqItems = currentFaqItems.filter(item => {
			return !this.items.find(i => i.question === item.name);
		});

		this.$store.commit('SET_JSONLD', {
			type: 'FAQPage',
			data: { mainEntity: remainingFaqItems }
		});
	},
	fragments: gql`
		fragment FaqEmbedDataFragment on Embed {
			data {
				... on EmbedDataFaq {
					items {
						question
						answer
					}
				}
			}
		}
	`
};
</script>
