<template lang="pug">
v-footer.hidden-sm-and-down( height="auto" padless)
	.window.py-0
		v-container
			v-row
				v-col
					i18n.grey--text.caption(path="global.footer.tagline_{link}")
						template(#link)
							a(href="/over-ons") {{$t('global.footer.tagline.link.text')}}
			v-row
				v-divider
			v-row.py-2(justify="center")
				v-col(v-for="item in footListsWithLocale" :key="item.title")
					strong.d-block {{ item.title }}
					ul.list-unstyled
						li(v-for="item in item.list" :key="item.url")
							a.never-visited(:href="item.url" :title="item.url_title" :target="item.url.startsWith('/') ? '_self' : '_blank'") {{ item.title }}

			template(v-if="$i18n.locale === 'nl-NL' || $i18n.locale === 'nl-BE'")
				v-row
					v-divider
				v-row.caption(justify="start" align="center")
					v-col.pr-0.shrink.text-no-wrap
						strong Een website van de
					v-col.shrink
						a(href="https://www.nskiv.nl" target="_blank" rel="nofollow")
							img(src="~/static/images/logo.svg" loading="lazy" height="36px")
				v-row.caption(justify="start" align="center")
					v-col.pr-0.shrink.text-no-wrap
						strong Official partners
					v-col.shrink
						a(href="https://www.nederlandseloterij.nl/spelen-helpt/sport" title="Nederlandse Loterij" target="_blank" rel="nofollow")
							v-img(:src="require('~/static/images/logo_nederlandseloterij.png')" max-width="105" loading="lazy" height="36px")
					v-col.shrink
						a(href="https://www.jumbosports.com/wintersport" title="Jumbo Wintersport" target="_blank" rel="nofollow")
							v-img(:src="require('~/static/images/logo_jumbosports.png')" contain max-width="200" loading="lazy" height="36px")
					v-col.shrink
						a(href="https://odido.nl" title="Odido" target="_blank" rel="nofollow")
							v-img(:src="require('~/static/images/logo_odido.png')" contain max-width="115" loading="lazy" height="30px")
				v-row.caption(justify="start" align="center")
					v-col.pr-0.shrink.text-no-wrap
						strong Partners
					v-col.shrink
						a(href="https://www.snowworld.com" title="SnowWorld" target="_blank" rel="nofollow")
							v-img(:src="require('~/static/images/logo_snowworld.png')" loading="lazy" height="36px")
					v-col.shrink
						a(href="https://www.daka.nl" title="Daka" target="_blank" rel="nofollow")
							v-img(:src="require('~/static/images/logo_daka.png')" loading="lazy" height="36px")
					v-col.shrink
						a(href="https://www.cruyff-foundation.org" title="Cruyff Foundation" target="_blank" rel="nofollow")
							v-img(:src="require('~/static/images/logo_cruyff.png')" loading="lazy" height="36px")
					v-col.shrink
						a(href="https://www.skiamade.com/nl?utm_source=wintersport.nl&utm_medium=logo&utm_campaign=Skiamade" title="Ski Amade" target="_blank" rel="nofollow")
							v-img(:src="require('~/static/images/logo_skiamade.png')" loading="lazy" height="36px")
					v-col.shrink
						a(href="https://teamnl.org" title="TeamNL" target="_blank" rel="nofollow")
							v-img(:src="require('~/static/images/logo_teamnl.png')" contain loading="lazy" height="36px")
					v-col.shrink
						a(href="https://www.villaforyou.com/nl/" title="villa for you" target="_blank" rel="nofollow")
							v-img(:src="require('~/static/images/logo_vfy.png')" contain loading="lazy" height="24px" width="109px")
</template>

<style lang="sass">
footer
	border-top: 1px solid var(--v-border-base) !important
	border-color: var(--v-border-base) !important
	background-color: var(--v-info-base) !important
</style>

<script>
import Locale from '../mixins/locale.js';

export default {
	name: 'Foot',
	mixins: [Locale],
	data() {
		return {};
	}
};
</script>
