/* eslint-disable camelcase */
export default ({ app }, inject) => {
	const url = 'https://www.snowcountry.nl';
	let source = 'wintersportnl';

	if (app.i18n.locale === 'nl-BE') {
		source = 'wintersportbe'
	}

	inject('snowcountryUrl', ({ utm_content, utm_term } = {}) => {

		// stringify and parse to remove empty values
		const cleanParameters = JSON.parse(JSON.stringify({
			utm_source: source,
			utm_medium: 'partners',
			utm_campaign: 'weer',
			utm_content,
			utm_term
		}))

		const query = new URLSearchParams(cleanParameters).toString()

		return `${url}?${query}`;
	});
};
