<template lang="pug">
v-data-table.mb-6.forum-topic-list__table(:items="forumTopics" :mobile-breakpoint="0" :items-per-page="50" hide-default-footer)
	template(#header)
		thead
			tr
				th.pl-0.text-left {{$t('page.forum:table.subject')}}
				th.text-left.hidden-sm-and-down {{$t('page.forum:table.topic_starter')}}
				th.text-right.hidden-sm-and-down {{$t('page.forum:table.last_activity')}}
				th.text-right {{$t('page.forum:table.replies')}}
	template(#item="{item: topic, headers}")
		tr(@click="goToTopic(topic, $event)" :key="topic.id" :class="{'forum-topic-list__row-sticky': topic.isSticky, 'forum-topic-list__row-new': topic.isNew, 'forum-topic-list__row-sold': topic.isItemSold, 'forum-topic-list__row-closed':topic.isClosed}")
			td.pl-0
				v-row.my-2.my-md-0(no-gutters)
					v-col.grow
						forum-icon.px-1(small) {{topic.isSticky ? 'sticky' : topic.category}}
						nuxt-link.topic-title.pr-1(:to="localePath(topic.to)") {{topic.title}}
						magic-nuxt-link.grey--text.hidden-sm-and-down.pr-1(v-if="topic.destination && !hideDestination" :to="topic.destination" :title="topic.destination.name")  ({{topic.destination.name}})
						span.grey--text.hidden-md-and-down.pr-1(v-if="topic.isItemSold") {{$t('page.forum:table.item.sold')}}
						span.grey--text.hidden-md-and-down.pr-1
							ws-icon(small v-if="topic.isClosed") lock
						.grey--text.caption.hidden-md-and-up {{$t('page.forum:table.last_reply.mobile_{date}', { date: $dayjs(topic.lastreply_at).format('DD-MM-YY · HH:mm')})}}
					v-col.shrink
						nuxt-link.d-block.mt-2.mt-md-0(v-if="topic.replyCount > 30" :to="localePath({ ...topic.to, query: {page: Math.ceil(topic.replyCount / 30) }, hash: '#last' })")
							ws-icon(small color="grey") jump
			td.hidden-sm-and-down
				span(v-if="!topic.user") {unknown user}
				magic-nuxt-link(v-else :to="topic.user") {{topic.user.username}}
			td.text-right.hidden-sm-and-down.text-no-wrap {{topic.lastreply_at|date}}
			td.text-right {{topic.replyCount || 0}}
		client-only
			tr(v-if="!hideLastVisitBar && topic.isLastNew && !isLastVisitBarClosed")
				td.pa-0(colspan="4" style="height:30px")
					v-sheet(color="info")
						v-row(no-gutters align="center")
							v-spacer
							v-col.caption.text-center.grey--text
								ws-icon(small color="grey") double-angle-up
								| nieuw sinds je laatste bezoek
							v-spacer
							v-col.shrink
								v-btn.mr-1(icon small  @click="closeNewPostsBar()")
									v-icon(small) close

</template>

<script>
import gql from 'graphql-tag';
import cookies from 'js-cookie';
import ForumIcon from '@dengl/common/components/forum/icon';

export default {
	name: 'ForumTopicList',
	components: {
		ForumIcon
	},
	props: {
		topics: {
			type: Array,
			default: () => {
				return [];
			}
		},
		hideLastVisitBar: {
			type: Boolean,
			default: false
		},
		hideDestination: {
			type: Boolean,
			default: false
		},
		topicUrlType: {
			type: String,
			default: 'forum'
		},
		destination: {
			type: Object,
			default: () => {
				return {};
			}
		},
		category: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			isLastVisitBarClosed: false
		};
	},
	computed: {
		forumTopics() {
			const allTopics = this.$lodash.cloneDeep(this.topics);
			return allTopics.reduce((carry, topic) => {
				if (topic.__typename === 'Weblog') {
					topic.category = topic.weblogCategory === 'news' ? 'news' : 'weblog';
				}
				if (topic.destinations && topic.destinations.length) {
					topic.destination = topic.destinations[0];
				}

				if (topic.__typename === 'Weblog') {
					topic.to = {
						name: topic.weblogCategory === 'news' ? 'news-slug' : 'weblog-slug',
						params: { 0: topic.slug }
					};
				} else if (this.destination && this.destination.__typename === 'Area') {
					topic.to = {
						name: 'areas-area-forum-topic',
						params: { slug: topic.slug, area: this.destination.slug }
					};
				} else if (
					this.destination &&
					this.destination.__typename === 'Resort'
				) {
					topic.to = {
						name: 'resorts-resort-forum-topic',
						params: {
							slug: topic.slug,
							area: this.destination.slug
						}
					};
				} else {
					topic.to = {
						name: 'forum-topic',
						params: { slug: topic.slug }
					};
				}

				if (!this.category) {
					topic.isSticky = topic.isStickyOnIndex;
				}

				const isNew = this.$dayjs(topic.lastreply_at).isAfter(
					this.$dayjs(this.$store.getters.forumLastVisit)
				);

				const previous = carry[carry.length - 1];

				if (previous && !isNew && previous.isNew && !previous.isSticky) {
					previous.isLastNew = true;
				}

				carry.push({ ...topic, isNew });
				return carry;
			}, []);
		}
	},
	created() {
		this.$emit('update:showSidebar', false);
	},
	methods: {
		goToTopic(topic, event) {
			if (event.target.nodeName === 'TD' || event.target.nodeName === 'DIV') {
				if (event.metaKey || event.ctrlKey) {
					const routeData = this.$router.resolve(this.localePath(topic.to));
					window.open(routeData.href, '_blank');
				} else {
					this.$router.push(this.localePath(topic.to));
				}
			}
		},
		closeNewPostsBar() {
			cookies.remove('lastForumVisit');
			this.isLastVisitBarClosed = true;
			this.$store.commit('SET_FORUM_LAST_VISIT', null);
		}
	},
	fragments: gql`
		fragment TopicListFragment on TopicConnection {
			pagination {
				page
				total
			}
			topics {
				id
				slug
				title
				created_at
				replyCount
				lastreply_at
				category
				isSticky
				isStickyOnIndex
				isItemSold
				isClosed
				edited {
					editedAt
				}
				# user {
				# 	id
				# 	username
				# 	slug
				# }
				user: profile {
					id
					username
					slug
				}
				destinations {
					id
					name
					slug
					__typename
				}
			}
		}
	`
};
</script>
