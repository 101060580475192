<template lang="pug">
div {{filterstring}}
</template>

<script>
import gql from 'graphql-tag';
import fields from './fields.js';

export default {
	name: 'ConfigAreaListEmbed',
	props: fields,
	async fetch() {},
	data() {
		return {};
	},
	fragments: gql`
		fragment ConfigAreaListEmbedDataFragment on Embed {
			data {
				... on EmbedDataConfigAreaList {
					filterstring
					infosheet
				}
			}
		}
	`
};
</script>
