/* eslint-disable no-console */
import Vue from 'vue';
import VueGtag from 'vue-gtag';

export default ({ isDev, app }) => {
	if (isDev) {
		console.log('Skipping GA in development');
	} else {
		const GTAG_ID = {
			'nl-NL': app.$env.GTAG_ID_NL,
			'nl-BE': app.$env.GTAG_ID_BE,
			'de-DE': app.$env.GTAG_ID_DE
		}[app.i18n.locale];

		Vue.use(VueGtag, {
			config: {
				id: GTAG_ID,
				params: { send_page_view: true }
			}
		});
	}
};
