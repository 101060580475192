<template lang="pug">
fragment
	component(v-for="banner in banners" :is="tag" v-bind="$attrs" :key="banner.id")
		component(v-bind="banner" :is="bannerComponent")
</template>

<script>
import gql from 'graphql-tag';
import ContentBanner from '@dengl/common/components/banners/content.vue';
// import SpotBanner from '@dengl/common/components/banners/spot.vue';
import ReplyBanner from '@dengl/common/components/banners/reply.vue';
import AccommodationBanner from '@dengl/common/components/banners/accommodation.vue';
import { Fragment } from 'vue-fragment';

const queries = {
	content: gql`
		query banner($filters: bannerFiltersInput!) {
			banner(filters: $filters) {
				id
				title
				subtitle
				text
				offer
				videoUrl
				imageUrl
				url
				linkLabel
				isHighlighted
				ad {
					id
					type
					campaign {
						id
						partner {
							id
						}
					}
				}
			}
		}
	`
	// spot: gql`
	// 	query getBanner($targets: BannerTargets, $count: Int) {
	// 		banners: spotsads(targets: $targets, count: $count) {
	// 			id
	// 			title
	// 			content
	// 			url
	// 			link
	// 			trackingCode
	// 			image
	// 		}
	// 	}
	// `
};

export default {
	name: 'Ad',
	components: {
		Fragment,
		ContentBanner,
		// SpotBanner,
		ReplyBanner,
		AccommodationBanner
	},
	props: {
		location: {
			type: String,
			default: null
		},
		tag: {
			type: String,
			default: 'div'
		},
		count: {
			type: [Number, String],
			default: null
		}
	},
	data() {
		return {
			banners: false,
			bannerComponent: 'content-banner'
		};
	},
	watch: {
		$route({ _params, _query }) {
			this.fetchBanners();
		}
	},
	watchQuery: true,
	mounted() {
		this.fetchBanners();
	},
	methods: {
		async fetchBanners() {
			this.banners = [];

			if (!Object.keys(this.$store.state.ads).includes(this.location)) {
				return;
			}

			const ad = this.$store.state.ads[this.location];

			if (ad.visible && ad.type !== 'spot') {
				// const bannerType = ad.type ? ad.type : 'content';
				// this.bannerComponent = `${bannerType}-banner`;
				if (ad.type === 'reply') {
					this.bannerComponent = 'reply-banner';
				} else if (ad.type === 'accommodation') {
					this.bannerComponent = 'accommodation-banner';
				} else {
					this.bannerComponent = 'content-banner';
				}

				const banners = await this.$graphClient
					.query({
						query: queries.content,
						variables: {
							filters: {
								targets: ad.targets
									? Object.keys(ad.targets).map(key => {
											return { type: key, id: ad.targets[key] };
									  })
									: [],
								type: 'BANNER',
								location: this.location.toUpperCase()
							},
							// count: parseInt(this.count),
							hasNoGoal: ad.isHouseAd ? true : null
							// location: this.location
						},
						fetchPolicy: 'no-cache'
					})
					.then(({ data }) => {
						return [data.banner];
					})
					.catch(_error => {
						return false;
					});
				this.banners =
					banners.length > 0 ? banners.filter(banner => !!banner) : [];
				// .map(banner => {
				// 	return {
				// 		...banner,
				// 		url: `/redir?u=${encodeURIComponent(banner.url)}&i=${
				// 			banner.id
				// 		}&t=banner&h=${this.$redirHash(banner.url)}`
				// 	};
				// });
			}
		}
	}
};
</script>
