module.exports = {
	filterstring: {
		type: String,
		default: ''
	},
	infosheet: {
		type: String,
		default: ''
	}
};
