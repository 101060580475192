<template lang="pug">
div
	forecast-list-table(:forecast="forecast" hide-lifts)
	//- v-data-table(:items="forecast" hide-default-footer :headers="headers" :mobile-breakpoint="0" must-sort :items-per-page="parseInt(limit)")
	//- 	template(#item="{item: snowheight}")
	//- 		tr
	//- 			td
	//- 				span.label.round(:class="snowheight.rankClass") {{snowheight.ranking}}
	//- 			td
	//- 				nuxt-link(:to="localePath({name: 'areas-area-snowheight', params: { area: snowheight.destination.slug }})" :title="`Sneeuwhoogte ${snowheight.destination.name}`")
	//- 					img.mr-2.flag(:src="`https://static.wintersport.nl/images/flags/svg/${snowheight.destination.country.countryCode}.svg`", :alt="snowheight.destination.country.name")
	//- 					| {{snowheight.destination.name}}
	//- 			td.text-right.text-md-left
	//- 				snowheight-bar(:max="maxSnowMountain" :snowheight="snowheight.snowTotal")
	//- 			td
	//- 				snowheight-bar(:max="maxSnowMountain" :snowheight="snowheight.snowValleyPeriod")
</template>

<script>
import gql from 'graphql-tag';
// import SnowheightBar from '@dengl/site/components/snowheight-bar.vue';
import ForecastListTable from '@dengl/site/components/weather/forecast-list-table.vue';

export default {
	name: 'ForecastListEmbed',
	components: { ForecastListTable },
	props: {
		snowheights: {
			type: String,
			required: true
		}
	},
	data() {
		return {};
	},
	computed: {
		forecast() {
			return JSON.parse(this.snowheights);
		}
	},
	fragments: gql`
		fragment ForecastListEmbedDataFragment on Embed {
			data {
				... on EmbedDataForecastList {
					limit
					rundate
					period
					region
					snowheights
				}
			}
		}
	`
};
</script>
