<template lang="pug">
	v-card(outlined color="info" v-if="destination")
		v-list.pa-0.checklist(two-line)
			template(v-for="({key, count, to, path, params}, index) in listitems")
				v-divider(v-if="index != 0")
				v-list-item
					v-list-item-avatar.align-self-start(color="white" size="30")
						span.grey--text.subtitle-1 {{index + 1}}
					v-list-item-content(v-if="key == 'accommodation'")
						v-list-item-title.subtitle-1.font-weight-bold {{$t('checklist.accommodation.title')}}
						v-list-item-subtitle Elk uur controleren wij alle prijzen van appartementen, hotels & chalets bij ruim 12 reisorganisaties.
						v-list-item-subtitle
							ws-icon(small left) bed
							nuxt-link(:to="to")
								strong {{destination.stats.accommodationCount}} accommodaties
								|
								| beschikbaar in {{destination.name}}
						.flex.linklist.ml-6
							link-row(v-if="destination.stats[accotype]" :key="accotype" v-for="accotype in ['apartments', 'hotels', 'pensions', 'chalets']")
								nuxt-link(:to="localePath({name: path, params: {...params, accommodationtype: $localeSlug(accotype, $i18n.locale)}})")
									| {{destination.stats[accotype]}} {{$t(`destination-navigation.accommodations.${accotype}`)}}

						//- v-list-item-subtitle {{$tc(`checklist.${key}.subtitle_{count}`, count, {area: destination.name})}}
					v-list-item-content(v-else)
						v-list-item-title.subtitle-1.font-weight-bold {{$t(`checklist.${key}.title`)}}
						v-list-item-subtitle {{$tc(`checklist.${key}.subtitle_{count}`, count, {area: destination.name})}}
					v-list-item-action
						v-btn(small depressed color="warning" :to="to") {{$t('checklist.cta_button')}}
</template>

<style lang="sass">
.checklist
  background-color: inherit !important

  .v-avatar
    border: 1px solid #9e9e9e !important

  .linklist
    line-height: 1.8
</style>

<script>
import gql from 'graphql-tag';

const LinkRow = {
	functional: true,
	render(createElement, context) {
		return createElement('v-row', { props: { noGutters: true } }, [
			createElement('v-col', { class: 'shrink' }, [
				createElement('ws-icon', { props: { small: true } }, ['angle-right'])
			]),
			createElement('v-col', { class: 'grow' }, context.children)
		]);
	}
};

const areaQuery = gql`
	query checklistForArea($id: ID!) {
		destination: area(id: $id) {
			id
			name
			slug
			stats {
				rentalCount
				lessonCount
				accommodationCount
				apartments: apartmentCount
				hotels: hotelCount
				pensions: pensionCount
				chalets: chaletCount
			}
		}
	}
`;

const resortQuery = gql`
	query checklistForResort($id: ID!) {
		destination: resort(id: $id) {
			id
			name
			slug
			stats {
				rentalCount
				lessonCount
				accommodationCount
				apartments: apartmentCount
				hotels: hotelCount
				pensions: pensionCount
				chalets: chaletCount
			}
		}
	}
`;

export default {
	components: {
		LinkRow
	},
	props: {
		areaId: { type: String, default: '' },
		resortId: { type: String, default: '' },
		hideAccommodations: {
			type: Boolean,
			default: false
		},
		hideRental: {
			type: Boolean,
			default: false
		},
		hideSkischools: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		destination: null
	}),
	async fetch() {
		const client = this.$graphClient;

		let query = areaQuery;
		let id = this.areaId;
		if (this.resortId) {
			query = resortQuery;
			id = this.resortId;
		}

		await client
			.query({
				query,
				variables: {
					id
				}
			})
			.then(({ data }) => {
				this.destination = data.destination;
			});
	},
	computed: {
		listitems() {
			if (!this.destination) {
				return [];
			}
			const params = this.resortId
				? { resort: this.destination.slug }
				: { area: this.destination.slug };

			const listItems = [];

			if (
				!this.hideAccommodations &&
				this.destination.stats.accommodationCount
			) {
				listItems.push({
					key: 'accommodation',
					count: this.destination.stats.accommodationCount,
					path: this.resortId
						? 'resorts-resort-accommodationtype'
						: 'areas-area-accommodationtype',
					params,
					to: this.localePath({
						name: this.resortId
							? 'resorts-resort-accommodationtype'
							: 'areas-area-accommodationtype',
						params: {
							...params,
							accommodationtype: this.$localeSlug(
								'accommodations',
								this.$i18n.locale
							)
						}
					})
				});
			}

			if (!this.hideRental && this.destination.stats.rentalCount) {
				listItems.push({
					key: 'rental',
					count: this.destination.stats.rentalCount,
					to: this.localePath({
						name: this.resortId ? 'resorts-resort-rental' : 'areas-area-rental',
						params
					})
				});
			}
			if (!this.hideLessons && this.destination.stats.lessonCount) {
				listItems.push({
					key: 'skischool',
					count: this.destination.stats.lessonCount,
					to: this.localePath({
						name: this.resortId ? 'resorts-resort-lesson' : 'areas-area-lesson',
						params
					})
				});
			}

			if (this.$i18n.locale === 'nl-NL') {
				listItems.push({
					key: 'insurance',
					count: 1,
					to: '/verzekering/reisverzekering'
				});
			}

			return listItems;
		}
	}
};
</script>
