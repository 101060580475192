import { Fragment } from 'vue-fragment';
import LoginDialog from '@/components/login-dialog';

export default {
	data() {
		return { showLoginDialog: false };
	},
	render(createElement, _context) {
		const events = ['click'];

		const on = events.reduce((carry, event) => {
			carry[event] = () => {
				const loggedin = !!this.$store.state.user.id;

				if (loggedin) {
					this.$emit('on-click');
				} else {
					this.showLoginDialog = true;
				}
			};
			return carry;
		}, {});

		return createElement(Fragment, [
			this.$scopedSlots.activator({
				on
			}),
			createElement(LoginDialog, {
				props: {
					value: this.showLoginDialog
				},
				on: {
					input: v => {
						this.showLoginDialog = v;
					},
					loggedin: () => {
						this.showLoginDialog = false;
						this.$emit('on-click');
					}
				}
			})
		]);
	}
};
