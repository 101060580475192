import Vue from 'vue';

import Icon from '@dengl/common/components/ws-icon.vue';
import Dialog from '@dengl/common/components/dialog.vue';
import Content from '@dengl/common/components/content.vue';
import OptionalNuxtLink from '@dengl/common/components/optional-nuxt-link.js';
import SnowAlarm from '@dengl/common/components/snow-alarm.vue';
import VideoHls from '@dengl/common/components/video-hls.vue';
import Ad from '@/components/ad.vue';
import Checklist from '@/components/checklist.vue';
import Contentpage from '@/components/pages/content-page.vue';
import Factline from '@/components/common/factline.vue';
import GlueList from '@/components/glue-list.js';
import LoginAction from '@/components/login-action.js';
import MagicNuxtLink from '@/components/magic-nuxt-link.js';
import Offers from '@/components/offers.vue';

Vue.component('Ad', Ad);
Vue.component('ContentPage', Contentpage);
Vue.component('Factline', Factline);
Vue.component('GlueList', GlueList);
Vue.component('LoginAction', LoginAction);
Vue.component('MagicNuxtLink', MagicNuxtLink);
Vue.component('Offers', Offers);
Vue.component('OptionalNuxtLink', OptionalNuxtLink);
Vue.component('SnowAlarm', SnowAlarm);
Vue.component('WsChecklist', Checklist);
Vue.component('WsContent', Content);
Vue.component('WsDialog', Dialog);
Vue.component('WsIcon', Icon);
Vue.component('VideoHls', VideoHls);
