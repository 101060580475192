<template lang="pug">
.wintersport-banner
  client-only(v-if="sliced_offers.length")
    .ws-tip-top.mb-0
      .subtitle-1.mb-0
        span.ws-tip.px-2.py-2.mr-2 TIP
        nuxt-link.never-visited.ws-tip-title(:to="localePath({name:'offers'})") Aanbiedingen top 20
    v-card( v-for="(offer, index) in sliced_offers" tag="v-card" flat :key="offer.id")
      a.d-block(:href="`/redir?u=${encodeURIComponent(offer.url)}&p=${offer.partner.slug}&i=${offer.id}&t=offer&h=${$redirHash(offer.url)}`" target="_blank" rel="nofollow")
        v-row(dense)
          v-col.shrink
            v-img(:src="`${offer.image}?width=64`" width="64" :aspect-ratio="16/9")
          v-col.grow
            .font-weight-bold {{offer.name}}
            .caption.grey--text {{offer.partner.name}}
          v-col.text-right.shrink.text-no-wrap.ml-auto
            .caption.grey--text {{offer.prefix}}
            .subtitle-1.error--text {{offer.offer}}
    .text-right.my-2
      nuxt-link.never-visited(:to="localePath({name:'offers'})") » meer aanbiedingen
</template>

<style lang="sass" scoped>
.ws-tip-top
	background-color: var(--v-info-base)
	.ws-tip
		display: inline-block
		font-weight: bold
		color: white
		background: var(--v-primary-base)

.wintersport-banner
	line-height: 16px
</style>

<script>
import gql from 'graphql-tag';

const offerQuery = gql`
	query getOffers {
		offers {
			id
			name
			url
			offer
			prefix
			image
			partner {
				id
				name
				slug
				image
			}
		}
	}
`;

export default {
	name: 'Offers',
	props: {},
	data() {
		return {
			offers: []
		};
	},
	computed: {
		sliced_offers() {
			return [...this.offers].slice(0, 4);
		}
	},
	mounted() {
		return this.$graphClient
			.query({
				query: offerQuery,
				variables: {},
				fetchPolicy: 'no-cache'
			})
			.then(({ data }) => {
				this.offers = data.offers;
			});
	}
};
</script>
