<template lang="pug">
dnt(thirdParty="twitter" thirdPartyName="twitter.com")
	v-container
		v-row(justify="center")
			v-col(cols="12" lg="8")
				tweet.tweet(:id="tweetId")
</template>

<script>
import gql from 'graphql-tag';
import { Tweet } from 'vue-tweet-embed';
import dnt from '@dengl/common/components/dnt';
import fields from './fields';

export default {
	name: 'TwitterEmbed',
	components: { tweet: Tweet, dnt },
	props: fields,
	fragments: gql`
		fragment TwitterEmbedDataFragment on Embed {
			data {
				... on EmbedDataTwitter {
					tweetId
				}
			}
		}
	`
};
</script>

<style lang="sass">
.tweet twitter-widget
	margin: 0 auto
</style>
