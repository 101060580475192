<template lang="pug">
banner(v-if="banner" v-bind="banner")
</template>

<script>
import gql from 'graphql-tag';
import Banner from '@dengl/common/components/banners/richmedia.vue';
import VideoHls from '@dengl/common/components/video-hls.vue';

const contentQuery = gql`
	query getMediaAd($filters: bannerFiltersInput!) {
		banner(filters: $filters) {
			id
			title
			subtitle
			text
			offer
			videoUrl
			imageUrl
			url
			linkLabel
			isHighlighted
		}
	}
`;

export default {
	name: 'EmbedRichmedia',
	components: {
		Banner,
		VideoHls
	},
	props: {
		tags: {
			type: Array,
			default() {
				return [''];
			}
		},
		location: {
			type: String,
			default() {
				return 'EMBED';
			}
		}
	},
	data() {
		return {
			banner: false
		};
	},
	watch: {
		$route({ _params, _query }) {
			this.fetchBanner();
		}
	},
	watchQuery: true,
	mounted() {
		this.fetchBanner();
	},
	methods: {
		fetchBanner() {
			this.banner = false;

			this.$graphClient
				.query({
					query: contentQuery,
					variables: {
						filters: {
							targets: [],
							type: 'RICHMEDIA',
							location: this.location?.toUpperCase() || 'EMBED'
						}
					},
					fetchPolicy: 'no-cache'
				})
				.then(({ data }) => {
					this.banner = data.banner;
				})
				.catch(_error => {
					return false;
				});
		}
	},
	fragments: gql`
		fragment RichmediaEmbedDataFragment on Embed {
			data {
				... on EmbedDataRichmedia {
					tags
					location
				}
			}
		}
	`
};
</script>
