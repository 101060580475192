<template lang="pug">
video-hls(:videoUrl="videoUrl")
</template>

<script>
import gql from 'graphql-tag';
import VideoHls from '@dengl/common/components/video-hls.vue';
import fields from './fields';

export default {
	name: 'VideoEmbed',
	components: { VideoHls },
	props: fields,
	fragments: gql`
		fragment VideoEmbedDataFragment on Embed {
			data {
				... on EmbedDataVideo {
					videoUrl
				}
			}
		}
	`
};
</script>
