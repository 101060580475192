<template lang="pug">
	v-autocomplete.searchbar(
				v-model="model"
				:items="entries"
				:label="null"
				:loading="isLoading"
				:placeholder="placeholder"
				:search-input.sync="search"
				:list-index.sync="selectedIndex"
				@keyup.enter.native="onEnter()"
				:append-icon="null"
				dense
				flat
				hide-details
				item-text="name"
				item-value="id"
				:no-data-text="$t('search.no.data.text')"
				no-filter
				ref="searchbar"
				return-object
				single-line
				outlined
				:background-color="background"
				)
		template(#append)
			v-btn.ma-0(@click="onEnter" color="accent"  depressed icon small)
				v-icon {{fieldicon}}
		template(#item="data")
			v-list-item-avatar(size="20")
				ws-icon(small) {{icons[data.item.__typename]}}
			v-list-item-content {{data.item.name}}

</template>

<style lang="sass">
.searchbar
	// padding-right: 0 !important
	.v-autocomplete__content .v-list__item__avatar
		min-width: 35px
	// .v-input__append-inner
		margin-top: 0 !important
</style>

<script>
import gql from 'graphql-tag';

export default {
	props: {
		placeholder: {
			type: String,
			default() {
				return this.$t('search.destination.default.text');
			}
		},
		fieldicon: {
			type: String,
			default: ''
		},
		background: {
			type: String,
			default: ''
		},
		types: {
			type: Array,
			default() {
				return ['areas', 'resorts'];
			}
		},
		limit: {
			type: Number,
			default: 50
		}
	},

	data: () => ({
		entries: [],
		isLoading: false,
		model: null,
		search: null,
		selectedIndex: -1,
		icons: {
			Country: 'ski',
			Region: 'ski',
			Area: 'ski',
			Resort: 'village',
			Cluster: 'ski',
			Organisation: 'organisation',
			Skihall: 'skihall',
			Revolvingslope: 'revolvingslope',
			Dryslope: 'dryslope',
			Carpetslope: 'carpetslope'
		}
	}),
	watch: {
		model(model) {
			this.$emit('select', model);
		},
		search(val) {
			if (val == null || val === '') return false;
			this.isLoading = true;

			const searchQuery = gql`
				query search($val: String!, $types: [String]!, $limit: Int!) {
					search(term: $val, types: $types, limit: $limit) {
						__typename
						... on Area {
							id
							name
							slug
							geography {
								location {
									coordinates
								}
							}
						}
						... on Resort {
							id
							name
							slug
							geography {
								location {
									coordinates
								}
							}
						}
					}
				}
			`;

			this.$graphClient
				.query({
					query: searchQuery,
					variables: {
						val,
						types: this.types,
						limit: this.limit
					}
				})
				.then(res => res.data)
				.then(data => {
					this.count = data.search.length;
					this.entries = data.search;
				})
				.catch(_err => {})
				.finally(() => (this.isLoading = false));
		}
	},
	methods: {
		onEnter() {
			if (this.selectedIndex === -1) {
				this.$refs.searchbar.blur();
				this.$emit('enter', this.search);
			}
			this.selectedIndex = -1;
		}
	}
};
</script>
