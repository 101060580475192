<template lang="pug">
div(v-if="editorialNote === 'SNOWCOUNTRY'")
	v-row(no-gutters justify="center" align="center")
		v-col(cols="2")
			v-divider
		v-col.px-3.shrink
			nuxt-link.text-center.caption.grey--text.mb-0.text-decoration-none(to="/noot-van-de-redactie") gesponsord
		v-col(cols="2")
			v-divider
	v-card.mb-6(:href="$snowcountryUrl({utm_content: 'article'})" target="_blank" flat)
		.flex.px-3.py-1.white--text( style="background-color: #0755ab")
			div
				| Weer of geen weer, je vindt altijd de beste ski en snowboard gear bij
				|
				|
				span.font-weight-bold snowcountry.nl
			img(src="/images/logo_snowcountry.png" height="26px")
		div.text-body-2.pa-3.py-2(style="background-color: #ebebeb") Snowcountry is dé Nederlandse webshop voor enthousiaste wintersporters, op de piste én in de backcountry!
v-card.mb-3.cl-gray(outlined v-else-if="editorialNote != 'EDITORIALPLUS'")
	v-card-text.caption
		strong {{$t('page.weblog.message:editorial.note.title')}}
		p.mb-0
			template(v-if="editorialNote == 'EDITORIAL'") {{$t('page.weblog.message:editorial.note.editorial')}}
			template(v-if="editorialNote == 'AFFILIATE'") {{$t('page.weblog.message:editorial.note.affiliate')}}
			template(v-if="editorialNote == 'PROMOTIONAL'") {{$t('page.weblog.message:editorial.note.promotional')}}
			template(v-if="editorialNote == 'ADVERTORIAL'") {{$t('page.weblog.message:editorial.note.advertorial')}}
			nuxt-link(to="/noot-van-de-redactie" :title="$t('page.weblog.message:editorial.note.link.title')").text-no-wrap  &raquo; {{$t('page.weblog.message:editorial.note.link.text')}}
</template>

<script>
import gql from 'graphql-tag';

export default {
	name: 'EditorialNoteEmbed',
	props: {
		editorialNote: {
			type: String,
			required: true
		}
	},
	data() {
		return {};
	},
	fragments: gql`
		fragment EditorialNoteEmbedDataFragment on Embed {
			data {
				... on EmbedDataEditorialNote {
					editorialNote
				}
			}
		}
	`
};
</script>

<style lang="sass" scoped>
.flex
	display: flex
	align-items: center
	gap: 2rem
	justify-content: space-between
</style>
