import nl from 'vuetify/es5/locale/nl';
import de from 'vuetify/es5/locale/de';

export default {
	lang: {
		locales: { nl, de },
		current: 'nl'
	},
	icons: {
		iconfont: 'md'
	},
	theme: {
		options: {
			variations: false,
			customProperties: true
		},
		themes: {
			light: {
				primary: '#4aa3df',
				accent: '#264f80',
				info: '#f2fbff',
				warning: '#f39c12',
				error: '#e74c3c',
				success: '#2ecc71',
				border: '#dddddd'
			}
		}
	}
};
