<template lang="pug">
	v-card(flat)
		barchart(:data="bars" infosheet)
		v-card-text.caption.py-1 {{description}}
</template>

<script>
import gql from 'graphql-tag';
import Barchart from '@dengl/site/components/display/barchart.vue';

export default {
	name: 'BarchartEmbed',
	components: {
		Barchart
	},
	props: {
		description: {
			type: String,
			default: ''
		},
		values: {
			type: String,
			required: true
		}
	},
	async fetch() {},
	data() {
		return {};
	},
	computed: {
		bars() {
			return this.values
				.split(',')
				.map(bar => bar.trim())
				.filter(bar => bar.length)
				.map(bar => {
					const [label, value] = bar.split(':');
					return {
						value,
						label
					};
				});
		}
	},
	fragments: gql`
		fragment BarchartEmbedDataFragment on Embed {
			data {
				... on EmbedDataBarchart {
					description
					values
				}
			}
		}
	`
};
</script>
