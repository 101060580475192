<template lang="pug">
dnt(thirdParty="youtube" thirdPartyName="youtube.com")
	vue-plyr(ref="plyr" :options="{'controls':['play', 'progress', 'volume', 'play-large', 'airplay', 'fullscreen', 'pip', 'mute'], 'disableContextMenu': false}")
		div(data-plyr-provider="youtube" :data-plyr-embed-id="youtubeId")
</template>

<script>
import gql from 'graphql-tag';
import dnt from '@dengl/common/components/dnt';
import fields from './fields';

export default {
	name: 'YoutubeEmbed',
	components: { dnt },
	props: fields,
	fragments: gql`
		fragment YoutubeEmbedDataFragment on Embed {
			data {
				... on EmbedDataYoutube {
					youtubeId
				}
			}
		}
	`
};
</script>
