<template lang="pug">
v-card.accommodationsearchEmbed(color="info" outlined).mb-3.px-1.pt-1
	minimum-filter(@update="searchAccommodations" horizontal)
</template>

<script>
import gql from 'graphql-tag';
import {
	encodeFilters,
	decodeFilters
} from '@dengl/common/utils/filterstrings';
import MinimumFilter from '@dengl/site/components/accommodations/minimum-filter.vue';
import fields from './fields.js';

export default {
	name: 'AccommodationSearchEmbed',
	components: { MinimumFilter },
	props: fields,
	data() {
		return {};
	},
	computed: {
		embedFilters() {
			return decodeFilters(this.filterstring, this.$i18n.locale);
		}
	},
	methods: {
		searchAccommodations(filters) {
			const path = 'accommodations-search';
			this.$router.push(
				this.localePath({
					name: path,
					hash: `#${encodeFilters(
						{ ...this.embedFilters, ...filters },
						this.$i18n.locale
					)}`
				})
			);
		}
	},
	fragments: gql`
		fragment AccommodationSearchEmbedDataFragment on Embed {
			data {
				... on EmbedDataAccommodationSearch {
					filterstring
				}
			}
		}
	`
};
</script>

<style lang="sass">
.accommodationsearchEmbed
	.v-input, .v-label
		font-size: 13px
		.v-input__slot
			min-height: 30px !important
</style>
