export const vueI18n = {"numberFormats":{"nl":{"currency":{"style":"currency","currency":"EUR"},"simplecurrency":{"style":"currency","currency":"EUR","useGrouping":false,"minimumFractionDigits":0,"maximumFractionDigits":0}},"de":{"currency":{"style":"currency","currency":"EUR"},"simplecurrency":{"style":"currency","currency":"EUR","useGrouping":false,"minimumFractionDigits":0,"maximumFractionDigits":0}}}}
export const vueI18nLoader = false
export const locales = [{"name":"Nederlands","code":"nl-NL","iso":"nl-NL","file":"nl-NL.js"},{"name":"Deutsch","code":"de-DE","iso":"de-DE","file":"de-DE.js"},{"name":"Vlaams","code":"nl-BE","iso":"nl-BE","file":"nl-BE.js"}]
export const defaultLocale = 'nl-NL'
export const defaultDirection = 'ltr'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix_except_default'
export const lazy = true
export const langDir = 'locale/'
export const rootRedirect = null
export const detectBrowserLanguage = false
export const differentDomains = true
export const seo = false
export const baseUrl = ''
export const vuex = {"moduleName":"i18n","syncLocale":true,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const skipSettingLocaleOnNavigate = false
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const includeNodeModules = true
export const encodePaths = false
export const IS_UNIVERSAL_MODE = true
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DIR_KEY = 'dir'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["nl-NL","de-DE","nl-BE"]
export const trailingSlash = false
