import axios from 'axios';

export default function ({ _store, redirect }) {
	return axios
		// TODO: url from env!
		.get(`https://localhost:3000/auth`, {
			withCredentials: true
		})
		.then(result => {
			if (!result.data.success) {
				return redirect('/verzekering/login');
			}
		})
		.catch(_error => {
			return redirect('/verzekering/login');
		});
}
