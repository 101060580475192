'use strict';

const https = require('https');
const { ApolloLink } = require('apollo-link');
const { createHttpLink } = require('apollo-link-http');
const { InMemoryCache } = require('apollo-cache-inmemory');
const { onError } = require('apollo-link-error');
const { middleWareLink } = require('./middleware');
const { fragmentMatcher } = require('./fragmentMatcher');

export default ({ app, req, headers }) => {
	const customHeaders = (req, headers) => {
		return req
			? {
					'x-original-url': req.originalUrl,
					'x-host-domain': req.headers.host,
					'x-host-user-agent': req.headers['user-agent'],
					'x-host-referer': req.headers.referer,
					'x-req-language': 'nl-BE',
					'x-language': 'be'
			  }
			: { ...headers, 'x-req-language': 'nl-BE', 'x-language': 'be' };
	};

	const uri = process.client ? '/graphql' : `${app.$env.API_DOMAIN}/graphql`;

	const link = createHttpLink({
		uri,
		credentials: 'same-origin',
		headers: customHeaders(req, headers),
		fetchOptions: uri.startsWith('https://')
			? {
					agent: new https.Agent({
						rejectUnauthorized: process.env.NODE_ENV === 'production'
					})
			  }
			: {}
	});

	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors)
			graphQLErrors.map(({ message, locations, path }) =>
				console.error(
					`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
						locations
					)}, Path: ${path}, headers: ${JSON.stringify(
						customHeaders(req, headers)
					)} `
				)
			);

		if (networkError)
			console.error(
				`[Network error]: (${networkError.statusCode}) ${networkError}`
			);
	});

	const isServer = !process.client;

	return {
		link: ApolloLink.from([errorLink, middleWareLink, link]),
		defaultHttpLink: false,
		cache: new InMemoryCache({
			fragmentMatcher,
			resultCaching: !isServer
		}),
		apollo: {
			defaultOptions: {
				mutate: {
					errorPolicy: 'all'
				}
			}
		},
		// assumeImmutableResults: isServer,
		...(isServer
			? {
					// Set this on the server to optimize queries when SSR
					ssrMode: true
			  }
			: {
					// This will temporary disable query force-fetching
					ssrForceFetchDelay: 100
			  })
	};
};
