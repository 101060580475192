<template lang="pug">
	.dnt
		client-only
			slot(v-if="showEmbed")
			v-card.text-center(color="info" v-else)
				v-card-title.text-center.d-block {{$t('component.dnt.title', {thirdParty: thirdPartyName || 'externen'})}}
				v-card-text {{$t('component.dnt.warning', {thirdParty: thirdPartyName || thirdParty})}}
				v-card-text.pb-0
					v-btn(color="accent" @click="consent" depressed) {{$t('component.dnt.consent.button')}}
				v-card-actions
					v-spacer
					v-checkbox.caption(v-model="rememberDntConsent" :label="$t('component.dnt.consent.remember')")
					v-spacer

</template>

<script>
export default {
	name: 'Dnt',
	props: {
		thirdParty: {
			type: String,
			required: true
		},
		thirdPartyName: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			showContent: false,
			rememberDntConsent: false
		};
	},
	computed: {
		showEmbed() {
			return !!(
				this.$store.state.dntConsent[this.thirdParty] || this.showContent
			);
		}
	},
	methods: {
		consent() {
			this.showContent = true;
			if (this.rememberDntConsent) {
				this.$store.commit('SET_DNT_CONSENT', { [this.thirdParty]: true });
			}
		}
	}
};
</script>
