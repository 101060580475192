<template lang="pug">
  .error-404
    v-card.mx-auto.mt-6(max-width='800' flat v-if="error.debug")
      v-card-text
        h2 Debug info
        p.mt-6 {{error.debug.message}}
        h2.mt-6 Stacktrace
        p.mt-6 {{error.debug.stack}}
    v-card.mx-auto.mt-4(max-width='800' flat v-else)
      v-card-text
        h2.mb-4 {{$t('page.404:title')}}
        v-img.mb-4(src='https://static.wintersport.nl/error.gif' alt='Error' :aspect-ratio="4/3")
        div(v-if="error.customCode" v-html="$t('page.404:explainer.code', {code: error.customCode})")
        div(v-else v-html="$t('page.404:explainer', {code: error.customCode})")
</template>
<script>
export default {
	name: 'Error404',
	props: {
		error: {
			type: Object,
			default: () => {}
		}
	}
};
</script>
