<template lang="pug">

v-dialog(v-model="showFilterDate" :return-value.sync='selectedDate' max-width="880")
  template(v-slot:activator='{ on }')
    slot(name="activator" :on="on")
  v-card
    v-card-title.headline(primary-title)
      | {{ $t('global.datepicker.title') }}
      v-spacer
      v-btn(flat icon @click="showFilterDate = false")
        v-icon close
    v-card-text.datepicker
      .month(v-for='month in months' :key='month.name')
        .month-name {{month.name}}
        table.table.mt-2
          colgroup
            col(span='5')
            col.highlight
          thead
            tr
              th(v-for="day in weekdays") {{day}}
          tbody
            tr(v-for='week in month.weeks')
              td(v-for='day in week' :class="[day.inactive ? 'inactive': '', day.selected ? 'selected': '']")
                a(v-if="day" :href="[day.inactive ? '': '#']" @click.prevent='setDate(day.fulldate)')
                  | {{day.label}}
</template>

<style lang="scss">
div.datepicker {
  padding: 0 16px;
  z-index: 3000;
  width: 872px;
}
// @include media-breakpoint-down(lg){
//   width: 592px;
// }

// @include media-breakpoint-down(sm){
//   width: 100%;
//   width:   auto;
//   overflow-y: scroll;
//   left: auto !important;
//   right: auto !important;
// }

.month {
  display: inline-block;
  width: 280px;
  // padding: 0 $spacer;
  // margin-bottom: $spacer*2;
  background: #fff;

  // @include media-breakpoint-down(sm){ width: 100%; }

  &:nth-of-type(even) {
    background-color: #fafafa;
  }

  .month-name {
    text-align: center;
    font-weight: bold;
  }

  .table {
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    // margin-bottom: $spacer;

    td {
      padding: 0;
      line-height: 16px;
      vertical-align: top;
      border-top: 1px solid var(--v-border-base);
    }

    thead th {
      vertical-align: bottom;
      border-bottom: 1px solid var(--v-border-base);
      font-weight: normal;
    }

    font-size: 80%;
    table-layout: fixed;
    th {
      // padding: $spacer/4 $spacer/2.5;
      text-align: center;
    }
    td {
      text-align: center;
      padding: 0;
      a {
        display: block;
        padding: 6px;
        line-height: normal;
        &:visited {
          // color: $ws-colors['peterriver']
          background: white;
        }
      }

      &.selected {
        // background-color: $ws-colors['ws-blue']
        color: white;
        a,
        span.low-price {
          color: white;
        }
      }
      &:not(.inactive) {
        a:hover,
        a.active {
          // color: $ws-colors['clouds']
          text-decoration: none;
          // background: $ws-colors['ws-blue']
          span.low-price,
          span.low-price.discount {
            color: white !important;
          }
        }
      }
      &.inactive {
        opacity: 0.4;
        cursor: default;
        & > span {
          display: block;
          // padding: $spacer/2;
        }
      }
    }
    .highlight {
      background-color: #e0f4fe;
    }
  }
}
</style>


<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    nullable: {
      type: Boolean,
      required: false,
      default: false
    },
    startdate: {
      type: String,
      required: true
    },
    enddate: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      showFilterDate: false,
      date: null,
      selectedDate: null
    };
  },
  computed: {
    dateFormatted: function() {
      return this.$dayjs(this.date).isValid()
        ? this.$dayjs(this.date).format('DD-MM-YYYY')
        : '';
    },
    weekdays: function() {
      return Array(7)
        .fill()
        .map((i, index) => {
          return this.$dayjs()
            .set('d', index + 1)
            .format('dd');
        });
    },
    months: function() {
      return this.monthsBetweenDates(this.startdate, this.enddate);
    }
  },
  created: function() {
    if (this.value) {
      this.date = this.value;
    }
  },
  methods: {
    setDate: function(date) {
      this.date = date;
      this.showFilterDate = false;
      this.$emit('input', date);
    },
    monthsBetweenDates: function(startdate, _enddate) {
      const months = [];
      let current = this.$dayjs(startdate);

      while (months.length < 5) {
        const days = Array(current.daysInMonth())
          .fill()
          .map((i, index) => {
            const day = current.clone().set('date', index + 1);
            return {
              label: index + 1,
              fulldate: day.format('YYYY-MM-DD'),
              inactive: day.isBefore(),
              selected: day.format('YYYY-MM-DD') === this.date
            };
          });

        const shift = parseInt(current.format('d'))
          ? parseInt(current.format('d')) - 1
          : 6;

        days.unshift(...Array(shift).fill(false));

        const weeks = [];

        for (let x = 0; x < Math.ceil(days.length / 7); x++) {
          const start = x * 7;
          const end = start + 7;

          weeks.push(days.slice(start, end));
        }

        const lastweek = weeks[weeks.length - 1];
        lastweek.push(...Array(7 - lastweek.length).fill(false));

        months.push({
          name: current.format('MMMM YYYY'),
          weeks: weeks
        });

        current = current.add(1, 'month');

        while ([4, 5, 6, 7, 8, 9, 10].includes(current.month())) {
          current = current.add(1, 'month');
        }
      }

      return months;
    }
  }
};
</script>
