import { extend } from 'vee-validate';

extend('wscontent', {
  validate: value => {
    if (value.markdown && value.markdown.length) {
      return true;
    }
    return false;
  },
  message: 'Markdown is empty'
});
