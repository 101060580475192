export default {
	functional: true,
	render: function (createElement, context) {
		const lastglue = context.props.lastglue || ' & ';
		const glue = context.props.glue || ', ';
		const tag = context.props.tag || 'span';

		const children = context.children
			.filter(child => !!child.tag)
			.reduceRight((carry, child, index, orginalArray) => {
				carry.unshift(child);
				if (index) {
					carry.unshift(index === orginalArray.length - 1 ? lastglue : glue);
				}
				return carry;
			}, []);

		return createElement(
			tag,
			{ staticClass: context.data.staticClass },
			children
		);
	}
};
