<template lang="pug">
vue-plyr(ref="plyr" :options="{'controls':['play', 'progress', 'volume', 'play-large', 'airplay', 'fullscreen', 'pip', 'mute'], 'disableContextMenu': false}")
	div(data-plyr-provider="vimeo" :data-plyr-embed-id="vimeoId")
</template>

<script>
import gql from 'graphql-tag';
import dnt from '@dengl/common/components/dnt';
import fields from './fields';

export default {
	name: 'VimeoEmbed',
	components: { dnt },
	props: fields,
	fragments: gql`
		fragment VimeoEmbedDataFragment on Embed {
			data {
				... on EmbedDataVimeo {
					vimeoId
				}
			}
		}
	`
};
</script>
