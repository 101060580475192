// DO NOT EDIT THIS FILE DIRECTLY USE npm run generate:embeds

import gql from 'graphql-tag';

import AccommodationBlock from './accommodation-block/embed.vue';
import AccommodationSearch from './accommodation-search/embed.vue';
import Barchart from './barchart/embed.vue';
import ConfigAreaList from './config-area-list/embed.vue';
import ContentpageList from './contentpage-list/embed.vue';
import EditorialNote from './editorial-note/embed.vue';
import Facebook from './facebook/embed.vue';
import Faq from './faq/embed.vue';
import ForecastList from './forecast-list/embed.vue';
import ForecastMap from './forecast-map/embed.vue';
import ImageCompare from './image-compare/embed.vue';
import ImageGallery from './image-gallery/embed.vue';
import Instagram from './instagram/embed.vue';
import LinkList from './link-list/embed.vue';
import Map from './map/embed.vue';
import PartnerList from './partner-list/embed.vue';
import Richmedia from './richmedia/embed.vue';
import Snowalarm from './snowalarm/embed.vue';
import Sportmatch from './sportmatch/embed.vue';
import TopicList from './topic-list/embed.vue';
import Twitter from './twitter/embed.vue';
import Video from './video/embed.vue';
import Vimeo from './vimeo/embed.vue';
import WeatherVideo from './weather-video/embed.vue';
import Weatheralert from './weatheralert/embed.vue';
import WeblogList from './weblog-list/embed.vue';
import Youtube from './youtube/embed.vue';

const embeds = {
	'accommodation-block': AccommodationBlock,
	'accommodation-search': AccommodationSearch,
	'barchart': Barchart,
	'config-area-list': ConfigAreaList,
	'contentpage-list': ContentpageList,
	'editorial-note': EditorialNote,
	'facebook': Facebook,
	'faq': Faq,
	'forecast-list': ForecastList,
	'forecast-map': ForecastMap,
	'image-compare': ImageCompare,
	'image-gallery': ImageGallery,
	'instagram': Instagram,
	'link-list': LinkList,
	'map': Map,
	'partner-list': PartnerList,
	'richmedia': Richmedia,
	'snowalarm': Snowalarm,
	'sportmatch': Sportmatch,
	'topic-list': TopicList,
	'twitter': Twitter,
	'video': Video,
	'vimeo': Vimeo,
	'weather-video': WeatherVideo,
	'weatheralert': Weatheralert,
	'weblog-list': WeblogList,
	'youtube': Youtube
};

const fragments = gql`
	fragment EmbedDataFragment on Embed {
		type
		slug
		...AccommodationBlockEmbedDataFragment
		...AccommodationSearchEmbedDataFragment
		...BarchartEmbedDataFragment
		...ConfigAreaListEmbedDataFragment
		...ContentpageListEmbedDataFragment
		...EditorialNoteEmbedDataFragment
		...FacebookEmbedDataFragment
		...FaqEmbedDataFragment
		...ForecastListEmbedDataFragment
		...ForecastMapEmbedDataFragment
		...ImageCompareEmbedDataFragment
		...ImageGalleryEmbedDataFragment
		...InstagramEmbedDataFragment
		...LinkListEmbedDataFragment
		...MapEmbedDataFragment
		...PartnerListEmbedDataFragment
		...RichmediaEmbedDataFragment
		...SnowalarmEmbedDataFragment
		...SportmatchEmbedDataFragment
		...TopicListEmbedDataFragment
		...TwitterEmbedDataFragment
		...VideoEmbedDataFragment
		...VimeoEmbedDataFragment
		...WeatherVideoEmbedDataFragment
		...WeatheralertEmbedDataFragment
		...WeblogListEmbedDataFragment
		...YoutubeEmbedDataFragment
	}
	${AccommodationBlock.fragments}
	${AccommodationSearch.fragments}
	${Barchart.fragments}
	${ConfigAreaList.fragments}
	${ContentpageList.fragments}
	${EditorialNote.fragments}
	${Facebook.fragments}
	${Faq.fragments}
	${ForecastList.fragments}
	${ForecastMap.fragments}
	${ImageCompare.fragments}
	${ImageGallery.fragments}
	${Instagram.fragments}
	${LinkList.fragments}
	${Map.fragments}
	${PartnerList.fragments}
	${Richmedia.fragments}
	${Snowalarm.fragments}
	${Sportmatch.fragments}
	${TopicList.fragments}
	${Twitter.fragments}
	${Video.fragments}
	${Vimeo.fragments}
	${WeatherVideo.fragments}
	${Weatheralert.fragments}
	${WeblogList.fragments}
	${Youtube.fragments}
`;

export { embeds, fragments };
