const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['authinsurance'] = require('../middleware/authinsurance.js')
middleware['authinsurance'] = middleware['authinsurance'].default || middleware['authinsurance']

middleware['reset-navigation'] = require('../middleware/reset-navigation.js')
middleware['reset-navigation'] = middleware['reset-navigation'].default || middleware['reset-navigation']

export default middleware
