<template lang="pug">
div(v-if="display === 'cards'")
	v-row
		v-col(cols="12" md="6" lg="4" v-for="contentpage in contentpages" :key="contentpage.id")
			optional-nuxt-link(:to="localePath({ name: 'contentpage', params: { 0: contentpage.path.slice(1) } })")
				v-img(:src="contentpage.meta.image || 'https://static.wintersport.nl/images/ogimages/ws-wintersporters.jpg'" :aspect-ratio="16/9")
				h3.mt-3 {{contentpage.name}}
				p.black--text {{contentpage.meta.description}}
div(v-else-if="display == 'list' || !display")
	div(v-for="contentpage in contentpages" :key="contentpage.id")
		optional-nuxt-link.d-block.font-weight-bold.subtitle-1(:to="localePath({ name: 'contentpage', params: { 0: contentpage.path.slice(1) } })") {{contentpage.name}}
		p.text--primary {{contentpage.content.introduction}}
</template>

<script>
import gql from 'graphql-tag';

const queryByTags = gql`
	query getContentpages($limit: Int, $tags: [String]) {
		contentpagesByTag(limit: $limit, tags: $tags) {
			id
			slug
			name
			path
			meta {
				image
				description
			}
			content {
				introduction(length: 120)
			}
		}
	}
`;
const queryByPath = gql`
	query getContentpages($path: String) {
		contentpage(path: $path) {
			id
			slug
			name
			children {
				id
				slug
				name
				path
				meta {
					image
					description
				}
				content {
					introduction(length: 120)
				}
			}
		}
	}
`;

export default {
	name: 'ContentpageListEmbed',
	props: {
		limit: {
			type: String,
			default: '3'
		},
		tags: {
			type: Array,
			default() {
				return [''];
			}
		},
		display: {
			type: String,
			default: 'list',
			validator(val) {
				return ['list', 'cards'].includes(val);
			}
		},
		source: {
			type: String,
			default: 'tags',
			validator(val) {
				return ['tags', 'childpages'].includes(val);
			}
		}
	},
	data() {
		return {
			contentpages: []
		};
	},
	async fetch() {
		if (this.source === 'tags') {
			this.contentpages = await this.$graphClient
				.query({
					query: queryByTags,
					variables: {
						tags: this.tags,
						limit: parseInt(this.limit) + 1
					}
				})
				.then(({ data }) => {
					return data.contentpagesByTag
						.filter(page => page.path !== this.$route.path)
						.slice(0, parseInt(this.limit));
				});
		} else if (this.source === 'childpages') {
			this.contentpages = await this.$graphClient
				.query({
					query: queryByPath,
					variables: {
						path: this.$route.path
					}
				})
				.then(({ data }) => {
					return data.contentpage.children;
				});
		}
	},
	fragments: gql`
		fragment ContentpageListEmbedDataFragment on Embed {
			data {
				... on EmbedDataContentpageList {
					limit
					tags
					display
					source
				}
			}
		}
	`
};
</script>
