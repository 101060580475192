<template lang="pug">
v-dialog(v-model="showDialog" @keydown.esc="closeDialog" max-width="800px" :persistent="dirty" :fullscreen="$vuetify.breakpoint.xsOnly" v-bind="$attrs" v-on="$listeners")
	v-card
		v-toolbar(flat color="info")
			v-toolbar-title
				slot(name="toolbar-title") {{title}}
			v-spacer
			slot(name="toolbar")
			v-btn(icon @click="closeDialog")
				v-icon close
		slot

</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true
		},
		dirty: {
			type: Boolean,
			default: false
		},
		value: Boolean
	},
	data() {
		return {
			showDialog: false
		};
	},
	watch: {
		value: {
			handler() {
				this.showDialog = this.value;
			}
		}
	},
	created() {
		this.showDialog = this.value;
	},
	methods: {
		closeDialog() {
			this.showDialog = false;
			this.$emit('input', false);
		}
	}
};
</script>
