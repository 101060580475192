const countries = [
	'at',
	'fr',
	'de',
	'ch',
	'it',
	'ad',
	'cz',
	'li',
	'no',
	'si',
	'be',
	'bg',
	'ca',
	'es',
	'se',
	'sk',
	'tr',
	'us',
	'fi'
];

const partners = {
	DS: 119,
	belvilla: 23,
	booking: 54,
	chaletnl: 39,
	chaletsplus: 6,
	interhome: 26,
	landal: 19,
	novasol: 116,
	sportiek: 31,
	summit: 28,
	sunweb: 11,
	topchalets: 33,
	// tui: 36,
	snowtrex: 44,
	villaforyou: 120
};

const mapping = {
	'nl-NL': {
		date: [/(\d{4}-\d{2}-\d{2})/],
		duration: [/(\d{1,2})nachten/, parseInt],
		persons: [/(\d{1,2})personen/, parseInt],
		bedrooms: [/(\d{1,2})kamers/, parseInt],
		isInResort: [/dorp/, Boolean],
		isNearSkilift: [/skilift/, Boolean],
		showMap: [/kaart/, Boolean],
		onsite: [/direct/, Boolean],
		rating: [/(\d{1})beoordeling/, parseInt],
		stars: [/(\d{1})sterren/, parseInt],
		elevation: [/hoogte-(\d{3,4})/, parseInt],
		distanceHighway: [/snelweg-(\d{1,3})/, parseInt],
		distanceUtrecht: [/utrecht-(\d{3,4})/, parseInt],
		distanceBrussel: [/brussel-(\d{3,4})/, parseInt],
		areaslugs: [/areas-([\w-_,]*)/, areas => areas.split(',')],
		resortslugs: [/resorts-([\w-_,]*)/, resorts => resorts.split(',')],
		regions: [/regions-([\w-_,]*)/, regions => regions.split(',')],
		accommodationId: [/([A-Z]{2}_[0-9]*)/],
		countries: [
			new RegExp(`(\\b${countries.join('\\b|\\b')}\\b)`, 'g'),
			[
				countries.reduce((carry, country) => {
					carry[country] = country;
					return carry;
				}, {})
			]
		],
		pistes: [
			/(gemakkelijk|moeilijk|aaneengesloten|nietvlak|dalafdaling|zomerski)/g,
			[
				{
					gemakkelijk: 'isEasy',
					moeilijk: 'isDifficult',
					aaneengesloten: 'hasConnectedSlopes',
					nietvlak: 'hasNoFlatAreas',
					dalafdaling: 'hasDownHill',
					zomerski: 'hasSummerSkiing'
				}
			]
		],
		features: [
			/(beginners|gevorderden|offpiste|rustig|tochten|sneeuwzeker|sfeervol|locatie|kindvriendelijk|apresski|prijs)/g,
			[
				{
					beginners: 'beginners',
					gevorderden: 'advanced',
					offpiste: 'offpiste',
					rustig: 'crowd',
					tochten: 'tours',
					sneeuwzeker: 'snowguarantee',
					sfeervol: 'authentic',
					locatie: 'location',
					kindvriendelijk: 'kids',
					apresski: 'apresski',
					prijs: 'price'
				}
			]
		],
		sizes: [
			/(\bs\b|\bm\b|\bl\b|\bxl\b)/g,
			[{ s: 's', m: 'm', l: 'l', xl: 'xl' }]
		],
		types: [
			/(\bappartement\b|\bpension\b|\bchalet\b|\bhotel\b)/g,
			[
				{
					appartement: 'apartment',
					pension: 'pension',
					chalet: 'chalet',
					hotel: 'hotel'
				}
			]
		],
		facilities: [
			/(wifi|sauna|zwembad|jacuzzi)/g,
			[
				{
					wifi: 'wifi',
					sauna: 'sauna',
					zwembad: 'swimming',
					jacuzzi: 'hottub'
				}
			]
		],
		partners: [
			new RegExp(`(\\b${Object.keys(partners).join('\\b|\\b')}\\b)`, 'g'),
			[partners]
		],
		servicetype: [
			/(overnachting|ontbijt|halfpension)/,
			{
				overnachting: 'roomonly',
				ontbijt: 'breakfast',
				halfpension: 'halfboard'
			}
		],
		transporttype: [
			/(bus|vliegtuig|trein)/,
			{
				bus: 'bus',
				vliegtuig: 'airplane',
				trein: 'train'
			}
		]
	},
	'de-DE': {
		date: [/(\d{4}-\d{2}-\d{2})/],
		duration: [/(\d{1,2})nachte/, parseInt],
		persons: [/(\d{1,2})personen/, parseInt],
		bedrooms: [/(\d{1,2})zimmer/, parseInt],
		isInResort: [/skiort/, Boolean],
		isNearSkilift: [/skilift/, Boolean],
		showMap: [/karte/, Boolean],
		onsite: [/direct/, Boolean],
		rating: [/(\d{1})bewertung/, parseInt],
		stars: [/(\d{1})sterne/, parseInt],
		elevation: [/hohe-(\d{3,4})/, parseInt],
		distanceHighway: [/autobahn-(\d{1,3})/, parseInt],
		distanceUtrecht: [/utrecht-(\d{3,4})/, parseInt],
		distanceBrussel: [/brussel-(\d{3,4})/, parseInt],
		areaslugs: [/areas-([\w-_,]*)/, areas => areas.split(',')],
		resortslugs: [/resorts-([\w-_,]*)/, resorts => resorts.split(',')],
		regions: [/regions-([\w-_,]*)/, regions => regions.split(',')],
		accommodationId: [/([A-Z]{2}_[0-9]*)/],
		countries: [
			new RegExp(`(\\b${countries.join('\\b|\\b')}\\b)`, 'g'),
			[
				countries.reduce((carry, country) => {
					carry[country] = country;
					return carry;
				}, {})
			]
		],
		pistes: [
			/(einfach|schwer|miteinanderverbunden|nichtflach|talabfahrt|sommerskifahren)/g,
			[
				{
					einfach: 'isEasy',
					schwer: 'isDifficult',
					miteinanderverbunden: 'hasConnectedSlopes',
					nichtflach: 'hasNoFlatAreas',
					talabfahrt: 'hasDownHill',
					sommerskifahren: 'hasSummerSkiing'
				}
			]
		],
		features: [
			/(anfaenger|fortgeschrittene|abseitsderpiste|ruhig|tagestouren|schneesicher|atmospharisch|Lage|kinderfreundlich|apresski|preis)/g,
			[
				{
					anfaenger: 'beginners',
					fortgeschrittene: 'advanced',
					abseitsderpiste: 'offpiste',
					ruhig: 'crowd',
					tagestouren: 'tours',
					schneesicher: 'snowguarantee',
					atmospharisch: 'authentic',
					Lage: 'location',
					kinderfreundlich: 'kids',
					apresski: 'apresski',
					preis: 'price'
				}
			]
		],
		sizes: [
			/(\bs\b|\bm\b|\bl\b|\bxl\b)/g,
			[{ s: 's', m: 'm', l: 'l', xl: 'xl' }]
		],
		types: [
			/(\bapartment\b|\bpension\b|\bchalet\b|\bhotel\b)/g,
			[
				{
					apartment: 'apartment',
					pension: 'pension',
					chalet: 'chalet',
					hotel: 'hotel'
				}
			]
		],
		facilities: [
			/(wifi|sauna|schwimmbad|jacuzzi)/g,
			[
				{
					wifi: 'wifi',
					sauna: 'sauna',
					schwimmbad: 'swimming',
					jacuzzi: 'hottub'
				}
			]
		],
		partners: [
			new RegExp(`(\\b${Object.keys(partners).join('\\b|\\b')}\\b)`, 'g'),
			[partners]
		],
		servicetype: [
			/(ubernachtung|fruhstuck|halbpension)/,
			{
				ubernachtung: 'roomonly',
				fruhstuck: 'breakfast',
				halbpension: 'halfboard'
			}
		],
		transporttype: [
			/(bus|flugzeug|zug)/,
			{
				bus: 'bus',
				flugzeug: 'airplane',
				zug: 'train'
			}
		]
	}
};

const decodeFilters = (filterString, iso) => {
	const filters = {};
	if (!iso) return filters;

	iso = iso === 'nl-BE' ? 'nl-NL' : iso;

	const tester = (expression, term, parser = e => e, array = false) => {
		if (typeof parser !== 'function') {
			const lookup = parser;
			parser = string => {
				return lookup[string];
			};
		}
		if (array) {
			let match;

			while ((match = expression.exec(filterString)) !== null) {
				if (!filters[term]) {
					filters[term] = [];
				}

				const result = parser(match[1]);
				filters[term].push(result);
			}
		} else {
			const match = expression.exec(filterString);
			if (match) {
				filters[term] = parser(match[1] || match[0]);
			}
		}
	};

	for (const [filter, [regex, parser]] of Object.entries(mapping[iso])) {
		if (Array.isArray(parser)) {
			tester(regex, filter, parser[0], true);
		} else {
			tester(regex, filter, parser);
		}
	}

	return filters;
};

const encodeFilters = (filters, iso) => {
	const filterArray = [];
	if (!iso) return filterArray;
	iso = iso === 'nl-BE' ? 'nl-NL' : iso;

	for (const filter of Object.keys(mapping[iso])) {
		const value = filters[filter];

		// for (const [filter, value] of Object.entries(filters)) {
		if (value && mapping[iso][filter]) {
			const [regex, parser] = mapping[iso][filter];
			let parsedValue = [value];

			if (typeof parser !== 'function' && typeof parser === 'object') {
				const reversedParser = {};
				if (Array.isArray(parser)) {
					for (const key in parser[0]) {
						reversedParser[parser[0][key]] = key;
					}
				} else {
					for (const key in parser) {
						reversedParser[parser[key]] = key;
					}
				}
				if (Array.isArray(value)) {
					parsedValue = value.map(singleValue => reversedParser[singleValue]);
				} else {
					parsedValue = [reversedParser[value]];
				}
			}

			parsedValue
				.filter(value => !!value)
				.filter(value => {
					return Array.isArray(value) ? value.length > 0 : true;
				})
				.forEach(value => {
					filterArray.push(
						regex.source.replace(/\\b/g, '').replace(/(\(.*\))/, value)
					);
				});
		}
	}
	return [...new Set(filterArray)].join(':');
};

module.exports = { decodeFilters, encodeFilters };
