import { slugs, deslugged } from '@/locale/slugs';

const localeSlug = (slug, locale = 'nl-NL') => {
	return slugs[locale][slug] || slug;
};

const normalizeSlug = function (slug) {
	return deslugged[slug] || slug;
};

export default (context, inject) => {
	context.$vuetify.lang.current = context.app.i18n.localeProperties.code.slice(
		0,
		2
	);
	inject('normalizeSlug', normalizeSlug);
	inject('localeSlug', localeSlug);
};
