const icons = {
  admin: '',
  'angle-left': '',
  'angle-right': '',
  'arrow-sub': '',
  balloon: '',
  bathroom: '',
  bed: '',
  beer: '',
  boardercross: '',
  bus: '',
  calendar: '',
  camera: '',
  car: '',
  'caret-down': '',
  'caret-left': '',
  'caret-right': '',
  'caret-up': '',
  carpetslope: '',
  cart: '',
  'chair-lift': '',
  check: '',
  climbing: '',
  'clock-o': '',
  cloud: '',
  cloudy: '',
  cocktail: '',
  comments: '',
  conveyor: '',
  couple: '',
  cross: '',
  cutlery: '',
  cycling: '',
  'day-cloudy': '',
  'day-fog': '',
  'day-rain': '',
  'day-showers': '',
  'day-snow-thunderstorm': '',
  'day-snow-wind': '',
  'day-snow': '',
  'day-storm-showers': '',
  'day-sunny': '',
  distance: '',
  diving: '',
  'double-angle-down': '',
  'double-angle-left': '',
  'double-angle-right': '',
  'double-angle-up': '',
  download: '',
  'dryslope-marker': '',
  dryslope: '',
  envelope: '',
  'exclamation-circle': '',
  'external-link': '',
  eye: '',
  family: '',
  filters: '',
  'flat-slope': '',
  friends: '',
  funpark: '',
  globe: '',
  golf: '',
  gondola: '',
  'google-marker': '',
  halfpipe: '',
  hamburger: '',
  'heart-o': '',
  heart: '',
  height: '',
  highway: '',
  'hot-tub': '',
  inbox: '',
  'information-circle': '',
  jump: '',
  langlauf: '',
  layers: '',
  list: '',
  lock: '',
  logout: '',
  'map-marker': '',
  medal: '',
  minus: '',
  'night-alt-cloudy': '',
  'night-alt-rain': '',
  'night-alt-showers': '',
  'night-alt-sleet': '',
  'night-alt-snow-thunderstorm': '',
  'night-alt-snow': '',
  'night-alt-sprinkle': '',
  'night-alt-storm-showers': '',
  'night-clear': '',
  'night-fog': '',
  'night-ski': '',
  'no-entry': '',
  'organisation-marker': '',
  page: '',
  pause: '',
  'peak-height': '',
  pencil: '',
  phone: '',
  'photo-camera': '',
  plane: '',
  play: '',
  plus: '',
  'quote-left': '',
  rain: '',
  raindrop: '',
  revert: '',
  'revolvingslope-marker': '',
  sauna: '',
  search: '',
  settings: '',
  showers: '',
  ski: '',
  'skihall-marker': '',
  skihall: '',
  sleet: '',
  snow: '',
  snowboard: '',
  snowflake: '',
  'sort-drag': '',
  sports: '',
  'square-meters': '',
  'star-half-full': '',
  'star-o': '',
  star: '',
  sticky: '',
  'storm-showers': '',
  'street-view': '',
  suitcase: '',
  'summer-ski': '',
  'swimming-pool': '',
  't-bar': '',
  tags: '',
  'thumbs-down-o': '',
  'thumbs-down': '',
  'thumbs-up-o': '',
  'thumbs-up': '',
  thunderstorm: '',
  tilde: '',
  toboggan: '',
  train: '',
  'trash-o': '',
  trash: '',
  trophy: '',
  user: '',
  users: '',
  'valley-run': '',
  'village-height': '',
  village: '',
  webcam: '',
  weblog: '',
  wifi: '',
  'wind-direction': ''
};

export { icons };
