<script>
import Vue from 'vue';
import Colorable from 'vuetify/es5/mixins/colorable';
import Sizeable from 'vuetify/es5/mixins/sizeable';
import Themeable from 'vuetify/es5/mixins/themeable';

import { convertToUnit } from 'vuetify/es5/util/helpers';

import mixins from 'vuetify/es5/util/mixins';
import { icons } from '../fonts/icons.js';
// prettier-ignore

const SIZE_MAP = {
  small: '16px',
  default: '24px',
  medium: '28px',
  large: '36px',
  xLarge: '40px'
};

const custom = {
	props: {
		circle: Boolean,
		icon: String,
		size: String,
		disabled: Boolean,
		left: Boolean,
		right: Boolean
	},
	methods: {
		getIcon() {
			let iconName = this.icon || '';
			if (this.$slots.default) iconName = this.$slots.default[0].text.trim();

			return iconName;
		},
		getSize() {
			const sizes = {
				small: this.small,
				medium: this.medium,
				large: this.large,
				xLarge: this.xLarge
			};

			const explicitSize = Object.keys(sizes).find(key => sizes[key]);
			if (this.size) {
				return convertToUnit(this.size);
			}
			return explicitSize && SIZE_MAP[explicitSize];
		},
		applyColors(data) {
			data.class = { ...data.class, ...this.themeClasses };
			this.circle
				? this.setBackgroundColor(this.color, data)
				: this.setTextColor(this.color, data);
		},
		getDefaultData() {
			const data = {
				staticClass: 'v-icon material-icons',
				class: {
					'v-icon--disabled': this.disabled,
					'v-icon--left': this.left,
					'v-icon--link': this.$listeners.click || this.$listeners['!click'],
					'v-icon--right': this.right
				},
				attrs: {
					'aria-hidden': true,
					...this.$attrs
				},
				style: { fontFamily: 'ws-icons' },
				on: this.$listeners
			};
			return data;
		}
	},
	render(h) {
		const icon = this.getIcon();
		const data = this.getDefaultData();

		const newChildren = [];

		newChildren.push(icons[icon]);

		const fontSize = this.getSize();
		if (fontSize) {
			const lineHeight =
				parseInt(fontSize) >= 20 && !this.circle
					? parseInt(fontSize) - 5 + 'px'
					: fontSize;
			data.style = {
				...data.style,
				fontSize,
				lineHeight
			};
		}
		if (this.circle) data.style = { ...data.style, borderRadius: '50%' };

		this.applyColors(data);

		return h('i', data, newChildren);
	}
};

const WSIcon = mixins(
	Colorable,
	Sizeable,
	Themeable
	/* @vue/component */
).extend(custom);

export default Vue.extend({
	name: 'WsIcon',

	$_wrapperFor: WSIcon,

	functional: true,

	render(h, { data, children }) {
		let iconName = '';

		// Support usage of v-text and v-html
		if (data.domProps) {
			iconName =
				data.domProps.textContent || data.domProps.innerHTML || iconName;

			// Remove nodes so it doesn't
			// overwrite our changes
			delete data.domProps.textContent;
			delete data.domProps.innerHTML;
		}

		return h(WSIcon, data, iconName ? [iconName] : children);
	}
});
</script>
