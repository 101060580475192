import Vue from 'vue'
import VueApollo from 'vue-apollo'
import 'cross-fetch/polyfill'
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client/src'
import Cookie from 'universal-cookie'
import { InMemoryCache } from 'apollo-cache-inmemory'

Vue.use(VueApollo)

export default (ctx, inject) => {
  const providerOptions = { clients: {} }
  const { app, beforeNuxtRender, req } = ctx
  const AUTH_TOKEN_NAME = 'apollo-token'
  const COOKIE_ATTRIBUTES = {"expires":365,"path":"\u002F","secure":undefined}
  const AUTH_TYPE = 'Bearer '
  const cookies = new Cookie(req && req.headers.cookie)

  // Config

      const NLTokenName = ''  || AUTH_TOKEN_NAME

      function NLGetAuth () {
        const token = cookies.get(NLTokenName)
        return token && NLClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let NLClientConfig

        NLClientConfig = require('@dengl/common/apollo/nl.js')

        if ('default' in NLClientConfig) {
          NLClientConfig = NLClientConfig.default
        }

        NLClientConfig = NLClientConfig(ctx)

      const NLValidateToken = () => true

      if (!NLClientConfig.validateToken) {
        NLClientConfig.validateToken = NLValidateToken
      }

      const NLCache = NLClientConfig.cache
        ? NLClientConfig.cache
        : new InMemoryCache(NLClientConfig.inMemoryCacheOptions ? NLClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        NLCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.NL : null)
      }

      if (!NLClientConfig.getAuth) {
        NLClientConfig.getAuth = NLGetAuth
      }

      if (process.client && NLClientConfig.browserHttpEndpoint) {
        NLClientConfig.httpEndpoint = NLClientConfig.browserHttpEndpoint
      }

      NLClientConfig.ssr = !!process.server
      NLClientConfig.cache = NLCache
      NLClientConfig.tokenName = NLTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!NLClientConfig.httpLinkOptions) {
          NLClientConfig.httpLinkOptions = {}
        }
        if (!NLClientConfig.httpLinkOptions.headers) {
          NLClientConfig.httpLinkOptions.headers = {}
        }
        NLClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let NLApolloCreation = createApolloClient({
        ...NLClientConfig
      })
      NLApolloCreation.apolloClient.wsClient = NLApolloCreation.wsClient

          providerOptions.clients.NL = NLApolloCreation.apolloClient

      const DETokenName = ''  || AUTH_TOKEN_NAME

      function DEGetAuth () {
        const token = cookies.get(DETokenName)
        return token && DEClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let DEClientConfig

        DEClientConfig = require('@dengl/common/apollo/de.js')

        if ('default' in DEClientConfig) {
          DEClientConfig = DEClientConfig.default
        }

        DEClientConfig = DEClientConfig(ctx)

      const DEValidateToken = () => true

      if (!DEClientConfig.validateToken) {
        DEClientConfig.validateToken = DEValidateToken
      }

      const DECache = DEClientConfig.cache
        ? DEClientConfig.cache
        : new InMemoryCache(DEClientConfig.inMemoryCacheOptions ? DEClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        DECache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.DE : null)
      }

      if (!DEClientConfig.getAuth) {
        DEClientConfig.getAuth = DEGetAuth
      }

      if (process.client && DEClientConfig.browserHttpEndpoint) {
        DEClientConfig.httpEndpoint = DEClientConfig.browserHttpEndpoint
      }

      DEClientConfig.ssr = !!process.server
      DEClientConfig.cache = DECache
      DEClientConfig.tokenName = DETokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!DEClientConfig.httpLinkOptions) {
          DEClientConfig.httpLinkOptions = {}
        }
        if (!DEClientConfig.httpLinkOptions.headers) {
          DEClientConfig.httpLinkOptions.headers = {}
        }
        DEClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let DEApolloCreation = createApolloClient({
        ...DEClientConfig
      })
      DEApolloCreation.apolloClient.wsClient = DEApolloCreation.wsClient

          providerOptions.clients.DE = DEApolloCreation.apolloClient

      const BETokenName = ''  || AUTH_TOKEN_NAME

      function BEGetAuth () {
        const token = cookies.get(BETokenName)
        return token && BEClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let BEClientConfig

        BEClientConfig = require('@dengl/common/apollo/be.js')

        if ('default' in BEClientConfig) {
          BEClientConfig = BEClientConfig.default
        }

        BEClientConfig = BEClientConfig(ctx)

      const BEValidateToken = () => true

      if (!BEClientConfig.validateToken) {
        BEClientConfig.validateToken = BEValidateToken
      }

      const BECache = BEClientConfig.cache
        ? BEClientConfig.cache
        : new InMemoryCache(BEClientConfig.inMemoryCacheOptions ? BEClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        BECache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.BE : null)
      }

      if (!BEClientConfig.getAuth) {
        BEClientConfig.getAuth = BEGetAuth
      }

      if (process.client && BEClientConfig.browserHttpEndpoint) {
        BEClientConfig.httpEndpoint = BEClientConfig.browserHttpEndpoint
      }

      BEClientConfig.ssr = !!process.server
      BEClientConfig.cache = BECache
      BEClientConfig.tokenName = BETokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!BEClientConfig.httpLinkOptions) {
          BEClientConfig.httpLinkOptions = {}
        }
        if (!BEClientConfig.httpLinkOptions.headers) {
          BEClientConfig.httpLinkOptions.headers = {}
        }
        BEClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let BEApolloCreation = createApolloClient({
        ...BEClientConfig
      })
      BEApolloCreation.apolloClient.wsClient = BEApolloCreation.wsClient

          providerOptions.clients.BE = BEApolloCreation.apolloClient

  const vueApolloOptions = Object.assign(providerOptions, {
      errorHandler (error) {
          console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
      }
  })

  const apolloProvider = new VueApollo(vueApolloOptions)
  // Allow access to the provider in the context
  app.apolloProvider = apolloProvider

  if (process.server) {
    const ApolloSSR = require('vue-apollo/ssr')
    beforeNuxtRender(({ nuxtState }) => {
      nuxtState.apollo = ApolloSSR.getStates(apolloProvider)
    })
  }

  inject('apolloHelpers', {
    onLogin: async (token, apolloClient = apolloProvider.defaultClient, cookieAttributes = COOKIE_ATTRIBUTES, skipResetStore = false) => {
      // Fallback for tokenExpires param
      if (typeof cookieAttributes === 'number') cookieAttributes = { expires: cookieAttributes }

      if (typeof cookieAttributes.expires === 'number') {
        cookieAttributes.expires = new Date(Date.now()+ 86400*1000*cookieAttributes.expires)
      }

      if (token) {
        cookies.set(AUTH_TOKEN_NAME, token, cookieAttributes)
      } else {
        cookies.remove(AUTH_TOKEN_NAME, cookieAttributes)
      }
      if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
      if (!skipResetStore) {
        try {
          await apolloClient.resetStore()
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('%cError on cache reset (setToken)', 'color: orange;', e.message)
        }
      }
    },
    onLogout: async (apolloClient = apolloProvider.defaultClient, skipResetStore = false) => {
      cookies.remove(AUTH_TOKEN_NAME, COOKIE_ATTRIBUTES)
      if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
      if (!skipResetStore) {
        try {
          await apolloClient.resetStore()
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
        }
      }
    },
    getToken: (tokenName = AUTH_TOKEN_NAME) => {
      return cookies.get(tokenName)
    }
  })
}
