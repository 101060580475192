const isEqual = require('lodash/isEqual');

export default function (to, from, savedPosition) {
	if (from.path === to.path && isEqual(from.query, to.query)) {
		return;
	}

	if (savedPosition) {
		return savedPosition;
	}

	return { x: 0, y: 0 };
}
