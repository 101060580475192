export default (context, inject) => {
	context.app.httpError = (
		code,
		message = null,
		customCode = null,
		error = null
	) => {
		let debug = null;

		switch (code) {
			case 404:
				return context.error({
					statusCode: 404,
					message: 'Page not found',
					customCode
				});
			default:
				if (error && context.app.$env.APP_ENV !== 'production') {
					console.log(error);
					debug = {
						stack: error.stack,
						message: error.message
					};
				}
				return context.error({
					statusCode: 500,
					message: message || 'An unknown error occured',
					customCode,
					debug
				});
		}
	};

	inject('destinationNotFoundCatcher', error => {
		if (
			[
				'GraphQL error: Area not found',
				'GraphQL error: Resort not found',
				'GraphQL error: Country not found',
				'GraphQL error: Region not found'
			].some(v => error.message.includes(v))
		) {
			return context.error({
				statusCode: 404,
				message: 'Page not found'
			});
		} else {
			throw error;
		}
	});
};
