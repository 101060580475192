<template lang="pug">
v-container.breadcrumbs.hidden-sm-and-down
	v-row(no-gutters align="center")
		v-col.grow
			v-breadcrumbs.px-0.hidden-xs-only(:items="allItems")
				template(#divider)
					ws-icon(small) angle-right
		v-col(v-if="isVerenigingPage").shrink
			img.mt-2(src="//static.wintersport.nl/images/nskiv/nskiv-logo.svg" height="40px" )
	v-tooltip(v-if="!isPublic && adminPath" attach=".breadcrumbs")
		p Content niet actief en alleen zichtbaar voor admins
		template(#activator="{ on, attrs }")
			v-btn( v-on="on" small fab top right absolute color="warning" style="right:60px")
				v-icon warning
	v-btn(v-if="adminPath" target="_blank" :href="adminPath" small fab top right absolute color="primary")
		v-icon edit

</template>

<style lang="sass">
.breadcrumbs
	border-bottom: 1px solid var(--v-border-base)
	margin: $spacer * -6 0 $spacer * 6 0	!important
	padding: 0
</style>

<script>
export default {
	name: 'Breadcrumbs',
	props: {
		items: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	data() {
		return {
			isVerenigingPage: false
		};
	},
	computed: {
		adminPath() {
			return this.$store.state.meta.adminPath;
		},
		isPublic() {
			return this.$store.state.meta.isPublic !== false; // lijkt raar, maar om te voorkomen dat null ook als niet public wordt gezien
		},
		allItems() {
			const breadcrumbs = this.$lodash.cloneDeep(this.$store.state.breadcrumbs);
			const items = [
				{ to: '/', text: 'Home', title: 'wintersport' },
				...breadcrumbs
					.map(item => {
						if (!item) return null;
						item.text = item.text.replace('Area:', '');
						item.nuxt = true;
						item.exact = true;
						return item;
					})
					.filter(item => item)
			];
			items.slice(-1)[0].disabled = true;
			return items;
		}
	},
	watch: {
		$route({ _params, _query }) {
			this.setIsVerenigingPage();
		}
	},
	mounted() {
		this.setIsVerenigingPage();
	},
	methods: {
		setIsVerenigingPage() {
			this.isVerenigingPage = ['vereniging', 'verzekering'].includes(
				this.$route.path.split('/')[1]
			);
		}
	}
};
</script>
