<template lang="pug">
v-container.pa-0.embed--image-gallery(v-if="photos.length" :class="[`embed--image-gallery__${display}`]")
	template(v-if="display==='strip'")
		a(:href="activeImage.url" target="_blank")
			figure
				v-img.black(:src="activeImage.url" :aspect-ratio="aspectRatio" :contain="contain" :alt="activeImage.alt" :ratio="16/9")
					slot
				figcaption {{activeImage.caption}}
		v-row.grey--text.mb-6(dense)
			v-col(cols="3" md="2" v-for="(photo, index) in photosWithWidth.slice(0, maxImages)" :key="index")
				a.thumb(@click.prevent="imageClick(index)")
					div(v-if="morePhotos && lastImage(index)").highlight.text-center.py-8 +{{morePhotos}}
					v-img(:src="photo.url" :aspect-ratio="aspectRatio" :alt="photo.alt")
	template(v-if="display==='grid'")
		v-row(dense)
			v-col(cols="12" md="6")
				v-img(:src="photosWithWidth[0].url" :aspect-ratio="4/3" :alt="photosWithWidth[0].alt" v-ripple style="cursor:pointer" @click="openGallery(0)")
			v-col(cols="12" md="3")
				slot(name="tile1")
					v-img.mb-2(v-if="photosWithWidth[1]" :src="photosWithWidth[1].url" :aspect-ratio="4/3" :alt="photosWithWidth[1].alt" v-ripple style="cursor:pointer" @click="openGallery(1)")
				slot(name="tile2")
					v-img.mb-2(v-if="photosWithWidth[2]" :src="photosWithWidth[2].url" :aspect-ratio="4/3" :alt="photosWithWidth[2].alt" v-ripple style="cursor:pointer" @click="openGallery(2)")
			v-col(cols="12" md="3")
				slot(name="tile3")
					v-img.mb-2(v-if="photosWithWidth[3]" :src="photosWithWidth[3].url" :aspect-ratio="4/3" :alt="photosWithWidth[3].alt" v-ripple style="cursor:pointer" @click="openGallery(3)")
						.d-flex.align-end.justify-end(style="height:100%")
							v-btn.ma-2(small color="info" @click="openGallery(0)")
								.primary--text toon alle foto's
				slot(name="tile4")
					v-img.mb-2(v-if="photosWithWidth[4]" :src="photosWithWidth[4].url" :aspect-ratio="4/3" :alt="photosWithWidth[4].alt" v-ripple style="cursor:pointer" @click="openGallery(4)")

	client-only
		lightbox(:media="lightboxImages" :showLightBox="false" ref="lightbox" :showCaption="showCaptions")
</template>

<script>
import gql from 'graphql-tag';

export default {
	name: 'ImageGalleryEmbed',
	props: {
		photos: {
			type: Array,
			required: true
		},
		display: {
			type: String,
			default: 'strip'
		},
		contain: {
			type: Boolean,
			default: false
		},
		aspectRatio: {
			type: Number,
			default: 1.6
		},
		showCaptions: {
			type: Boolean,
			default: false
		},
		imageWidth: {
			type: [Number, String],
			default: 1024
		},
		thumbWidth: {
			type: [Number, String],
			default: 200
		},
		maxImages: {
			type: [Number, String],
			default: 6
		}
	},
	data() {
		return {
			activeImageIndex: 0
		};
	},
	computed: {
		photosWithWidth() {
			return this.photos
				? this.photos.map(photo => {
						const url = new URL(photo.url);
						url.searchParams.append(
							'width',
							this.display === 'grid' ? 600 : this.thumbWidth
						);
						return { ...photo, url: url.toString() };
				  })
				: [];
		},
		lightboxImages() {
			return this.photos.map(photo => {
				const thumb = new URL(photo.url);
				thumb.searchParams.append('width', '200');
				const src = new URL(photo.url);
				src.searchParams.append('width', '1200');
				const obj = { thumb: thumb.toString(), src: src.toString() };
				if (photo.caption) obj.caption = photo.caption;
				return obj;
			});
		},
		activeImage() {
			const photo = this.photos[this.activeImageIndex];
			const url = new URL(photo.url);
			url.searchParams.append('width', this.imageWidth);
			return { ...photo, url: url.toString() };
		},
		morePhotos() {
			return this.photos ? this.photos.slice(this.maxImages).length : false;
		}
	},
	methods: {
		openGallery(index) {
			this.$refs.lightbox.showImage(index);
		},
		imageClick(index) {
			if (this.lastImage(index)) {
				this.openGallery(index);
			} else {
				this.activeImageIndex = index;
			}
		},
		lastImage(index) {
			return index + 1 === parseInt(this.maxImages);
		}
	},
	fragments: gql`
		fragment ImageGalleryEmbedDataFragment on Embed {
			data {
				... on EmbedDataImageGallery {
					photos {
						url
						alt
						caption
					}
					contain
					aspectRatio
				}
			}
		}
	`
};
</script>
<style lang="sass">
.embed--image-gallery
	figure
		position: relative
		margin-bottom: 8px

		img
			width: 100%
			height: auto
			display: block

		figcaption
			padding: 0 0.4rem
			position: absolute
			bottom: 0
			left: 0
			background: rgba(0, 0, 0, 0.6)
			color: #fff
			font-size: 0.8em

	.thumb
		position: relative
		display: block

		.highlight
			background-color: #000
			position: absolute
			opacity: 0.65
			color: #FFF
			top: 0px
			width: 100%
			height: 100%
			z-index: 2
			font-weight: bold
			font-size: 25px
		img
			z-index: 1
</style>
