<template lang="pug">
full-width-page
  .nuxt-error
    component(:is='errorPage' :error='error')
</template>
<script>
import error404 from '@/components/error/404.vue';
import error500 from '@/components/error/500.vue';
import FullWidthPage from '@/components/pages/full-width-page.vue';

export default {
	name: 'NuxtError',
	components: {
		FullWidthPage
	},
	layout: 'empty',
	props: {
		error: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		errorPage() {
			if (parseInt(this.error.statusCode) === 404) {
				return error404;
			}
			// catch everything else
			return error500;
		}
	}
};
</script>
