module.exports = {
	filterstring: {
		type: String,
		default: ''
	},
	introduction: {
		type: String,
		default: ''
	},
	cta: {
		type: String,
		default: 'Bekijk alle'
	}
};
