<template lang="pug">
	v-card(outlined)
		v-container
			v-row
				v-col.shrink.pa-0.pa-md-3(cols="12" md="auto")
					v-img(:src="thumb" :to="detailPage" @click="$gtag.event('click', {event_label:'acco-image', event_category: 'accommodations-search'})" :aspect-ratio="$vuetify.breakpoint.mdAndUp? 4/3: 16/9" :width="$vuetify.breakpoint.mdAndUp ? 250 : 'auto'")
						template(#placeholder)
							v-row.fill-height(align="center" style="background-color:rgb(245,245,245);")
								v-col.text-center
									v-icon image_not_supported
						.d-flex.align-end.d-sm-none(style="height:100%")
							v-card.text-center.d-inline-block.py-2.px-3.ma-2.hidden-md(color="info")
								.text-h5.accent--text.font-weight-bold {{ $n(Math.ceil(cheapestVariant.price), 'simplecurrency', $i18n.locale) }}
								.caption.accent--text(style="font-size:11px !important; line-height:11px") {{ $n(Math.ceil(cheapestVariant.price/cheapestVariant.persons), 'simplecurrency', $i18n.locale) }} p.p.
				v-col.grow.pl-md-0.d-flex.flex-column
					nuxt-link.text-md-h5.font-weight-bold.black--text(style="line-height:24px; word-break:normal" :to="detailPage" @click="$gtag.event('click', {event_label:'acco-title', event_category: 'accommodations-search'})") {{accommodation.name}} {{'*'.repeat(accommodation.stars)}}
					glue-list(glue=" - " lastglue=" - ")
						magic-nuxt-link(:to="accommodation.resort.country")
							img.flag(:src="`https://static.wintersport.nl/images/flags/svg/${accommodation.resort.country.countryCode}.svg`" width="16")
							| {{accommodation.resort.country.name}}
						magic-nuxt-link(:to="accommodation.resort.area") {{accommodation.resort.area.name}}
						magic-nuxt-link.font-weight-bold(:to="accommodation.resort") {{accommodation.resort.name}}
					factline.black--text
						li(icon="map-marker") {{formatDistance(accommodation.distanceToCenter)}} van het centrum {{accommodation.resort.name}}
						li(icon="highway" v-if="accommodation.resort.area.departures.city && accommodation.resort.area.departures.city.distance !== 9999") {{$t('areas_list_item.{distance}_{city}', { distance: accommodation.resort.area.departures.city.distance, city:accommodation.resort.area.departures.city.node.name })}}
						li(:icon="skilifts[0].icon" v-if="skilifts.length") {{formatDistance(skilifts[0].distance)}} tot skilift {{skilifts[0].name}}
					v-spacer
					.hidden-xs-only
						.caption.font-weight-bold(v-if="details.length") Details van dit {{$t(`accommodations-filters.${accommodation.type}`).toLowerCase()}}
						div
							v-chip.px-1.mr-2.black--text(label small color="#CCE3FF" v-for="detail in details" :key="detail") {{detail.toLowerCase()}}
							v-chip.px-1.mr-2.text--black(label small color="#CCE3FF" v-if="!filters.date") vertrek op {{$dayjs(cheapestVariant.date).format('D MMM YYYY')}}
						nuxt-link.mt-3(:to="detailPage") » Meer informatie over dit {{$t(`accommodations-filters.${accommodation.type}`).toLowerCase()}}
					v-btn.font-weight-bold.mt-3.hidden-sm-and-up(color="primary" small depressed :to="detailPage" @click="$gtag.event('click',{event_label:'acco-view-all-options', event_category: 'accommodations-search'})") {{$tc('accommodations_list.view-all-options', accommodation.variants.length)}}
				v-col.pa-1.text-center.d-sm-flex.flex-column.shrink.hidden-sm-and-down(md="auto")
					.caption.font-weight-bold.mb-1(v-if="accommodation.rating")
						| Beoordeeld met een
						|
						v-chip.px-1.white--text(label small :color="ratingcolor") {{accommodation.rating < 10 ? accommodation.rating.toFixed(1): 10}}
					v-card.grow(color="info" flat width="250")
						v-card-text
							.text-h4.accent--text.font-weight-bold {{ $n(Math.ceil(cheapestVariant.price), 'simplecurrency', $i18n.locale) }}
							v-tooltip(top max-width="300" transition="")
								template(#activator="{ on, attrs }")
									.caption.accent--text.mb-1.justify-center.d-flex(v-on="on" v-bind="attrs")
										| {{ $n(Math.ceil(cheapestVariant.price/cheapestVariant.persons), 'simplecurrency', $i18n.locale) }} p.p.
										|
										v-icon.ml-1(x-small color="accent") info
								| {{$t('accommodations_list.price_disclaimer')}}
							v-btn.font-weight-bold.my-2(color="primary" depressed :to="detailPage" @click="$gtag.event('click', {event_label:'acco-view-all-options', event_category: 'accommodations-search'})") {{$tc('accommodations_list.view-all-options', accommodation.variants.length)}}
							.caption(style="font-size:11px !important" v-if="uniquePartners.length")
								| We hebben prijzen gevonden van
								|
								glue-list.font-weight-bold(:lastglue="` ${$t('global.and')} `")
									span(v-for="partner in uniquePartners" :key="partner.id") {{partner.name}}
							.caption.accent--text.d-flex.justify-center(style="font-size:11px !important" v-if="hasOnsiteBooking")
								v-icon(x-small color="accent") check
								|
								| Direct te boeken op wintersport.nl

</template>

<script>
import {
	encodeFilters,
	decodeFilters
} from '@dengl/common/utils/filterstrings';
import AccommodationRating from './rating.vue';

export default {
	components: {
		AccommodationRating
	},
	props: {
		accommodation: {
			type: Object,
			required: true
		},
		filterstring: {
			type: String,
			default: ''
		}
	},
	computed: {
		detailPage() {
			const hash = encodeFilters(
				{
					date: this.cheapestVariant.date,
					...this.filters
				},
				this.$i18n.locale
			);

			return this.localePath({
				name: 'accommodations-search-id',
				params: { id: this.accommodation.id },
				hash: `#${hash}`
			});
		},
		uniquePartners() {
			return this.accommodation.variants
				.map(variant => variant.partner)
				.filter(partner => partner.name !== 'DS')
				.reduce((unique, item) => {
					if (!unique.map(u => u.id).includes(item.id)) unique.push(item);
					return unique;
				}, []);
		},
		hasOnsiteBooking() {
			return this.accommodation.variants.some(variant => {
				return variant.checkout === 'ONSITE';
			});
		},
		skilifts() {
			return this.accommodation.pois
				.filter(poi => {
					return poi.item.__typename === 'Skilift';
				})
				.map(poi => {
					return {
						name: poi.item.name,
						distance: poi.distance,
						icon: poi.item.type
					};
				});
		},
		cheapestVariant() {
			return this.accommodation.variants[0];
		},
		details() {
			return [
				...this.specialServiceTypes.map(type =>
					this.$t(`accommodations-filters.${type}`)
				),
				// zodra iemand interesse heeft in facilities tonen we ze allemaal
				...(this.filters.facilities
					? this.accommodation.facilities.map(facility =>
							this.$t(`accommodations-filters.${facility}`)
					  )
					: []),
				// we maken hier een lijst met varianten met meeste room surface per aantal kamers
				...Object.entries(
					this.accommodation.variants.reduce((carry, variant) => {
						if (variant.transporttype === 'bus') {
							carry[
								this.$tc('accommodations_list.duration', variant.duration)
							] = 0;
						}
						const room = this.$tc(
							'accommodations_list.bedrooms',
							variant.bedrooms
						);
						if (!carry[room] || variant.roomsurface > carry[room]) {
							carry[room] = variant.roomsurface;
						}
						return carry;
					}, {})
				).map(([room, surface]) => {
					return surface ? room + ` (${surface}m²)` : room;
				})
			];
		},
		filters() {
			return decodeFilters(this.filterstring, this.$i18n.locale);
		},
		serviceTypes() {
			return [
				...new Set(
					this.accommodation.variants.map(variant => variant.servicetype)
				)
			];
		},
		specialServiceTypes() {
			// only return servicetypes when roomonly is not an option
			return this.serviceTypes.includes('roomonly') ? [] : this.serviceTypes;
		},
		thumb() {
			if (!this.accommodation.images.length) {
				return '';
			}

			let url = new URL(this.accommodation.images[0]);

			if (!url.toString().includes('bstatic.com')) {
				url.searchParams.append('width', 250);

				const imageWithoutProtocol = url.toString().replace(/https?:\/\//i, '');

				url = `https://cdn.wintersport.nl/proxy/${imageWithoutProtocol}`;
			}

			return url.toString();
		},
		ratingcolor() {
			if (this.accommodation.rating >= 9) {
				return '#1f7a45';
			}
			if (this.accommodation.rating >= 8) {
				return '#29a35c';
			}
			if (this.accommodation.rating >= 7) {
				return 'success';
			}
			if (this.accommodation.rating >= 6) {
				return 'warning';
			}
			return '#999999';
		}
	},
	methods: {
		formatDistance(distance) {
			if (distance < 10) {
				return '5m kruipen';
			} else if (distance < 200) {
				return Math.ceil(distance / 10) * 10 + 'm';
			} else if (distance < 500) {
				return Math.round(distance / 50) * 50 + 'm';
			} else if (distance < 1000) {
				return Math.round(distance / 100) * 100 + 'm';
			} else {
				return (distance / 1000).toFixed(1) + 'km';
			}
		}
	}
};
</script>

<style lang="sass">
.partnerlogos
	display: grid
	grid-template-columns: 50% 50%
</style>
