<template lang="pug">
v-menu(bottom left offset-y transition="slide-y-transition" open-on-hover :disabled="items.length === 0")
	template(#activator="props")
		slot(name="button" v-bind="props")
			v-btn.nav--btn.font-weight-bold.px-2(text v-bind="props.attrs" :to="to" v-on="props.on" :color="color" depressed)
				slot
				v-icon.mr-n2.ml-n1(v-if="items.length") arrow_drop_down
	v-list(dense)
		template(v-for="item in items")
			v-divider.my-3(v-if="item === '---'")
			//- v-subheader.font-weight-bold.black--text(v-else-if="!item.path") {{item.text}}
			v-list-item(v-else-if="item.path" :to="localePath(item.path)" exact color="primary")
				v-list-item-icon.mr-3(v-if="item.icon")
					v-icon(small dense color="primary") {{item.icon}}
				v-list-item-title.body-2(:class="[item.color? `${item.color}--text`:'primary--text']") {{item.text}}
			v-list-item(v-else-if="item.url" target="_blank" :href="item.url" exact color="primary")
				v-list-item-title.body-2(:class="[item.color? `${item.color}--text`:'primary--text']") {{item.text}}
			v-list-item(v-else disabled)
				v-list-item-title.body-2.font-weight-bold.black--text {{item.text}}
		slot(name="list")
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: 'primary'
		},
		items: {
			type: Array,
			default() {
				return [];
			}
		},
		to: {
			type: String,
			required: true
		}
	}
};
</script>

<style scoped lang="sass">
.v-list--dense .v-list-item
	min-height: 32px

.nav--btn:hover::before
	opacity: 0.15
	background-color: var(--v-primary-base)
</style>
