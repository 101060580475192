import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Vue from 'vue';

import 'dayjs/locale/nl';
import 'dayjs/locale/de';

export default (context, inject) => {
	dayjs.locale(context.app.i18n.locale.split('-')[0]);
	dayjs.extend(relativeTime);

	inject('dayjs', dayjs);
};

Vue.filter('date', value => {
	const dateDayJS = dayjs(value);

	const today = dayjs();
	let format;

	if (today.format('MM-DD') === dateDayJS.format('MM-DD')) {
		format = 'HH:mm';
	} else {
		format = 'DD-MM-YY · HH:mm';
	}

	return dateDayJS.format(format);
});
