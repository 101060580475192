<template lang="pug">
	v-card.mt-6(outlined)
		v-card-title
			span.font-weight-bold.black--text.text-subtitle-2 Disclaimer
		v-card-text.caption
			p MILQ Media B.V. bemiddelt slechts bij de afname van {{productString}} bij de dienstverlener en is  geen partij bij de overeenkomst tussen de boeker en de dienstverlener. De voorwaarden van  de dienstverlener zijn van toepassing op die overeenkomst. MILQ Media B.V is niet verantwoordelijk  voor de uitvoer van de geboekte diensten. Bij problemen dient de boeker zich te richten tot de dienstverlener.
			p Op de bemiddeling zijn de <a href="https://static.wintersport.nl/pdf/voorwaarden-milq-bv-06-2021.pdf" target="_blank">bemiddelingsvoorwaarden en privacybeleid van MILQ Media B.V.</a> van toepassing.
			p De reiziger kan geen aanspraak maken op de rechten die uitsluitend gelden voor pakketreizen en elke dienstverlener is uitsluitend aansprakelijk voor de goede contractuele  uitvoering van zijn eigen diensten. De reiziger kan evenmin aanspraak maken op  insolventiebescherming.

</template>

<script>
export default {
	name: 'OTADisclaimer',
	props: {
		productType: {
			type: String,
			default: 'accommodation'
		}
	},
	computed: {
		productString() {
			let string;
			switch (this.productType) {
				case 'accommodation':
					string = 'een accommodatie';
					break;

				case 'rental':
					string = 'skihuur';
					break;

				case 'lessons':
					string = 'skiles';
					break;
			}

			return string;
		}
	}
};
</script>

<style></style>
