<template lang="pug">
div
	topic-list(:topics="topics" hide-last-visited-bar)
</template>

<script>
import gql from 'graphql-tag';
import TopicList from '@dengl/common/components/forum/topic-list.vue';
const query = gql`
	query getTopics($limit: Int, $tags: [String]) {
		topicsByTag(limit: $limit, tags: $tags) {
			topics {
				id
				slug
				title
				created_at
				replyCount
				lastreply_at
				category
				isSticky
				isItemSold
				isClosed
				edited {
					editedAt
				}
				content {
					markdown
				}
				# user {
				# 	id
				# 	username
				# 	slug
				# }
				user: profile {
					id
					username
					slug
				}
				destinations {
					id
					name
					slug
					__typename
				}
			}
		}
	}
`;

export default {
	name: 'TopicListEmbed',
	components: { TopicList },
	props: {
		limit: {
			type: String,
			default: '3'
		},
		tags: {
			type: Array,
			default() {
				return [''];
			}
		}
	},
	async fetch() {
		this.topics = await this.$graphClient
			.query({
				query,
				variables: {
					tags: this.tags,
					limit: parseInt(this.limit)
				}
			})
			.then(({ data }) => {
				return data.topicsByTag.topics;
			});
	},
	data() {
		return {
			topics: []
		};
	},

	fragments: gql`
		fragment TopicListEmbedDataFragment on Embed {
			data {
				... on EmbedDataTopicList {
					limit
					tags
				}
			}
		}
	`
};
</script>
