<template lang="pug">

v-row
	v-col.py-0(cols="12" md="6" v-for="(item, index) in items" :key="index" v-if="item.url")
		a(:href="`/redir?u=${encodeURIComponent(item.url)}&p=${item.partner.slug}&t=partnerlistembed&h=${$redirHash(item.url)}`" rel="nofollow"  target="_blank" @click="item.checked=true")
			v-list-item.pa-0(dense)
				v-list-item-action.ma-0
					v-img(:src="`${item.partner.image}?width=80`" width="60")
				v-list-item-content.pa-0.mx-3.primary--text {{item.title}}
				v-list-item-action.ma-0
					v-btn(x-small color="warning" depressed)
						span(v-if="!item.checked") {{$t('travelbox.button_cta')}}
						ws-icon(small v-else) check
</template>

<script>
import gql from 'graphql-tag';

export default {
	name: 'PartnerListEmbed',
	props: {
		items: {
			type: Array,
			required: true
		}
	},
	data() {
		return {};
	},
	fragments: gql`
		fragment PartnerListEmbedDataFragment on Embed {
			data {
				... on EmbedDataPartnerList {
					items {
						title
						url
						partner {
							id
							slug
							name
							image
						}
					}
				}
			}
		}
	`
};
</script>
