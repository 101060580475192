<script>
export default {
	props: {
		views: {
			type: Number,
			default: 0
		},
		value: {
			type: Number,
			default: null
		}
	},
	data() {
		return {
			rating: null
		};
	},
	computed: {
		color() {
			return this.value > 8 ? '#e2aa11' : 'accent';
		},
		stringValue() {
			return (
				{
					10: 'Fantastisch',
					9: 'Fantastisch',
					8: 'Erg goed',
					7: 'Goed',
					6: 'Prima',
					5: 'matig',
					4: 'slecht'
				}[Math.round(this.value)] || ''
			);
		}
	}
};
</script>
<template lang="pug">
	v-list-item.accommodationrating.pa-0(two-line)
		v-list-item-content.pa-0.text-right
			v-list-item-title.accommodationrating-string(:class="{'font-weight-bold': value >= 7, 'gold-text': value >= 8}") {{stringValue}}
			v-list-item-subtitle.caption.font-weight-bold(v-if="views > 0") {{views}}x bekeken
		v-list-item-avatar.my-0(tile v-if="value")
			v-sheet.accommodationrating-float.white--text(:color="color" tile) {{value}}
</template>



<style lang="sass">
.accommodationrating
	$self: &

	min-height: 0px !important
	max-width: 160px
	&-string
		color: var(--v-accent-base)
		font-size: 18px
		&.gold-text
			color: #e2aa11

	&-float
		width: 40px
		height: 40px
		font-size: 16px
		font-weight: bold
		text-align: center
		line-height: 40px
		display: inline-block
</style>
