const { ApolloLink } = require('apollo-link');

const middleWareLink = new ApolloLink((operation, forward) => {
	if (operation.variables) {
		const omitTypename = (key, value) =>
			['__typename', 'history'].includes(key) ? undefined : value;
		// eslint-disable-next-line no-param-reassign
		operation.variables = JSON.parse(
			JSON.stringify(operation.variables),
			omitTypename
		);
	}
	return forward(operation);
});

module.exports = { middleWareLink };
