
<script>
import LegendJetstream from './jetstream.vue';
import LegendSun from './sun.vue';
import LegendTemp from './temp.vue';
import LegendPrecip from './precip.vue';
import LegendWind from './wind.vue';

const legends = {
	'legend-jetstream': LegendJetstream,
	'legend-sun': LegendSun,
	'legend-temp': LegendTemp,
	'legend-precip': LegendPrecip,
	'legend-wind': LegendWind
};

export default {
	props: {
		type: {
			type: String,
			required: true
		}
	},
	render: function(h) {
		return h(legends[this.type]);
	}
};
</script>


<style lang="sass">
.legend
	position: relative
	width: 100%

	:after
		clear: both

	ul
		margin: 0
		padding: 0

		li
			height: 28px
			float: left
			border: 1px solid black
			border-left: 0
			list-style: none
			margin: 0
			padding: 0
			color: #fff
			font-size: 12px
			text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7)
			text-align: center
			line-height: 26px

			&:nth-child(1)
				border-left: 1px solid black

		&.snowfall li
			width: 10%

		&.snowfall.split
			li
				width: 9.09%

				&:first-child
					border: none
					border-right: 1px solid black
					background: #333
					color: white
					text-shadow: none

		&.rain li
			width: 6.66667%

		&.rain.split li
			width: 12.5%

			&:first-child
				border: none
				border-right: 1px solid black
				background: #333
				color: white
				text-shadow: none

		&.wind li
			width: 10%

			&:first-child
				border: none
				border-right: 1px solid black
				background: #333
				color: white
				text-shadow: none

		&.temperature li
			width: 4.54%

			&:first-child
				border: none
				border-right: 1px solid black
				background: #333
				color: white
				text-shadow: none

		&.sun li
			width: 9.09%

			&:first-child
				border: none
				border-right: 1px solid black
				background: #333
				color: white
				text-shadow: none

		&.jetstream li
			width: 10%

			&:first-child
				border: none
				border-right: 1px solid black
				background: #333
				color: white
				text-shadow: none

		&.push
			margin-left: 30px

			&.snowfall:before
				position: absolute
				left: 0
				top: 0

				padding: 0
</style>
