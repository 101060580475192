<template lang="pug">
  span.bar.horizontal
    | {{snowheight}}cm&nbsp;
    i(:style="`width:${_percentage}%;`") {{snowheight}}cm&nbsp;
</template>

<script>
export default {
	props: {
		snowheight: { type: Number, default: 0 },
		max: { type: Number, default: 0 },
		percentage: { type: Number, default: 0 }
	},
	computed: {
		_percentage() {
			if (!this.percentage && !(this.snowheight >= 0 && this.max >= 0)) {
				throw new Error(
					'either percentage or snowheight in combination of max must be set in snowheigth bar'
				);
			}

			if (!this.percentage && this.max > 0) {
				return Math.round((this.snowheight / this.max) * 100);
			}

			return this.percentage || 0;
		}
	}
};
</script>
