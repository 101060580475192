<template lang="pug">
div(:style="`position: relative; height: ${height}px;`")
  svg(:width="width" :height="height" style="right: 0; position: absolute;")
    rect.background(:width="width" :height="height - axisPadding")
    g(v-for="{value, offset, barHeight, label, barLabel, active} in processedData" :transform="`translate(${offset} 0)`" :style="clickable ? 'cursor: pointer;' : ''" :class="barWidth < 30 ? 'compact':''" @click="$emit('clickedbar', label)")
      rect.chartbar(:height="barHeight" :width="barWidth" :transform="`translate(0 ${height-barHeight - axisPadding})`" :class="active ? 'active':''")
      g(:transform="`translate(${barWidth * 0.5} ${height - barHeight - axisPadding})`" )
        text.barLabel(v-if="value" :class="barHeight > 30 ? 'inside': 'outside'") {{barLabel}}
      g(:transform="`translate(${barWidth * 0.5} ${height - axisPadding + 5})`" )
        text.axisLabel {{label}}
</template>

<style lang="sass">
.background
	fill: var(--v-info-base)
	stroke: var(--v-border-base)

.chartbar
	fill: var(--v-accent-base)

.axisLabel
	font-size: 10px
	alignment-baseline: middle
	text-anchor: middle
	transform: translate(0, 6px)

.barLabel
	font-size: 10px
	alignment-baseline: middle
	text-anchor: middle
	&.inside
		fill: white
		transform: translate(0, 10px)
	&.outside
		transform: translate(0, -6px)

.compact
	.axisLabel
		transform: rotate(-90deg)
		text-anchor: end
	.barLabel
		&.inside
			transform: translate(0, 2px) rotate(-90deg)
			text-anchor: end

		&.outside
			transform: translate(0, -2px) rotate(-90deg)
			text-anchor: start

.active
	fill: var(--v-primary-base)
</style>

<script>
export default {
	name: 'Barchart',
	props: {
		height: {
			type: Number,
			default: 120
		},
		clickable: {
			type: Boolean,
			default: false
		},
		infosheet: {
			type: Boolean,
			default: false
		},
		data: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data: () => ({
		axisPadding: 40,
		barSpacing: 1
	}),
	computed: {
		barWidth() {
			const width = Math.round(
				this.width / this.data.length - this.barSpacing * 1.5
			);
			return width > 0 ? width : 10;
		},
		max() {
			return Math.max(...this.data.map(e => e.value)) * 1.1 || 1;
		},
		processedData() {
			return this.data.map((originalBar, index) => {
				const bar = { ...originalBar };
				bar.offset = index * (this.width / this.data.length) + 2;
				bar.barHeight = Math.round(
					(bar.value * (this.height - this.axisPadding)) / this.max
				);
				return bar;
			});
		},
		width() {
			return this.infosheet
				? this.$store.state.content_width.infosheet
				: this.$store.state.content_width.full_unpadded;
		}
	},
	methods: {
		calculateWidth() {
			this.width = this.$el.offsetWidth;
		}
	}
};
</script>
