export const alpenhoofdkam = {
	type: 'LineString',
	id: 99,
	coordinates: [
		[15.71044, 47.69866],
		[15.41107, 47.68756],
		[15.13916, 47.61357],
		[14.9826, 47.54501],
		[14.82879, 47.54409],
		[14.77111, 47.56354],
		[14.68322, 47.56169],
		[14.60495, 47.53202],
		[14.56787, 47.47172],
		[14.46075, 47.42808],
		[14.35638, 47.4095],
		[14.21356, 47.34254],
		[14.057, 47.2494],
		[13.87023, 47.2755],
		[13.79333, 47.28295],
		[13.68209, 47.26059],
		[13.57498, 47.2494],
		[13.44863, 47.22143],
		[13.39508, 47.16264],
		[13.3786, 47.09162],
		[13.28659, 47.09256],
		[13.26187, 47.08041],
		[13.25912, 47.06263],
		[13.20556, 47.04391],
		[13.07922, 47.01678],
		[13.04626, 47.02706],
		[13.01742, 47.04299],
		[12.97485, 47.03643],
		[12.92541, 47.07759],
		[12.84713, 47.08321],
		[12.72903, 47.11592],
		[12.55462, 47.12995],
		[12.48321, 47.1477],
		[12.39532, 47.14955],
		[12.36923, 47.14115],
		[12.36648, 47.11967],
		[12.23602, 47.07104],
		[12.21542, 47.08321],
		[12.18521, 47.08882],
		[12.09457, 47.07104],
		[12.02453, 47.04954],
		[11.94213, 47.03736],
		[11.86798, 47.00648],
		[11.78558, 46.98774],
		[11.77185, 46.9765],
		[11.74575, 46.9718],
		[11.71279, 46.99055],
		[11.67434, 46.99055],
		[11.62765, 47.01208],
		[11.54251, 46.9868],
		[11.51367, 47.00367],
		[11.48208, 47.00835],
		[11.45324, 46.99243],
		[11.42028, 46.95776],
		[11.32965, 46.99055],
		[11.26098, 46.96994],
		[11.19506, 46.969],
		[11.16897, 46.93994],
		[11.09069, 46.89022],
		[11.1058, 46.83858],
		[11.05361, 46.80944],
		[11.04126, 46.76995],
		[10.90393, 46.75867],
		[10.74737, 46.79159],
		[10.65673, 46.86394],
		[10.45898, 46.82825],
		[10.37933, 46.73609],
		[10.23651, 46.62492],
		[10.15136, 46.61171],
		[10.11566, 46.60794],
		[10.09094, 46.57774],
		[10.04149, 46.51162],
		[10.05523, 46.43217],
		[9.9948, 46.37345],
		[9.88494, 46.37155],
		[9.77233, 46.34692],
		[9.69543, 46.37345],
		[9.53613, 46.43785],
		[9.25872, 46.50217],
		[9.12963, 46.53524],
		[9.05822, 46.57302],
		[9.01702, 46.60039],
		[8.96072, 46.60699],
		[8.78356, 46.57207],
		[8.61877, 46.5815],
		[8.46083, 46.51162],
		[8.38531, 46.45867],
		[8.3551, 46.39997],
		[8.21777, 46.31848],
		[8.20541, 46.30519],
		[8.18618, 46.29761],
		[8.14223, 46.30234],
		[8.12027, 46.28953],
		[8.08319, 46.26248],
		[8.10928, 46.24919],
		[8.1237, 46.22877],
		[8.13812, 46.22401],
		[8.14567, 46.20787],
		[8.16627, 46.17697],
		[8.15116, 46.15462],
		[8.14704, 46.13653],
		[8.12645, 46.13464],
		[8.10379, 46.11037],
		[8.03581, 46.09989],
		[8.02139, 46.07226],
		[8.03237, 46.04367],
		[7.99804, 46.00077],
		[7.91084, 45.99552],
		[7.87925, 45.97309],
		[7.87719, 45.94493],
		[7.87513, 45.92248],
		[7.85522, 45.91675],
		[7.82226, 45.92631],
		[7.80372, 45.91867],
		[7.75222, 45.9373],
		[7.72613, 45.92153],
		[7.70759, 45.93491],
		[7.71102, 45.9478],
		[7.6966, 45.95352],
		[7.68013, 45.95782],
		[7.66296, 45.97406],
		[7.61695, 45.97024],
		[7.58606, 45.97024],
		[7.57232, 45.98693],
		[7.54623, 45.98074],
		[7.54142, 45.97071],
		[7.54554, 45.95925],
		[7.4961, 45.95974],
		[7.47482, 45.95019],
		[7.47413, 45.93777],
		[7.44941, 45.93299],
		[7.43774, 45.92248],
		[7.38281, 45.8986],
		[7.34916, 45.91294],
		[7.32032, 45.91151],
		[7.29423, 45.91962],
		[7.2647, 45.89287],
		[7.21664, 45.88666],
		[7.19604, 45.8594],
		[7.16033, 45.86993],
		[7.16171, 45.87614],
		[7.15278, 45.87852],
		[7.12738, 45.86706],
		[7.11158, 45.85797],
		[7.10197, 45.86132],
		[7.09991, 45.86706],
		[7.07519, 45.89526],
		[7.06283, 45.90052],
		[7.06077, 45.91246],
		[7.04292, 45.92248],
		[7.01477, 45.91054],
		[7.00515, 45.8986],
		[7.00996, 45.88666],
		[6.97975, 45.86801],
		[6.94816, 45.85702],
		[6.92894, 45.84602],
		[6.91108, 45.8417],
		[6.88224, 45.84697],
		[6.87057, 45.824],
		[6.84928, 45.83644],
		[6.82869, 45.83787],
		[6.81701, 45.83358],
		[6.81221, 45.82545],
		[6.80259, 45.77278],
		[6.80877, 45.72486],
		[6.84997, 45.68698],
		[6.88911, 45.67596],
		[6.90422, 45.67836],
		[6.90078, 45.66444],
		[6.91589, 45.66011],
		[6.92894, 45.64667],
		[6.97151, 45.65292],
		[6.99554, 45.63803],
		[6.97769, 45.58377],
		[6.99554, 45.56742],
		[6.98936, 45.55828],
		[6.99348, 45.53184],
		[7.00447, 45.51837],
		[6.99897, 45.50585],
		[7.03056, 45.49575],
		[7.05734, 45.49478],
		[7.04772, 45.48371],
		[7.0491, 45.47264],
		[7.10197, 45.46783],
		[7.09922, 45.45386],
		[7.11708, 45.43363],
		[7.18368, 45.40808],
		[7.17681, 45.38927],
		[7.16033, 45.37144],
		[7.16171, 45.3613],
		[7.15416, 45.35166],
		[7.1315, 45.34151],
		[7.10815, 45.32318],
		[7.13493, 45.28165],
		[7.13424, 45.25603],
		[7.11502, 45.24346],
		[7.09648, 45.23186],
		[7.08, 45.21397],
		[7.07176, 45.20912],
		[7.04635, 45.22267],
		[6.98455, 45.21493],
		[6.94747, 45.17429],
		[6.90284, 45.16508],
		[6.89392, 45.16653],
		[6.8843, 45.15831],
		[6.89529, 45.13893],
		[6.87881, 45.13168],
		[6.78817, 45.15589],
		[6.74285, 45.13748],
		[6.70166, 45.14038],
		[6.63436, 45.11035],
		[6.67694, 45.02014],
		[6.74285, 45.01627],
		[6.74285, 44.99102],
		[6.76483, 44.96284],
		[6.74835, 44.94147],
		[6.75384, 44.90938],
		[6.87057, 44.84808],
		[6.91177, 44.84709],
		[6.93649, 44.85878],
		[7.02026, 44.83152],
		[7.01202, 44.78183],
		[7.03537, 44.72526],
		[7.08068, 44.68426],
		[7.01202, 44.69012],
		[6.95846, 44.67451],
		[6.95228, 44.6491],
		[6.97906, 44.63739],
		[6.97151, 44.62321],
		[6.95159, 44.61735],
		[6.93237, 44.59877],
		[6.93306, 44.57432],
		[6.91383, 44.56795],
		[6.91314, 44.55769],
		[6.88156, 44.55133],
		[6.87126, 44.53665],
		[6.8534, 44.52784],
		[6.86645, 44.49649],
		[6.88293, 44.47691],
		[6.90628, 44.46711],
		[6.91452, 44.44897],
		[6.94404, 44.4323],
		[6.92688, 44.42348],
		[6.91726, 44.42593],
		[6.8946, 44.422],
		[6.89598, 44.36951],
		[6.88636, 44.36264],
		[6.9207, 44.35134],
		[6.92688, 44.33514],
		[6.95159, 44.31893],
		[6.95983, 44.3096],
		[6.95846, 44.29386],
		[6.99279, 44.27962],
		[7.00035, 44.24028],
		[7.04292, 44.22452],
		[7.07794, 44.23339],
		[7.10197, 44.22108],
		[7.14798, 44.19894],
		[7.16651, 44.20534],
		[7.17544, 44.19992],
		[7.18299, 44.19992],
		[7.18849, 44.19451],
		[7.22488, 44.16693],
		[7.23999, 44.17382],
		[7.2496, 44.15659],
		[7.28462, 44.14279],
		[7.34298, 44.14526],
		[7.35946, 44.11568],
		[7.39311, 44.12356],
		[7.42675, 44.11224],
		[7.43019, 44.12949],
		[7.46315, 44.12653],
		[7.50503, 44.14082],
		[7.52906, 44.1423],
		[7.57164, 44.15116],
		[7.61627, 44.14968],
		[7.63, 44.15757],
		[7.63069, 44.17186],
		[7.64785, 44.17826],
		[7.66807, 44.17607],
		[7.67777, 44.17691],
		[7.68416, 44.17395],
		[7.68047, 44.16324]
	],
	properties: {
		name: 'Alpenhoofdkam',
		popup: '<h3>Alpenhoofdkam</h3>'
	}
};

export const alpenindeling = {
	type: 'FeatureCollection',
	features: [
		{
			type: 'Feature',
			id: 1,
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[6.30615, 46.176022, 0],
						[6.57531, 46.354511, 0],
						[6.94061, 46.487041, 0],
						[7.24548, 46.717258, 0],
						[7.55584, 46.800999, 0],
						[8.14086, 47.01022, 0],
						[8.73413, 47.200909, 0],
						[9.52514, 47.487511, 0],
						[10.05249, 47.606159, 0],
						[10.73364, 47.643181, 0],
						[12.66723, 47.842651, 0],
						[13.83178, 47.945782, 0],
						[14.75463, 48.041359, 0],
						[15.44677, 48.070728, 0],
						[15.77636, 47.989922, 0],
						[15.908203, 47.931065, 0],
						[15.93017, 47.842651, 0],
						[15.70404, 47.702358, 0],
						[15.4129, 47.687569, 0],
						[15.1419, 47.615421, 0],
						[14.96246, 47.543159, 0],
						[14.68231, 47.565399, 0],
						[14.56146, 47.468948, 0],
						[14.34722, 47.416931, 0],
						[14.06158, 47.256859, 0],
						[13.79241, 47.286678, 0],
						[13.44085, 47.22702, 0],
						[13.38043, 47.09256, 0],
						[13.25408, 47.088821, 0],
						[13.23715, 47.063568, 0],
						[13.07281, 47.017712, 0],
						[12.919, 47.085079, 0],
						[12.36969, 47.14489, 0],
						[12.24334, 47.07386, 0],
						[12.18292, 47.09256, 0],
						[11.74346, 46.969002, 0],
						[11.61712, 47.01022, 0],
						[11.53472, 46.984001, 0],
						[11.4743, 47.01022, 0],
						[11.41387, 46.965248, 0],
						[11.19415, 46.969002, 0],
						[11.09527, 46.8414, 0],
						[11.02386, 46.769958, 0],
						[10.89202, 46.76244, 0],
						[10.75836, 46.79253, 0],
						[10.65032, 46.863941, 0],
						[10.46356, 46.83765, 0],
						[10.37109, 46.73703, 0],
						[10.24383, 46.63435, 0],
						[10.19805, 46.618309, 0],
						[10.12252, 46.610771, 0],
						[10.04608, 46.513512, 0],
						[10.04699, 46.432171, 0],
						[10.00213, 46.377251, 0],
						[9.77508, 46.353561, 0],
						[9.47982, 46.456779, 0],
						[9.12872, 46.543751, 0],
						[8.9859, 46.60416, 0],
						[8.79364, 46.57774, 0],
						[8.63433, 46.589062, 0],
						[8.39263, 46.475689, 0],
						[8.23425, 46.327961, 0],
						[8.09692, 46.291908, 0],
						[7.91564, 46.327961, 0],
						[7.77008, 46.33934, 0],
						[7.63, 46.352612, 0],
						[7.51464, 46.333649, 0],
						[7.35534, 46.27483, 0],
						[7.23449, 46.221649, 0],
						[7.08892, 46.157001, 0],
						[6.92962, 46.042728, 0],
						[6.84997, 45.941601, 0],
						[6.61102, 45.832619, 0],
						[6.48742, 45.830711, 0],
						[6.3446, 45.859409, 0],
						[6.21002, 45.937778, 0],
						[6.22924, 46.02557, 0],
						[6.30615, 46.176022, 0]
					]
				]
			},
			properties: {
				name: 'Noordalpen',
				index: 0,
				popup: '<h3>Noordalpen</h3>'
			}
		},
		{
			type: 'Feature',
			id: 2,
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[8.08502, 46.25964, 0],
						[8.10516, 46.27673, 0],
						[8.1134, 46.301399, 0],
						[8.06671, 46.369671, 0],
						[7.94448, 46.506901, 0],
						[7.72064, 46.65509, 0],
						[7.63824, 46.707851, 0],
						[7.59567, 46.738911, 0],
						[7.57782, 46.757729, 0],
						[7.54898, 46.770901, 0],
						[7.48718, 46.779369, 0],
						[7.39791, 46.758678, 0],
						[7.23861, 46.712559, 0],
						[6.93924, 46.486099, 0],
						[6.56433, 46.350712, 0],
						[6.30889, 46.185532, 0],
						[6.15234, 45.93969, 0],
						[6.1029, 45.803909, 0],
						[5.85845, 45.16848, 0],
						[5.79254, 45.044411, 0],
						[5.74585, 44.90646, 0],
						[5.79254, 44.67646, 0],
						[5.94635, 44.34742, 0],
						[6.18804, 44.121109, 0],
						[6.47094, 43.973049, 0],
						[6.76208, 43.864231, 0],
						[7.11273, 43.846409, 0],
						[7.40478, 43.988861, 0],
						[7.3883, 44.099419, 0],
						[7.33612, 44.137871, 0],
						[7.28118, 44.137871, 0],
						[7.16857, 44.195, 0],
						[7.04772, 44.218632, 0],
						[6.98455, 44.2747, 0],
						[6.9461, 44.310089, 0],
						[6.91726, 44.343491, 0],
						[6.8898, 44.370979, 0],
						[6.9049, 44.45927, 0],
						[6.8637, 44.49062, 0],
						[6.84906, 44.52784, 0],
						[6.92138, 44.57579, 0],
						[6.95892, 44.653019, 0],
						[7.06329, 44.69989, 0],
						[6.99188, 44.855862, 0],
						[6.83807, 44.85976, 0],
						[6.74469, 44.933689, 0],
						[6.7337, 45.015301, 0],
						[6.66778, 45.026951, 0],
						[6.62933, 45.120049, 0],
						[6.78863, 45.151051, 0],
						[6.90399, 45.139431, 0],
						[7.02484, 45.220741, 0],
						[7.082062, 45.224613, 0],
						[7.1347, 45.271019, 0],
						[7.16217, 45.410019, 0],
						[6.99188, 45.514042, 0],
						[6.96991, 45.64476, 0],
						[6.81701, 45.688911, 0],
						[6.78955, 45.75985, 0],
						[6.8161, 45.840279, 0],
						[6.95434, 45.852711, 0],
						[7.0523, 45.909119, 0],
						[7.11273, 45.863232, 0],
						[7.29949, 45.916759, 0],
						[7.38555, 45.907211, 0],
						[7.55218, 45.985512, 0],
						[7.87078, 45.920582, 0],
						[7.93121, 46.004589, 0],
						[7.96234, 46.002682, 0],
						[7.99804, 46.00745, 0],
						[8.03009, 46.05798, 0],
						[8.10241, 46.121792, 0],
						[8.13674, 46.141781, 0],
						[8.15643, 46.183632, 0],
						[8.08502, 46.25964, 0]
					]
				]
			},
			properties: {
				name: 'Westalpen',
				index: 1,
				popup: '<h3>Westalpen</h3>'
			}
		},
		{
			type: 'Feature',
			id: 3,
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[7.81265, 43.862251, 0],
						[7.1109, 43.845421, 0],
						[6.75933, 43.864231, 0],
						[6.427, 43.992809, 0],
						[6.17157, 44.136879, 0],
						[5.94635, 44.339561, 0],
						[5.84747, 44.559158, 0],
						[5.80627, 44.64325, 0],
						[5.7788, 44.72332, 0],
						[5.79254, 44.768181, 0],
						[5.81726, 44.807171, 0],
						[5.92437, 44.875332, 0],
						[6.24023, 44.9473, 0],
						[6.43249, 44.939522, 0],
						[6.5863, 44.955078, 0],
						[6.6632, 44.945358, 0],
						[6.73736, 44.96674, 0],
						[6.7218, 45.01918, 0],
						[6.678314, 45.02792, 0],
						[6.643982, 45.107452, 0],
						[6.65588, 45.120049, 0],
						[6.734619, 45.135555, 0],
						[6.793671, 45.148148, 0],
						[6.87561, 45.143299, 0],
						[6.99417, 45.21106, 0],
						[7.08068, 45.20816, 0],
						[7.14523, 45.259418, 0],
						[7.11639, 45.321251, 0],
						[7.1878, 45.408089, 0],
						[7.15896, 45.42062, 0],
						[7.00378, 45.513081, 0],
						[6.99646, 45.64476, 0],
						[6.86096, 45.686989, 0],
						[6.81427, 45.726311, 0],
						[6.78772, 45.81348, 0],
						[7.00744, 45.897652, 0],
						[7.04772, 45.909119, 0],
						[7.09716, 45.859409, 0],
						[7.55676, 45.966419, 0],
						[7.89733, 45.920582, 0],
						[8.15002, 46.187431, 0],
						[8.10607, 46.286221, 0],
						[8.63342, 46.56641, 0],
						[8.97399, 46.589062, 0],
						[9.53613, 46.439751, 0],
						[9.76821, 46.342178, 0],
						[9.88769, 46.370621, 0],
						[10.00442, 46.373459, 0],
						[10.0621, 46.428391, 0],
						[10.04425, 46.505001, 0],
						[10.07446, 46.55508, 0],
						[10.11566, 46.606991, 0],
						[10.28137, 46.649429, 0],
						[10.38345, 46.73703, 0],
						[10.46173, 46.825432, 0],
						[10.54504, 46.83765, 0],
						[10.65673, 46.852669, 0],
						[10.75012, 46.786888, 0],
						[10.95611, 46.75491, 0],
						[11.04675, 46.76244, 0],
						[11.08383, 46.816029, 0],
						[11.10717, 46.865822, 0],
						[11.09893, 46.892101, 0],
						[11.13464, 46.9165, 0],
						[11.20056, 46.967129, 0],
						[11.32965, 46.984928, 0],
						[11.40518, 46.964321, 0],
						[11.43951, 46.967129, 0],
						[11.46972, 46.991489, 0],
						[11.5686, 47.006481, 0],
						[11.77185, 46.965248, 0],
						[12.07946, 47.055149, 0],
						[12.40264, 47.126209, 0],
						[12.48458, 47.143021, 0],
						[12.56835, 47.116859, 0],
						[12.78808, 47.096298, 0],
						[12.92129, 47.075729, 0],
						[12.96936, 47.030819, 0],
						[13.01193, 47.0355, 0],
						[13.08746, 47.012089, 0],
						[13.4024, 47.09256, 0],
						[13.46282, 47.215832, 0],
						[13.8034, 47.26804, 0],
						[14.07257, 47.24194, 0],
						[14.33166, 47.402061, 0],
						[14.55139, 47.461521, 0],
						[14.6228, 47.539452, 0],
						[14.69421, 47.556141, 0],
						[14.77386, 47.557991, 0],
						[14.82879, 47.542229, 0],
						[14.97711, 47.541309, 0],
						[15.14602, 47.61079, 0],
						[15.40969, 47.682949, 0],
						[15.71456, 47.695889, 0],
						[15.83129, 47.589489, 0],
						[15.80932, 47.428082, 0],
						[15.6665, 47.26432, 0],
						[15.19317, 46.95026, 0],
						[14.66674, 46.445419, 0],
						[13.93524, 46.176022, 0],
						[12.97485, 46.019852, 0],
						[12.15087, 45.912941, 0],
						[11.1557, 45.59866, 0],
						[10.26031, 45.537128, 0],
						[9.00238, 45.847931, 0],
						[8.41461, 45.798168, 0],
						[7.97607, 45.5756, 0],
						[7.56317, 45.309662, 0],
						[7.26654, 44.8909, 0],
						[7.40478, 44.472988, 0],
						[7.51464, 44.363129, 0],
						[7.83325, 44.276669, 0],
						[7.99255, 44.190079, 0],
						[7.99255, 44.09153, 0],
						[7.94311, 43.95723, 0],
						[7.81265, 43.862251, 0]
					]
				]
			},
			properties: {
				name: 'Zuidalpen',
				index: 2,
				popup: '<h3>Zuidalpen</h3>'
			}
		}
	]
};

export default { alpenindeling, alpenhoofdkam };
