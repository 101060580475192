export default (ctx, inject) => {
	let language = ctx.app.i18n.locale.slice(3);

	if (!language) {
		language = ctx.req.headers.host.slice(-2).toUpperCase();
	}

	language = ['NL', 'BE', 'DE'].includes(language) ? language : 'NL';

	const client = ctx.app.apolloProvider.clients[language];

	if (client) {
		inject('graphClient', client);
	} else {
		console.log(
			'No graphql client',
			`host: ${ctx.req.headers.host}`,
			`referer: ${ctx.req.headers.referer}`,
			`language: ${language}`,
			Object.keys(ctx.app.apolloProvider.clients)
		);
	}
};
