export const nl = {
  at: { slug: 'oostenrijk', name: 'Oostenrijk' },
  fr: { slug: 'frankrijk', name: 'Frankrijk' },
  de: { slug: 'duitsland', name: 'Duitsland' },
  ch: { slug: 'zwitserland', name: 'Zwitserland' },
  it: { slug: 'italie', name: 'Italië' },
  ad: { slug: 'andorra', name: 'Andorra' },
  cz: { slug: 'tjechie', name: 'Tsjechië' },
  li: { slug: 'liechtenstein', name: 'Liechtenstein' },
  no: { slug: 'noorwegen', name: 'Noorwegen' },
  si: { slug: 'slovenie', name: 'Slovenië' },
  be: { slug: 'belgie', name: 'België' },
  bg: { slug: 'bulgarije', name: 'Bulgarije' },
  ca: { slug: 'canada', name: 'Canada' },
  es: { slug: 'spanje', name: 'Spanje' },
  se: { slug: 'zweden', name: 'Zweden' },
  sk: { slug: 'slowakije', name: 'Slowakije' },
  tr: { slug: 'turkije', name: 'Turkije' },
  us: { slug: 'usa', name: 'Verenigde staten' },
  fi: { slug: 'finland', name: 'Finland' }
};

export const de = {
  at: { slug: 'oesterreich', name: 'Österreich' },
  fr: { slug: 'frankreich', name: 'Frankreich' },
  de: { slug: 'deutschland', name: 'Deutschland' },
  ch: { slug: 'schweiz', name: 'Schweiz' },
  it: { slug: 'italie', name: 'Italien' },
  ad: { slug: 'andorra', name: 'Andorra' },
  cz: { slug: 'tjechie', name: 'Tschechische Republik' },
  li: { slug: 'liechtenstein', name: 'Liechtenstein' },
  no: { slug: 'norwegen', name: 'Norwegen' },
  si: { slug: 'slowenien', name: 'Slowenien' },
  be: { slug: 'belgien', name: 'Belgien' },
  bg: { slug: 'bulgaria', name: 'Bulgaria' },
  ca: { slug: 'canada', name: 'Canada' },
  es: { slug: 'spanien', name: 'Spanien' },
  se: { slug: 'schweden', name: 'Sweden' },
  sk: { slug: 'slowakei', name: 'slowakei' },
  tr: { slug: 'turkei', name: 'Türkei' },
  us: { slug: 'usa', name: 'United States' },
  fi: { slug: 'finnland', name: 'Finnland' }
};

export default { nl, de };
