<template lang="pug">

div
	v-card(color="info" flat)
		client-only
			mapbox.mt-6(
				ref="map"
				style="height:600px"
				:layers="layers"
				)
		v-card-text.caption.py-1 {{description}}
</template>

<script>
import gql from 'graphql-tag';
import { alpenhoofdkam, alpenindeling } from './presets.js';

export default {
	name: 'MapEmbed',
	components: {
		Mapbox: () => (process.client ? import('@/components/mapbox.vue') : null)
	},
	props: {
		description: {
			type: String,
			default: ''
		},
		areaIds: {
			type: Array,
			default() {
				return [];
			}
		},
		kmls: {
			type: Array,
			default() {
				return [];
			}
		},
		display: {
			type: String,
			default: 'marker',
			validator(val) {
				return ['polygon', 'marker', 'highlight'].includes(val);
			}
		}
	},
	data() {
		return {
			areas: []
		};
	},
	async fetch() {
		if (this.areaIds) {
			await this.$graphClient
				.query({
					query: gql`
						query getAreas($ids: [ID]) {
							areas(ids: $ids) {
								id
								name
								slug
								geography {
									location {
										type
										coordinates
									}
									polygon {
										type
										coordinates
									}
								}
							}
						}
					`,
					variables: {
						ids: this.areaIds
					}
				})
				.then(({ data }) => {
					this.areas = data.areas;
				});
		}
	},
	computed: {
		layers() {
			return [
				{
					id: 'map-alpenindeling',
					type: 'fill',
					source: {
						type: 'geojson',
						data: alpenindeling
					},
					layout: {
						visibility:
							this.kmls && this.kmls.includes('alpenindeling')
								? 'visible'
								: 'none'
					},
					defaultevents: true,
					fit: true,
					paint: {
						'fill-color': {
							property: 'index',
							stops: [
								[0, '#33CCFF'],
								[1, '#FF0000'],
								[2, '#33FF33']
							]
						},
						'fill-opacity': [
							'case',
							['boolean', ['feature-state', 'hover'], false],
							0.8,
							0.45
						]
					}
				},
				{
					id: 'map-alpenhoofdkam',
					type: 'line',
					source: {
						type: 'geojson',
						data: alpenhoofdkam
					},
					fit: true,
					layout: {
						visibility:
							this.kmls && this.kmls.includes('alpenhoofdkam')
								? 'visible'
								: 'none'
					},
					paint: {
						'line-color': '#ff0000',
						'line-width': 4
					}
				},
				{
					id: 'map-areas-polygons',
					type: 'fill',
					source: {
						type: 'geojson',
						data: {
							type: 'FeatureCollection',
							features: this.areas.map((area, index) => {
								return {
									type: 'Feature',
									id: index + 10,
									geometry: area.geography.polygon,
									properties: {
										...area,
										popup: `<h3>${area.name}</h3>`
									}
								};
							})
						}
					},
					fit: true,
					defaultevents: true,
					layout: {
						visibility: 'visible'
					},
					minzoom: 8,
					maxzoom: 24,
					paint: {
						'fill-color': '#FFAA5F',
						'fill-outline-color': '#555555',
						'fill-opacity': [
							'case',
							['boolean', ['feature-state', 'hover'], false],
							1,
							0.8
						]
					}
				},
				{
					id: 'map-areas',
					type: 'circle',
					source: {
						type: 'geojson',
						data: {
							type: 'FeatureCollection',
							features: this.areas.map((area, index) => {
								return {
									type: 'Feature',
									id: index + 10,
									geometry: area.geography.location,
									properties: {
										...area,
										popup: `<h3>${area.name}</h3>`
									}
								};
							})
						}
					},
					minzoom: 0,
					maxzoom: 8,
					defaultevents: true,
					layout: {
						visibility: 'visible'
					},
					paint: {
						'circle-radius': [
							'case',
							['boolean', ['feature-state', 'hover'], false],
							7,
							5
						],
						'circle-color': 'rgb(79, 164, 221)',
						'circle-stroke-color': '#fff',
						'circle-stroke-width': 2
					}
				}
			];
		}
	},
	fragments: gql`
		fragment MapEmbedDataFragment on Embed {
			data {
				... on EmbedDataMap {
					description
					areaIds
					display
					kmls
				}
			}
		}
	`
};
</script>
