const { IntrospectionFragmentMatcher } = require('apollo-cache-inmemory');

// https://github.com/apollographql/apollo-client/issues/3397
const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData: {
		__schema: {
			types: [
				{
					kind: 'INTERFACE',
					name: 'Skipasable',
					possibleTypes: [
						{
							name: 'Cluster'
						},
						{
							name: 'Area'
						}
					]
				},
				{
					kind: 'INTERFACE',
					name: 'HasMeta',
					possibleTypes: [
						{
							name: 'AcademyLevel'
						},
						{
							name: 'AcademyLesson'
						},
						{
							name: 'Accommodation'
						},
						{
							name: 'Country'
						},
						{
							name: 'Area'
						},
						{
							name: 'Resort'
						},
						{
							name: 'Region'
						},
						{
							name: 'Topic'
						},
						{
							name: 'Trip'
						},
						{
							name: 'Cluster'
						},
						{
							name: 'Contentpage'
						},
						{
							name: 'GearProduct'
						},
						{
							name: 'GearCluster'
						},
						{
							name: 'GearFeature'
						},
						{
							name: 'GearCategory'
						},
						{
							name: 'Organisation'
						},
						{
							name: 'Skihall'
						},
						{
							name: 'Dryslope'
						},
						{
							name: 'Revolvingslope'
						},
						{
							name: 'TeamMember'
						},
						{
							name: 'SelectionAthlete'
						},
						{
							name: 'Weblog'
						},
						{
							name: 'Location'
						}
					]
				},
				{
					kind: 'INTERFACE',
					name: 'Reviewable',
					possibleTypes: [
						{
							name: 'Area'
						},
						{
							name: 'Resort'
						},
						{
							name: 'Location'
						},
						{
							name: 'Organisation'
						},
						{
							name: 'Skihall'
						},
						{
							name: 'Dryslope'
						},
						{
							name: 'Revolvingslope'
						}
					]
				},
				{
					kind: 'INTERFACE',
					name: 'UserContent',
					possibleTypes: [
						{
							name: 'Topic'
						},
						{
							name: 'Reply'
						},
						{
							name: 'Review'
						}
					]
				},
				{
					kind: 'UNION',
					name: 'DenglCampaignTarget',
					possibleTypes: [
						{
							name: 'Region'
						},
						{
							name: 'Country'
						},
						{
							name: 'Resort'
						},
						{
							name: 'Area'
						}
					]
				},
				{
					kind: 'INTERFACE',
					name: 'FrontpageItem',
					possibleTypes: [
						{
							name: 'FrontpageItemTopic'
						},
						{
							name: 'FrontpageItemWeblog'
						},
						{
							name: 'FrontpageItemVideo'
						},
						{
							name: 'FrontpageItemCustom'
						}
					]
				}
			] // no types provided
		}
	}
});

module.exports = { fragmentMatcher };
