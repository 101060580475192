/* eslint-disable import/no-unresolved, import/no-extraneous-dependencies */
import middleware from '@@/.nuxt/middleware';

middleware['cache-headers'] = async context => {
	if (process.server) {
		const nuxtHeaders = [];
		await [].concat
			.apply(
				[],
				context.route.matched.map(({ components }) => Object.values(components))
			)
			.reduce(async (headers, component) => {
				if (
					component.options &&
					component.options[context.env.CACHE_HEADERS_FUNCTION]
				) {
					const header = await component.options[
						context.env.CACHE_HEADERS_FUNCTION
					](context);

					nuxtHeaders.push(header);
				}

				return headers;
			}, Promise.resolve([]));

		nuxtHeaders.forEach(header => {
			if (header) {
				context.res.setHeader(Object.keys(header)[0], Object.values(header)[0]);
			}
		});
	}
};
