<template lang="pug">
dnt(thirdParty="facebook" thirdPartyName="facebook.com")
	v-container
		v-row(justify="center")
			v-col(cols="12" lg="8")
				vue-facebook-embed(:videoId="videoId" :appID="'120994591283453'" :client-token="'c7e5348dda5b0e5748cacae968e09ffb'")
</template>

<script>
import gql from 'graphql-tag';
import dnt from '@dengl/common/components/dnt';
import VueFacebookEmbed from './fbEmbed.js';

export default {
	name: 'FacebookEmbed',
	components: { dnt, VueFacebookEmbed },
	props: {
		videoId: {
			type: String,
			required: true
		}
	},
	fragments: gql`
		fragment FacebookEmbedDataFragment on Embed {
			data {
				... on EmbedDataFacebook {
					videoId
				}
			}
		}
	`
};
</script>
