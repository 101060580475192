var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(("position: relative; height: " + _vm.height + "px;"))},[_c('svg',{staticStyle:{"right":"0","position":"absolute"},attrs:{"width":_vm.width,"height":_vm.height}},[_c('rect',{staticClass:"background",attrs:{"width":_vm.width,"height":_vm.height - _vm.axisPadding}}),_vm._l((_vm.processedData),function(ref){
var value = ref.value;
var offset = ref.offset;
var barHeight = ref.barHeight;
var label = ref.label;
var barLabel = ref.barLabel;
var active = ref.active;
return _c('g',{class:_vm.barWidth < 30 ? 'compact':'',style:(_vm.clickable ? 'cursor: pointer;' : ''),attrs:{"transform":("translate(" + offset + " 0)")},on:{"click":function($event){return _vm.$emit('clickedbar', label)}}},[_c('rect',{staticClass:"chartbar",class:active ? 'active':'',attrs:{"height":barHeight,"width":_vm.barWidth,"transform":("translate(0 " + (_vm.height-barHeight - _vm.axisPadding) + ")")}}),_c('g',{attrs:{"transform":("translate(" + (_vm.barWidth * 0.5) + " " + (_vm.height - barHeight - _vm.axisPadding) + ")")}},[(value)?_c('text',{staticClass:"barLabel",class:barHeight > 30 ? 'inside': 'outside'},[_vm._v(_vm._s(barLabel))]):_vm._e()]),_c('g',{attrs:{"transform":("translate(" + (_vm.barWidth * 0.5) + " " + (_vm.height - _vm.axisPadding + 5) + ")")}},[_c('text',{staticClass:"axisLabel"},[_vm._v(_vm._s(label))])])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }