import { embeds as EmbedComponents, fragments } from './components';

const parseEmbed = (createElement, slug, embeds) => {
	const embedData = embeds.find(embed => {
		if (embed.slug === slug) {
			return embed;
		}
		return false;
	});

	if (!embedData) {
		return [
			createElement('div', {
				domProps: {
					innerHTML: 'Embed not found'
				}
			})
		];
	}

	return [
		createElement(
			EmbedComponents[embedData.type],
			{ props: embedData.data, class: ['flow-left'] },
			[]
		)
	];
};

export { parseEmbed, fragments };
