export default {
	data() {
		return {
			locale: {
				navigation: {
					'nl-NL': [
						{
							title: 'skigebieden',
							name: 'areas',
							url_title: 'skigebieden zoeken'
						},
						{
							title: 'weerbericht',
							name: 'weather',
							url_title: 'wintersport weerbericht'
						},
						{
							title: 'accommodatie',
							name: 'accommodations-search',
							url_title: 'wintersport accommodaties'
						},
						{
							title: 'vereniging',
							name: 'vereniging',
							url_title: 'Nederlandse Ski Vereniging'
						},
						{
							title: 'forum',
							name: 'forum-category',
							url_title: 'wintersport forum'
						}
					],
					'nl-BE': [
						{
							title: 'skigebieden',
							name: 'areas',
							url_title: 'skigebieden zoeken'
						},
						{
							title: 'weerbericht',
							name: 'weather',
							url_title: 'wintersport weerbericht'
						},
						{
							title: 'accommodatie',
							name: 'accommodations-search',
							url_title: 'wintersport accommodaties'
						},
						{
							title: 'forum',
							name: 'forum-category',
							url_title: 'wintersport forum'
						}
					],
					'de-DE': [
						{
							title: 'Skigebiete',
							name: 'areas',
							url_title: 'skigebieden zoeken'
						},
						{
							title: 'Wettervorhersage',
							name: 'weather',
							url_title: 'wintersport weebericht'
						},
						{
							title: 'Unterkunft',
							name: 'accommodations-search',
							url_title: 'wintersport accommodaties'
						}
					]
				},
				footer: {
					lists: {
						'nl-NL': [
							{
								title: 'Website',
								list: [
									{
										title: 'Over ons',
										url: '/over-ons',
										url_title: 'Team'
									},
									{
										title: 'Voorwaarden & privacy',
										url: '/privacy-en-voorwaarden',
										url_title: 'Disclaimer'
									},
									{
										title: 'Nieuwsbrieven',
										url: '/nieuwsbrief',
										url_title: 'Wintersport nieuwsbrief'
									},
									{
										title: 'Skigebieden',
										url: '/skigebieden',
										url_title: 'Skigebieden'
									},
									{
										title: 'Accommodaties',
										url: '/accommodatie',
										url_title: 'Wintersport accommodaties'
									},
									{
										title: 'Aanbiedingen',
										url: '/aanbiedingen',
										url_title: 'Wintersport aanbiedingen'
									}
								]
							},
							{
								title: 'Vereniging',
								list: [
									{
										title: 'Nederlandse Ski Vereniging',
										url: 'https://www.nskiv.nl',
										url_title: 'Nederlandse Ski Vereniging'
									},
									{
										title: 'Contact',
										url: 'https://www.nskiv.nl/over-ons/contact',
										url_title: 'Contact Wintersport.nl'
									},
									{
										title: 'Meestgestelde vragen',
										url: 'https://www.nskiv.nl/over-ons/meestgestelde-vragen',
										url_title: 'Contact Wintersport'
									},
									{
										title: 'Partners',
										url: 'https://www.nskiv.nl/over-ons/partners',
										url_title: 'Partners Nederlandse Ski Vereniging'
									},
									{
										title: 'Verzekeringen',
										url: '/verzekering',
										url_title: 'Wintersport verzekering'
									},
									{
										title: 'Vacatures',
										url: 'https://www.nskiv.nl/over-ons/vacatures',
										url_title: 'Vacatures'
									}
								]
							},
							{
								title: 'Skigebieden',
								list: [
									{
										title: 'Winterberg',
										url: '/skigebieden/winterberg',
										url_title: 'Wintersport Winterberg'
									},
									{
										title: 'Zillertal Arena',
										url: '/skigebieden/zillertalarena',
										url_title: 'Wintersport Zillertal Arena'
									},
									{
										title: 'SkiWelt',
										url: '/skigebieden/skiwelt',
										url_title: 'Skiwelt Wilder Kaiser - Brixental'
									},
									{
										title: 'Serfaus - Fiss - Ladis',
										url: '/skigebieden/serfaus',
										url_title: 'Serfaus - Fiss - Ladis'
									},
									{
										title: 'Les Trois Vallées',
										url: '/skigebieden/troisvallees',
										url_title: 'Les Trois Vallées'
									},
									{
										title: 'Saalbach Hinterglemm',
										url: '/skigebieden/saalbach',
										url_title: 'Saalbach Hinterglemm Leogang Fieberbrunn'
									}
								]
							},
							{
								title: 'Dorpen',
								list: [
									{
										title: 'Val Thorens',
										url: '/dorpen/val_thorens',
										url_title: 'Wintersport Val Thorens'
									},
									{
										title: 'Königsleiten',
										url: '/dorpen/koenigsleiten',
										url_title: 'Wintersport Konigsleiten'
									},
									{
										title: 'Gerlos',
										url: '/dorpen/gerlos',
										url_title: 'Wintersport Gerlos'
									},
									{
										title: 'St. Anton am Arlberg',
										url: '/dorpen/st_anton_am_arlberg',
										url_title: 'Wintersport Sankt Anton am Arlberg'
									},
									{
										title: 'Flachau',
										url: '/dorpen/flachau',
										url_title: 'Wintersport Flachau'
									},
									{
										title: 'Westendorf',
										url: '/dorpen/westendorf',
										url_title: 'Westendorf'
									}
								]
							},
							{
								title: "Regio's",
								list: [
									{
										title: 'Tirol',
										url: '/skigebieden/overzicht/tirol',
										url_title: 'Skigebieden Tirol'
									},
									{
										title: 'Savoie',
										url: '/skigebieden/overzicht/savoie',
										url_title: 'Skigebieden Savoie'
									},
									{
										title: 'Salzburgerland',
										url: '/skigebieden/overzicht/salzburger_land',
										url_title: 'Skigebieden Salzburgerland'
									},
									{
										title: 'Sauerland',
										url: '/skigebieden/overzicht/sauerland',
										url_title: 'Skigebieden Sauerland'
									},
									{
										title: 'Vorarlberg',
										url: '/skigebieden/overzicht/vorarlberg',
										url_title: 'Skigebieden Vorarlberg'
									},
									{
										title: 'Zuid-Tirol',
										url: '/skigebieden/overzicht/sudtirol',
										url_title: 'Skigebieden Zuid-Tirol'
									}
								]
							},
							{
								title: 'Landen',
								list: [
									{
										title: 'Oostenrijk',
										url: '/skigebieden/overzicht/oostenrijk',
										url_title: 'Skigebieden Oostenrijk'
									},
									{
										title: 'Frankrijk',
										url: '/skigebieden/overzicht/frankrijk',
										url_title: 'Skigebieden Frankrijk'
									},
									{
										title: 'Duitsland',
										url: '/skigebieden/overzicht/duitsland',
										url_title: 'Skigebieden Duitsland'
									},
									{
										title: 'Italië',
										url: '/skigebieden/overzicht/italie',
										url_title: 'Skigebieden Italie'
									},
									{
										title: 'Zwitserland',
										url: '/skigebieden/overzicht/zwitserland',
										url_title: 'Skigebieden Zwitserland'
									},
									{
										title: 'Andorra',
										url: '/skigebieden/overzicht/andorra',
										url_title: 'Skigebieden Andorra'
									}
								]
							}
						],
						'nl-BE': [
							{
								title: 'Website',
								list: [
									{
										title: 'Contact',
										url: '/contact',
										url_title: 'Contact'
									},
									{
										title: 'Voorwaarden & privacy',
										url: '/privacy-en-voorwaarden',
										url_title: 'Disclaimer'
									},
									{
										title: 'Nieuwsbrieven',
										url: '/nieuwsbrief',
										url_title: 'Wintersport nieuwsbrief'
									},
									{
										title: 'Skigebieden',
										url: '/skigebieden',
										url_title: 'Skigebieden'
									},
									{
										title: 'Accommodaties',
										url: '/accommodatie',
										url_title: 'Wintersport accommodaties'
									},
									{
										title: 'Aanbiedingen',
										url: '/aanbiedingen',
										url_title: 'Wintersport aanbiedingen'
									}
								]
							},
							{
								title: 'Skigebieden',
								list: [
									{
										title: 'Winterberg',
										url: '/skigebieden/winterberg',
										url_title: 'Wintersport Winterberg'
									},
									{
										title: 'Zillertal Arena',
										url: '/skigebieden/zillertalarena',
										url_title: 'Wintersport Zillertal Arena'
									},
									{
										title: 'SkiWelt',
										url: '/skigebieden/skiwelt',
										url_title: 'Skiwelt Wilder Kaiser - Brixental'
									},
									{
										title: 'Serfaus - Fiss - Ladis',
										url: '/skigebieden/serfaus',
										url_title: 'Serfaus - Fiss - Ladis'
									},
									{
										title: 'Les Trois Vallées',
										url: '/skigebieden/troisvallees',
										url_title: 'Les Trois Vallées'
									},
									{
										title: 'Saalbach Hinterglemm',
										url: '/skigebieden/saalbach',
										url_title: 'Saalbach Hinterglemm Leogang Fieberbrunn'
									}
								]
							},
							{
								title: 'Dorpen',
								list: [
									{
										title: 'Val Thorens',
										url: '/dorpen/val_thorens',
										url_title: 'Wintersport Val Thorens'
									},
									{
										title: 'Königsleiten',
										url: '/dorpen/koenigsleiten',
										url_title: 'Wintersport Konigsleiten'
									},
									{
										title: 'Gerlos',
										url: '/dorpen/gerlos',
										url_title: 'Wintersport Gerlos'
									},
									{
										title: 'St. Anton am Arlberg',
										url: '/dorpen/st_anton_am_arlberg',
										url_title: 'Wintersport Sankt Anton am Arlberg'
									},
									{
										title: 'Flachau',
										url: '/dorpen/flachau',
										url_title: 'Wintersport Flachau'
									},
									{
										title: 'Westendorf',
										url: '/dorpen/westendorf',
										url_title: 'Westendorf'
									}
								]
							},
							{
								title: "Regio's",
								list: [
									{
										title: 'Tirol',
										url: '/skigebieden/overzicht/tirol',
										url_title: 'Skigebieden Tirol'
									},
									{
										title: 'Savoie',
										url: '/skigebieden/overzicht/savoie',
										url_title: 'Skigebieden Savoie'
									},
									{
										title: 'Salzburgerland',
										url: '/skigebieden/overzicht/salzburger_land',
										url_title: 'Skigebieden Salzburgerland'
									},
									{
										title: 'Sauerland',
										url: '/skigebieden/overzicht/sauerland',
										url_title: 'Skigebieden Sauerland'
									},
									{
										title: 'Vorarlberg',
										url: '/skigebieden/overzicht/vorarlberg',
										url_title: 'Skigebieden Vorarlberg'
									},
									{
										title: 'Zuid-Tirol',
										url: '/skigebieden/overzicht/sudtirol',
										url_title: 'Skigebieden Zuid-Tirol'
									}
								]
							},
							{
								title: 'Landen',
								list: [
									{
										title: 'Oostenrijk',
										url: '/skigebieden/overzicht/oostenrijk',
										url_title: 'Skigebieden Oostenrijk'
									},
									{
										title: 'Frankrijk',
										url: '/skigebieden/overzicht/frankrijk',
										url_title: 'Skigebieden Frankrijk'
									},
									{
										title: 'Duitsland',
										url: '/skigebieden/overzicht/duitsland',
										url_title: 'Skigebieden Duitsland'
									},
									{
										title: 'Italië',
										url: '/skigebieden/overzicht/italie',
										url_title: 'Skigebieden Italie'
									},
									{
										title: 'Zwitserland',
										url: '/skigebieden/overzicht/zwitserland',
										url_title: 'Skigebieden Zwitserland'
									},
									{
										title: 'Andorra',
										url: '/skigebieden/overzicht/andorra',
										url_title: 'Skigebieden Andorra'
									}
								]
							}
						],
						'de-DE': [
							{
								title: 'Website',
								list: [
									{
										title: 'Kontakt',
										url: '/kontakt',
										url_title: 'Kontakt Skiportal.de'
									}
								]
							},
							{
								title: 'Skigebiete',
								list: [
									{
										title: 'SkiWelt Wilder Kaiser - Brixental',
										url: '/skigebiete/skiwelt-wilder-kaiser',
										url_title: 'Skigebiet SkiWelt Wilder Kaiser - Brixental'
									},
									{
										title: 'Zillertal 3000',
										url: '/skigebiete/zillertal-3000',
										url_title: 'Skigebiet Zillertal 3000'
									},
									{
										title: 'Serfaus - Fiss - Ladis',
										url: '/skigebiete/serfaus-fiss-ladis',
										url_title: 'Skigebiet Serfaus - Fiss - Ladis'
									},
									{
										title: 'Saalbach Hinterglemm Leogang Fieberbrunn',
										url: '/skigebiete/saalbach-hinterglemm-leogang-fieberbrunn',
										url_title: 'Saalbach Hinterglemm Leogang Fieberbrunn'
									},
									{
										title: 'Val Gardena',
										url: '/skigebiete/val-gardena-groeden',
										url_title: 'Skigebiet Val Gardena'
									},
									{
										title: 'Kleinwalsertal',
										url: '/skigebiete/kleinwalsertal',
										url_title: 'Skigebiet Kleinwalsertal'
									}
								]
							},
							{
								title: 'Dörfer',
								list: [
									{
										title: 'Sankt Anton am Arlberg',
										url: '/skiorte/sankt-anton-am-arlberg',
										url_title: 'Skiort Sankt Anton am Arlberg'
									},
									{
										title: 'Ischgl',
										url: '/skiorte/ischgl',
										url_title: 'Skiort Ischgl'
									},
									{
										title: 'Mayrhofen',
										url: '/skiorte/mayrhofen',
										url_title: 'Skiort Mayrhofen'
									},
									{
										title: 'Sölden',
										url: '/skiorte/soelden',
										url_title: 'Skiort Sölden'
									},
									{
										title: 'Kitzbühel',
										url: '/skiorte/kitzbuehel',
										url_title: 'Skiort Kitzbühel'
									},
									{
										title: 'Winterberg',
										url: '/skiorte/winterberg',
										url_title: 'Skiort Winterberg'
									}
								]
							},
							{
								title: 'Regionen',
								list: [
									{
										title: 'Tirol',
										url: '/skigebiete/regionen/tirol',
										url_title: 'Region Tirol'
									},
									{
										title: 'Salzburgerland',
										url: '/skigebiete/regionen/salzburgerland',
										url_title: 'Region Salzburgerland'
									},
									{
										title: 'Bayerische Alpen',
										url: '/skigebiete/regionen/bayerische-alpen',
										url_title: 'Region Bayerische Alpen'
									},
									{
										title: 'Sauerland',
										url: '/skigebiete/regionen/sauerland',
										url_title: 'Region Sauerland'
									},
									{
										title: 'Südtirol',
										url: '/skigebiete/regionen/suedtirol',
										url_title: 'Region Südtirol'
									},
									{
										title: 'Vorarlberg',
										url: '/skigebiete/regionen/vorarlberg',
										url_title: 'Region Vorarlberg'
									}
								]
							},
							{
								title: 'Länder',
								list: [
									{
										title: 'Österreich',
										url: '/skigebiete/ubersicht-lander-mit-wintersportgebieten/oesterreich',
										url_title: 'Region Österreich'
									},
									{
										title: 'Deutschland',
										url: '/skigebiete/ubersicht-lander-mit-wintersportgebieten/deutschland',
										url_title: 'Region Deutschland'
									},
									{
										title: 'Schweiz',
										url: '/skigebiete/ubersicht-lander-mit-wintersportgebieten/schweiz',
										url_title: 'Region Schweiz'
									},
									{
										title: 'Italien',
										url: '/skigebiete/ubersicht-lander-mit-wintersportgebieten/italien',
										url_title: 'Region Italien'
									},
									{
										title: 'Frankreich',
										url: '/skigebiete/ubersicht-lander-mit-wintersportgebieten/frankreich',
										url_title: 'Region Frankreich'
									}
								]
							}
						]
					},
					partners: {
						'nl-NL': [
							{
								title: 'Een website van de',
								partners: [
									{
										image:
											'https://static.wintersport.nl/images/nskiv/footer_nskiv.png',
										url: '/vereniging',
										url_title: 'Nederlandse Ski Vereniging',
										width: 97
									}
								]
							},
							{
								title: 'Official partners',
								partners: [
									{
										image:
											'https://static.wintersport.nl/images/nskiv/footer_nederlandseloterij.png',
										url: '//www.lotto.nl',
										url_title: 'Nederlandse Loterij',
										width: 108
									}
								]
							}
						]
					},
					vereniging_partners: {
						'nl-NL': [
							{
								title: 'Partners',
								partners: [
									{
										image:
											'https://static.wintersport.nl/images/nskiv/footer_snowworld.png',
										url: '//www.snowworld.com',
										url_title: 'Snow World',
										width: 86
									},
									{
										image:
											'https://static.wintersport.nl/images/nskiv/footer_daka.png',
										url: '//www.daka.nl/',
										url_title: 'Daka',
										width: 62
									},
									{
										image:
											'https://static.wintersport.nl/images/nskiv/footer_cruyff.png',
										url: '//www.cruyff-foundation.org/',
										url_title: 'Cruyff Foundation',
										width: 26
									},
									{
										image:
											'https://static.wintersport.nl/images/nskiv/footer_skiamade.png',
										url: 'https://www.skiamade.com/nl?utm_source=wintersport.nl&utm_medium=logo&utm_campaign=Skiamade',
										url_title: 'Ski Amadé',
										width: 114
									}
								]
							}
						],
						'nl-BE': [],
						'de-DE': []
					}
				}
			}
		};
	},
	computed: {
		navWithLocale() {
			return this.locale.navigation[this.currentLocale] || [];
		},
		footListsWithLocale() {
			return this.locale.footer.lists[this.currentLocale] || [];
		},
		footPartnersWithLocale() {
			return this.locale.footer.partners[this.currentLocale] || [];
		},
		footVerenigingPartnersWithLocale() {
			return this.locale.footer.vereniging_partners[this.currentLocale] || [];
		},
		currentLocale() {
			return this.$i18n.locale || 'nl-NL';
		}
	}
};
