<template lang="pug">
div
	v-progress-linear(v-if="fetchPending" indeterminate)
	v-data-iterator(v-else :items="accommodations" disable-pagination hide-default-footer :sort-desc="!sort.ascending" :sort-by="sortfield")
		template(#no-data)
			v-alert.my-6(type="info" color="accent" text border="bottom")
				p We hebben geen accommodaties gevonden die voldoen aan je filters.
				ul.list-unstyled
					li(v-for="alternative in alternatives")
						nuxt-link(:to="localePath({name: 'accommodations-search', hash: `#${alternative.filterstring}`})") Zonder het filter <strong>{{alternative.title}}</strong> hebben we <strong>{{alternative.resultCount}}</strong> resultaten gevonden vanaf {{ $n(alternative.fromprice, 'simplecurrency', $i18n.locale) }}
		template(#item="{item, index}")
			accommodation-list-item.mb-6(:accommodation="item" :key="item.id" :filterstring="filterstring")
			.mb-6(v-if="index === 5")
				ad(location="accosearchresults")
	v-alert.my-6(v-if="total > 50 && !fetchPending && !hideAlert" type="info" color="accent" text border="bottom")
		p Er zijn teveel accommodaties gevonden om hier allemaal te tonen. Gebruik de filters om nog wat beter te zoeken.

	disclaimer(v-if="!fetchPending && !hideDisclaimer")
</template>

<script>
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';
import AccommodationListItem from '@dengl/common/components/accommodations/accommodation-list-item.vue';
import Disclaimer from '@dengl/common/components/accommodations/disclaimer.vue';

const {
	encodeFilters,
	decodeFilters
} = require('@dengl/common/utils/filterstrings');

const query = gql`
	query Accommodations(
		$filters: String
		$limit: Int
		$sort: AccommodationSortInput
		$preferedCity: String!
	) {
		accommodations(filters: $filters, limit: $limit, sort: $sort) {
			total
			filters
			nodes {
				id
				name
				type
				images
				location
				url
				rating
				stars
				isInresort
				facilities
				views
				distanceToCenter
				pois {
					distance
					item {
						... on Skilift {
							id
							name
							type
						}
						... on Rental {
							id
							name
						}
						... on Skischool {
							id
							name
						}
					}
				}
				resort {
					id
					name
					slug
					area {
						id
						name
						slug
						departures {
							city(city: $preferedCity) {
								distance
								node {
									id
									name
								}
							}
						}
					}
					country {
						id
						name
						slug
						countryCode
					}
				}
				variants {
					id
					name
					price
					date
					persons
					bedrooms
					roomsurface
					maxOccupancy
					duration
					servicetype
					url
					transporttype
					checkout
					priceBreakdown {
						acco
						skipas
						extra
					}
					partner {
						id
						name
						image
					}
				}
			}
		}
	}
`;

export default {
	name: 'AccommodationList',
	components: {
		AccommodationListItem,
		Disclaimer
	},
	props: {
		filterstring: {
			type: String,
			default: ''
		},
		limit: {
			type: [String, Number],
			default: 50
		},
		sort: {
			type: Object,
			default() {
				return {
					ascending: true,
					field: 'PRICE'
				};
			}
		},
		hideAlert: {
			type: Boolean,
			default: false
		},
		hideDisclaimer: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			accommodations: [],
			alternatives: [],
			total: 0,
			fetchPending: true,
			filterStringChangedWhileFetching: false
		};
	},
	async fetch() {
		await this.$graphClient
			.query({
				query,
				variables: {
					filters: this.filterstring,
					limit: parseInt(this.limit),
					sort: this.sort,
					preferedCity: this.$store.getters.preferedCity
				}
			})
			.then(({ data }) => {
				if (data.accommodations.filters === this.filterstring) {
					this.accommodations = data.accommodations.nodes.filter(
						acco => acco.variants.length
					);

					// console.log(data.accommodations);
					if (!this.accommodations.length) {
						this.fetchAlternatives(data.accommodations.filters);
					}

					this.total = data.accommodations.total;
					this.fetchPending = false;
				} else if (this.filterStringChangedWhileFetching) {
					this.filterStringChangedWhileFetching = false;
					this.debouncedFetch();
				}
			});
	},
	computed: {
		sortfield() {
			switch (this.sort.field) {
				case 'PRICE':
					return ['variants[0].price'];
				case 'RATING':
					return ['rating'];
				case 'DISTANCE_SKILIFT':
					return ['skilifts.distance'];
				default:
					return [];
			}
		}
	},
	watch: {
		filterstring() {
			if (this.fetchPending) {
				this.filterStringChangedWhileFetching = true;
			}
			this.fetchPending = true;
			this.debouncedFetch();
		},
		sort() {
			this.fetchPending = true;
			this.debouncedFetch();
		}
	},
	mounted() {
		if (!this.fetchPending && !this.accommodations.length) {
			this.fetchAlternatives(this.filterstring);
		}
	},
	methods: {
		debouncedFetch: debounce(function () {
			this.fetchPending = true;
			this.$fetch();
		}, 200),

		fetchAlternatives(filterstring) {
			const filters = decodeFilters(filterstring, this.$i18n.locale);
			this.alternatives = [];
			Object.keys(filters)
				.filter(filter => !['persons', 'duration', 'date'].includes(filter))
				.reduce(async (prev, filter) => {
					await prev;

					const filterCopy = { ...filters };
					delete filterCopy[filter];

					return this.$graphClient
						.query({
							query,
							variables: {
								filters: encodeFilters(filterCopy, this.$i18n.locale),
								limit: parseInt(this.limit),
								sort: this.sort,
								preferedCity: this.$store.getters.preferedCity
							}
						})
						.then(({ data }) => {
							const fromprice = data.accommodations.nodes.reduce(
								(fromprice, acco) => {
									if (
										acco.variants.length &&
										acco.variants[0].price < fromprice
									) {
										return acco.variants[0].price;
									}
									return fromprice;
								},
								Infinity
							);
							if (fromprice < Infinity && filterstring === this.filterstring) {
								this.alternatives.push({
									resultCount: data.accommodations.total,
									title: this.$t(`accommodations-filters.heading.${filter}`),
									filterstring: encodeFilters(filterCopy, this.$i18n.locale),
									fromprice
								});
							}
						});
				}, new Promise(resolve => resolve()));
		}
	}
};
</script>
