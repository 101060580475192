export default {
	render(createElement) {
		return createElement('div', {}, this.$slots.default);
	},
	name: 'VuePlyr',
	props: {
		/** Options object for plyr config. **/
		options: {
			type: Object,
			required: false,
			default() {
				return {};
			}
		},
		/** Array of events to emit from the plyr object **/
		emit: {
			type: Array,
			required: false,
			default() {
				return [];
			}
		}
	},
	data() {
		return {
			player: {},
			loaded: false
		};
	},
	computed: {
		opts() {
			const options = { vimeo: { dnt: true }, ...this.options };
			if (
				!Object.prototype.hasOwnProperty.call(
					this.options,
					'hideYouTubeDOMError'
				)
			) {
				options.hideYouTubeDOMError = true;
			}
			return options;
		}
	},
	mounted() {
		if (!window.Plyr) {
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.async = false;
			script.src = 'https://cdn.plyr.io/3.6.12/plyr.js';
			document.body.appendChild(script);

			const css = document.createElement('link');
			css.rel = 'stylesheet';
			css.href = 'https://cdn.plyr.io/3.6.12/plyr.css';
			document.body.appendChild(css);

			script.onload = () => {
				this.initialize();
			};
		} else {
			this.initialize();
		}
	},
	beforeDestroy() {
		try {
			this.player.destroy();
		} catch (e) {
			if (
				!(
					this.opts.hideYouTubeDOMError &&
					e.message === 'The YouTube player is not attached to the DOM.'
				)
			) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	},
	methods: {
		initialize() {
			this.player = new window.Plyr(this.$el.firstChild, this.opts);
			this.loaded = true;
			this.emit.forEach(element => {
				this.player.on(element, this.emitPlayerEvent);
			});
		},
		emitPlayerEvent(event) {
			this.$emit(event.type, event);
		}
	}
};
