<template lang="pug">
.infosheet.ml-4(style="float:right")
	v-card.mb-3(outlined color="info")
		.subtitle-1.pa-3 {{title}}
		video-hls(:videoUrl="videoUrl")
</template>

<script>
import gql from 'graphql-tag';
import VideoHls from '@dengl/common/components/video-hls.vue';
import fields from './fields';

export default {
	name: 'EmbedWeatherVideo',
	components: { VideoHls },
	props: fields,
	data() {
		return {
			title: '',
			id: '',
			videos: {
				nordstau: {
					title: 'Nordstau',
					videoUrl: 'nskivyBeEKzpxvO'
				},
				sudstau: {
					title: 'Südstau',
					videoUrl: 'nskivTXFAbNqAO'
				},
				weststau: {
					title: 'Weststau',
					videoUrl: 'nskivMZrnhwMUXW'
				},
				fohn: {
					title: 'Föhn',
					videoUrl: 'nskivc1ITip83V1'
				},
				inversie: {
					title: 'Inversie',
					videoUrl: 'nskivJHmQPhCYZX'
				}
			}
		};
	},
	created() {
		this.videoUrl = this.videos[this.type].videoUrl;
		this.title = this.videos[this.type].title;
	},
	fragments: gql`
		fragment WeatherVideoEmbedDataFragment on Embed {
			data {
				... on EmbedDataWeatherVideo {
					type
				}
			}
		}
	`
};
</script>
