<template lang="pug">
v-card.forecast-mailing(outlined)
	v-card-text
		forecast-mailing-dialog(v-model="showForecastMailingDialog")
		strong.block.mb-1
			| {{$t('page.weblog.message:forecast.mailing.title')}}
			a.text-no-wrap(@click="showForecastMailingDialog=true" :title="$t('page.weblog.message:forecast.mailing.link.text')")  {{$t('page.weblog.message:forecast.mailing.link.text')}}
</template>

<script>
import gql from 'graphql-tag';
import ForecastMailingDialog from '@dengl/common/components/forecast-mailing-dialog.vue';

export default {
	name: 'EmbedWeatherAlert',
	components: {
		ForecastMailingDialog
	},
	data() {
		return {
			showForecastMailingDialog: false
		};
	},
	fragments: gql`
		fragment WeatheralertEmbedDataFragment on Embed {
			data {
				... on EmbedDataWeatheralert {
					name
				}
			}
		}
	`
};
</script>
