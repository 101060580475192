<template lang="pug">
	ws-dialog(v-model="dialog" :title="$t('forecast.mailing:heading')")
		validation-observer(v-slot="{ handleSubmit, invalid }" ref="form")
			v-form( @submit.prevent="handleSubmit(submitEmail)")
				v-card-text.text--primary
					validation-provider(v-slot="{errors, touched}" rules="required|email" :name="$t('forecast.mailing:email.input.label')" slim)
						v-text-field(v-model.trim="email" outlined :label="$t('forecast.mailing:email.input.label')" required :error-messages="touched ? errors : []")
				v-card-actions
					v-spacer
					v-btn.gradient( color="accent" :disabled="invalid" depressed type="submit") {{ $t('forecast.mailing:submit') }}

</template>

<script>
import gql from 'graphql-tag';

export default {
	props: {
		value: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			dialog: false,
			email: null
		};
	},
	watch: {
		value(value, _oldValue) {
			this.dialog = value;
		},
		dialog(value, _oldValue) {
			this.$emit('input', value);
		}
	},
	created() {
		if (this.$store.state.user.email) {
			this.email = this.$store.state.user.email;
		}
	},
	methods: {
		submitEmail() {
			this.$graphClient
				.mutate({
					mutation: gql`
						mutation ($input: addNewsletterSubscriptionInput!) {
							addNewsletterSubscription(input: $input)
						}
					`,
					variables: {
						input: {
							email: this.email,
							tags: ['forecast']
						}
					}
				})
				.then(({ errors, _data }) => {
					if (errors) {
						return this.$toast.error(
							this.$t('newsletter:error.while.subscribing')
						);
					} else {
						this.dialog = false;
						return this.$toast.success(this.$t('newsletter:subscribed'));
					}
				})
				.catch(_error => {
					return this.$toast.error(
						this.$t('newsletter:error.while.subscribing')
					);
				});
		}
	}
};
</script>
