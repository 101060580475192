<template lang="pug">
div.compare-container
	twenty-twenty(:before="imageBefore" :after="imageAfter")
	div.compare-caption(v-if="caption") {{caption}}
</template>

<script>
import gql from 'graphql-tag';
import 'vue-twentytwenty/dist/vue-twentytwenty.css';
import TwentyTwenty from 'vue-twentytwenty';

export default {
	name: 'ImageCompareEmbed',
	components: {
		TwentyTwenty
	},
	props: {
		imageBefore: {
			type: String,
			required: true
		},
		imageAfter: {
			type: String,
			required: true
		},
		caption: {
			type: String,
			default: null
		}
	},
	data() {
		return {};
	},
	fragments: gql`
		fragment ImageCompareEmbedDataFragment on Embed {
			data {
				... on EmbedDataImageCompare {
					imageBefore
					imageAfter
					caption
				}
			}
		}
	`
};
</script>

<style>
.compare-container {
	position: relative;
}
.compare-caption {
	padding: 0 0.4rem;
	position: absolute;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.6);
	color: #fff;
	font-size: 0.8em;
	z-index: 9999;
}
</style>
