export default {
	functional: true,

	render(createElement, context) {
		if (context.props.to) {
			return createElement('nuxt-link', context.data, context.children);
		} else if (context.props.href) {
			return createElement('a', context.data, context.children);
		} else {
			return context.children;
		}
	}
};
